import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaChartPie } from "react-icons/fa";
import DashboardStat from "../../../components/DashboardStat";
import { GetDashboard } from "../../../services/admin/dashboard.service";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { constants } from "../../../utils/constants";
import { EmptyLocalStorage } from "../../../services/auth/auth.service";
import { useHistory } from "react-router-dom";

const InitialStats = {
  user_count: 0,
  liked_users: 0,
};

function Restaurant({ RoleId }) {
  const history = useHistory();
  const [stats, setStats] = useState(InitialStats);
  useEffect(async () => {
    let params = { role_id: RoleId };
    await GetDashboard(params)
      .then((data) => {
        if (data.success) {
          setStats({
            received_orders: data.data.received_orders,
            pending_orders: data.data.pending_orders,
            completed_orders: data.data.completed_orders,
            hosts: data.data.hosts,
            menu_cat: data.data.menu_cat,
            menu_item: data.data.menu_item
          });
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  }, []);

  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/orders?status=1"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Recived Orders"}
              icon={<FaChartPie />}
              countMain={stats.received_orders}
            />
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/orders?status=2"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Pending Orders"}
              icon={<FaChartPie />}
              countMain={stats.pending_orders}
            />
          </NavLink>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/orders?status=3"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Complete Orders"}
              icon={<FaChartPie />}
              countMain={stats.completed_orders}
            />
          </NavLink>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/manage-host"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Number of Hosts"}
              icon={<FaChartPie />}
              countMain={stats.hosts}
            />
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/category"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Number of Menu Category"}
              icon={<FaChartPie />}
              countMain={stats.menu_cat}
            />
          </NavLink>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/manageitem"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Number of Menu Products"}
              icon={<FaChartPie />}
              countMain={stats.menu_item}
            />
          </NavLink>
        </Col>
      </Row>
    </div>
  );
}

export default Restaurant;
