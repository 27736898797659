import React, {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import {Navigation, Pagination, Scrollbar, A11y, Thumbs} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {LinkIcon} from "../../components/SvgIcons";
import {toast} from "react-toastify";
import {GetProductsById} from "../../services/products.service";
import {MoneyFormat} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FaRegEdit} from "react-icons/fa";

const ViewProduct = (props) => {
    let history = useHistory();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [data, setData] = useState();
    const [translation, setTranslation] = useState();

    useEffect(() => {
        getProduct();
    }, [])

    const getProduct = async () => {
        let id = props.match.params.id;
        await GetProductsById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    console.log(result.data);
                    setTranslation({
                        name_ar: result.data?.translations?.find(field => field.key === 'name' && field.locale === 'ar')?.message,
                        name_cn: result.data?.translations?.find(field => field.key === 'name' && field.locale === 'cn')?.message,
                        about_appearance_ar: result.data?.translations?.find(field => field.key === 'about' && field.locale === 'ar')?.message,
                        about_appearance_cn: result.data?.translations?.find(field => field.key === 'about' && field.locale === 'cn')?.message,
                        delivery_and_return_ar: result.data?.translations?.find(field => field.key === 'delivery' && field.locale === 'ar')?.message,
                        delivery_and_return_cn: result.data?.translations?.find(field => field.key === 'delivery' && field.locale === 'cn')?.message,
                    })
                    return setData(result.data);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                history.push('/products');
                return toast.error(error.response.data.message);
            }
        })
    }

    return (
        <div className={"ViewProduct"}>
            <h3 className="page-heading">{data?.name} <span onClick={() => {
                history.push('/products/edit/' + data?.id);
            }} style={{float:'right', cursor:'pointer', }}><FaRegEdit style={{fontSize:'18px'}} /></span></h3>
            <hr/>
            <div className={"productDescription"}>
                <Row>
                    {data?.attachments && data?.attachments.length > 0 ?

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Swiper className={"swiperParent"} slidesPerView={1} navigation modules={[Thumbs]}
                                    thumbs={{swiper: thumbsSwiper}}>
                                {
                                    data.attachments.map((attachment) => {
                                        return (<SwiperSlide>
                                            <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = constants.IMAGENOTFOUND;
                                }} src={attachment.mediaUrl}
                                                 className={"img-fluid img-thumbnail"}/>
                                        </SwiperSlide>)
                                    })
                                }
                            </Swiper>
                            <Swiper spaceBetween={10} slidesPerView={3} navigation modules={[Thumbs]}
                                    watchSlidesProgress
                                    onSwiper={setThumbsSwiper} className={"mt-3 swiperChild"}>

                                {
                                    data.attachments.map((attachment) => {
                                        return (<SwiperSlide>
                                            <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = constants.IMAGENOTFOUND;
                                }} src={attachment.mediaUrl}
                                                 className={"img-fluid img-thumbnail"}/>
                                        </SwiperSlide>)
                                    })
                                }
                            </Swiper>

                            {/************************************************************************************************
                             use this image when there is only one image to show
                             <img src="https://picsum.photos/300/300" className={"img-fluid img-thumbnail img-placeholder"} />
                             *************************************************************************************************/}


                        </Col>
                        : ''
                    }

                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <h3>{data?.name}</h3>
                        <p>{data?.about_appearance}</p>
                        <Row>
                            <Col xs={"12"} sm={"12"} md={"6"} lg={"6"} xl={"6"}>
                                <p><b>Store Name:</b> {data?.store?.name}</p>
                                <p><b>Min Order:</b> {data?.min_order}</p>
                                <p><b>Category:</b> {data?.category?.name}</p>
                                <p><b>Sub Category:</b> {data?.sub_category?.name}</p>
                            </Col>
                            <Col xs={"12"} sm={"12"} md={"6"} lg={"6"} xl={"6"}>
                                <p><b>Need For CSA:</b> {data?.csa_required ? 'Yes' : 'No'}</p>
                                <p><b>Colors: </b>
                                    <div style={{display: "inline-flex"}}>
                                        {
                                            data?.colors && data.colors.map((dt) => {
                                                return (
                                                    dt.value == 'White' ?
                                                    <div style={{marginRight: '5px'}}><span
                                                    style={{backgroundColor: dt.value, color:'#000', border: '0.25px solid #00000017'}}
                                                    class="badge">{dt.value}</span></div> :
                                                        <div style={{marginRight: '5px'}}><span
                                                    style={{backgroundColor: dt.value}}
                                                    class="badge">{dt.value}</span></div>
                                                )
                                            })
                                        }
                                    </div>
                                </p>
                            </Col>
                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>

                            </Col>

                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>

                            </Col>

                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
                                <p><b>Price Variation(s):</b></p>
                                <div>
                                    <table class="table table-borderless">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>Size</th>
                                            <th style={{fontSize:'12px'}}>Price</th>
                                            <th style={{fontSize:'12px'}}>Weight (KG)</th>
                                        </tr>
                                        {
                                            data?.sizes && data.sizes.map((dt) => {
                                                return (<tr>
                                                    <td style={{fontSize:'12px'}}>{dt.value}</td>
                                                    <td style={{fontSize:'12px'}}>{MoneyFormat(dt.price)}</td>
                                                    <td style={{fontSize:'12px'}}>{dt.weight}</td>
                                                </tr>)
                                            })
                                        }
                                    </table>
                                </div>
                            </Col>

                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>

                            </Col>

                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
                                <p><b>Price Bulk Price(s):</b></p>
                                <div>
                                    <table class="table table-borderless">
                                        <tr>
                                            <th style={{fontSize:'12px'}} width="20%">Discount</th>
                                            <th style={{fontSize:'12px'}} width="20%">Min</th>
                                            <th style={{fontSize:'12px'}} width="20%">Max</th>
                                        </tr>
                                        {
                                            data?.product_bulk_prices && data.product_bulk_prices.map((dt) => {
                                                return (<tr>
                                                    <td style={{fontSize:'12px'}}>{dt.discount}</td>
                                                    <td style={{fontSize:'12px'}}>{dt.min}</td>
                                                    <td style={{fontSize:'12px'}}>{dt.max}</td>
                                                </tr>)
                                            })
                                        }
                                    </table>


                                </div>
                            </Col>

                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>

                            </Col>
                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
                                <p><b>Price Matching(s):</b></p>
                                <div>
                                    <ul className="list-inline list-variations mt-3">
                                        {
                                            data?.product_matching && data.product_matching.map((dt) => {
                                                return (<li className="list-inline-item d-flex justify-content-between">
                                                    <p>{dt.platform}</p>
                                                    <p  style={{width:'20%'}}>{MoneyFormat(dt.price)}</p>
                                                    <p  style={{width:'20%'}}>
                                                        <a href={dt.external_url}>
                                                            <LinkIcon/> Link
                                                        </a>
                                                    </p>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
                                <p><b>Uploaded Description:</b></p>
                                {
                                    data?.product_description_document?.mediaUrl ?
                                        <p>
                                            <a target={"_blank"} href={data?.product_description_document.mediaUrl}
                                               style={{textDecoration: 'none', color: '#12726c'}} download>Download
                                                File</a>
                                        </p> : <p>Not Available</p>
                                }
                            </Col>
                            <Col xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
                                <p><b>Delivery And Return:</b></p>
                                <p>
                                    {data?.delivery_and_return}
                                </p>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ViewProduct
