import {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import { SendNotification } from "../services/notifications.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../services/auth/auth.service";
import { useHistory } from "react-router-dom";

const PushNotification = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState()
    const [title, setTitle] = useState()
    const [message, setMessage] = useState()
    const {register, watch, reset, handleSubmit, setValue, formState: {errors}} = useForm({
        mode: "onBlur"
    });
    const onSubmit = (data) => {
        setLoading(true);
        
        var role = []
        if (data.role == 2) {
            role = [2, 3]
        }else {
            role = [1]
        }
        data.role = role

        SendNotification(data)
        .then((data) => {
            if (data.success) {
                toast.success(data.message);
                reset(
                    {
                        role: "", 
                        title: "",
                        message: ""
                    }
                );
            } else {
                toast.error(data.message);
            }

            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
                EmptyLocalStorage();
                history.push("/");
            } else {
                return toast.error(error.response.data.message);
            }
        });

    }

    return (<>
        <div className={"usersPage"}>
            <h3 className="page-heading">Push Notifications</h3>
            <hr/>
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Form.Select size="lg"
                                         {...register("role", {
                                             onChange: (e) => {
                                                 setValue("role", e.target.value);
                                             }, required: {
                                                 value: "required", message: "Role is required",
                                             },
                                         })}
                            >
                                <option value={""} selected={true} disabled={true}>Select Role</option>
                                <option value={1}>User</option>
                                <option value={2}>Host</option>
                            </Form.Select>
                            <div>
                                {errors.role && (<span
                                    className={"text-muted validationText hasError pr-5 "}
                                    role="alert"
                                >
                                {errors.role.message}
                            </span>)}
                            </div>
                        </div>

                        <div className={"mt-3"}>
                            <Form.Label>
                                Title<small style={{color: "red"}}>*</small>
                            </Form.Label>
                            <Form.Control
                                placeholder="Enter email"
                                id={"formSignUpEmail"}
                                {...register("title", {
                                    onChange: (e) => {
                                        setValue("title", e.target.value);
                                    }, required: {
                                        value: "required", message: "Title is required",
                                    }, minLength: {
                                        value: 3, message: "Min Length is 3",
                                    }, maxLength: {
                                        value: 35, message: "Max length is 35",
                                    },
                                })}
                                type="text"
                            />
                            <div>
                                {errors.title && (<span
                                    className={"text-muted validationText hasError pr-5 "}
                                    role="alert"
                                >
                                {errors.title.message}
                            </span>)}
                            </div>
                        </div>
                        <div className={"mt-3"}>
                            <Form.Label>
                                Message<small style={{color: "red"}}>*</small>
                            </Form.Label>
                            <Form.Control
                                placeholder="Enter email"
                                id={"formSignUpEmail"}
                                {...register("message", {
                                    onChange: (e) => {
                                        setValue("message", e.target.value);
                                    }, required: {
                                        value: "required", message: "Message is required",
                                    }, minLength: {
                                        value: 3, message: "Min Length is 3",
                                    }, maxLength: {
                                        value: 35, message: "Max length is 35",
                                    },
                                })}
                                type="text"
                            />
                            <div>
                                {errors.message && (<span
                                    className={"text-muted validationText hasError pr-5 "}
                                    role="alert"
                                >
                                {errors.message.message}
                            </span>)}
                            </div>
                        </div>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={"ms-auto"}>
                            <input
                                type={"submit"}
                                className={"btn btn-green-theme w-100 mt-3"}
                                value={"Submit"}
                                disabled={loading}
                            />
                        </Col>

                    </Form>
                </Col>
            </Row>
        </div>
    </>)
}
export default PushNotification