import React, { useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import {
  ChangePasswordApi,
  EmptyLocalStorage,
} from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from "react-router-dom";

function ChangePassword() {
  const history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [newPass, ToggleNewPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [disableButton, setDisableButton] = useState(false);
  const password = useRef({});
  password.current = watch("NewPassword", "");
  const onSubmit = async (data) => {
    // await login(data.email, data.password);
    setDisableButton(true);
    let current_password = data.password;
    let password = data.NewPassword;
    let password_confirmation = data.NewConfirmPassword;

    await ChangePasswordApi(current_password, password, password_confirmation)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          reset();
          setDisableButton(false);
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Change Password</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form className={"form-add-user"} onSubmit={handleSubmit(onSubmit)}>
            <div className={"pass-field mb-3"}>
              <Form.Label>Current Password</Form.Label>
              <InputGroup controlId="CurrPassowrd">
                <FormControl
                  placeholder="Enter Password"
                  autoComplete={"off"}
                  {...register("password", {
                    required: {
                      value: "required",
                      message: "Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Min length is 8",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type={pass ? "password" : "text"}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => TogglePass(!pass)}
                >
                  {pass ? <FaEye /> : <FaEyeSlash />}
                </Button>
              </InputGroup>
              {errors.password && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.password.message}
                </Form.Text>
              )}
            </div>

            <div className={"pass-field mb-3"}>
              <Form.Label>New Password</Form.Label>
              <InputGroup controlId="NewPassword">
                <FormControl
                  placeholder="Enter New Password"
                  autoComplete={"off"}
                  {...register("NewPassword", {
                    required: {
                      value: "required",
                      message: "New Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Min length is 8",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type={newPass ? "password" : "text"}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => ToggleNewPass(!newPass)}
                >
                  {newPass ? <FaEye /> : <FaEyeSlash />}
                </Button>
              </InputGroup>
              {errors.NewPassword && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.NewPassword.message}
                </Form.Text>
              )}
            </div>

            <div className={"pass-field mb-3"}>
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup controlId="newConfirmPassowrd">
                <FormControl
                  placeholder="Confirm Password"
                  autoComplete={"off"}
                  {...register("NewConfirmPassword", {
                    required: {
                      value: "required",
                      message: "Confirm password is required",
                    },
                    validate: password.current
                      ? (value) =>
                          value === password.current ||
                          "The passwords do not match"
                      : "",
                    minLength: {
                      value: 8,
                      message: "Min length is 8",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type={confirmPass ? "password" : "text"}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={() => setConfirmPass(!confirmPass)}
                >
                  {confirmPass ? <FaEye /> : <FaEyeSlash />}
                </Button>
              </InputGroup>
              {errors.NewConfirmPassword && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.NewConfirmPassword.message}
                </Form.Text>
              )}
            </div>

            <input
              type={"submit"}
              disabled={disableButton}
              className={"btn btn-green-theme mt-3"}
              value={"Change Password"}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default ChangePassword;
