import { constants, apiUrl } from "../utils/constants";
import { GET, POST, PUT, DELETE_USER, CHECKOUT_USER, BLOCK_USER } from "./api.service.wrapper";
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service";
import { createUser } from "./firebase.service";

const user = GetAuthUser();
let role = GetUserRole();

export const GetProfile = async () => {
  let response = await GET(apiUrl.profile);
  return response;
};

export const UpdateProfile = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(apiUrl.users, id, data, config);
  return response;
};

export const GenerateQRCode = async () => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(`${apiUrl.restaurant}/${GetAuthUser()?.restaurant.id}/${apiUrl.generate_qr_code}`);
  return response;
};

export const GetUsers = async (
  keyword = "",
  role_id = "",
  page = "",
  perPage = ""
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (role_id) {
    params["role_id"] = role_id;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  var url = ""
  console.log("role:", role_id)
  console.log("res role:", constants.ROLES.RESTAURANT_OWNER)
  if (role_id == constants.ROLES.RESTAURANT_OWNER) {
    params["restaurant"] = user?.restaurant.id
    url = `restaurant/${user?.restaurant.id}/${apiUrl.restaurant_users}`
    console.log("in res")
  }else if (role_id == constants.ROLES.ROLE_ADMIN) {
    url = apiUrl.users
    console.log("in admin")
  }

  const response = await GET(url, params);
  return response;
};


export const GetHosts = async (
  keyword = "",
  role_id = "",
  page = "",
  perPage = ""
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  params["restaurant"] = user?.restaurant.id;

  if (role_id) {
    params["role_id"] = role_id;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  const response = await GET(apiUrl.hosts, params);
  return response;
};

export const GetUserById = async (id) => {
  let response = "";
  response = await GET(apiUrl.profile + "?user=" + id);
  return response;
};
export const GetCustomerById = async (id) => {
  let response = "";
  response = await GET(apiUrl.users  + "/" + id);
  console.log(response, 'response')
  return response;
};

export const GetHostById = async (id) => {
  let response = "";
  response = await GET(apiUrl.users + "/" + id);
  return response;
};

export const CreateUser = async (data) => {
  const response = await POST(apiUrl.register, data);
  return response;
};

export const UpdateUser = async (id, data) => {
  const response = await PUT(apiUrl.users, id, data);
  return response;
};

export const DeleteUser = async (id) => {
  const response = await DELETE_USER(apiUrl.deleteusers, { user_id: id });
  return response;
};

export const CheckoutUser = async (id) => {
  var url = `restaurant/${user?.restaurant.id}/${apiUrl.checkout}`
  const response = await CHECKOUT_USER(url, { user_id: id });
  return response;
};

export const BlockUser = async (id, data) => {
  const response = await POST(apiUrl.users + "/" + id + "/block", data);
  return response;
};

export const ChatNotification = async (data) => {
  const response = await POST(apiUrl.chat_notification, data);
  return response;
};
