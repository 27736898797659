import React, {useEffect, useState} from 'react'
import {GetUserRole} from "../../services/auth/auth.service"
import {AdminDashboard, RestaurantDashboard, CsaDashboard} from "./ByRole/index"
import {constants} from "../../utils/constants";

function Dashboard() {
    const [dashboard, setDashboard] = useState();

    useEffect(() => {
        let role = GetUserRole();

        if(role == constants.ROLES.ROLE_ADMIN){
            setDashboard(<AdminDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.RESTAURANT_OWNER){
            setDashboard(<RestaurantDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_CSA){
            setDashboard(<CsaDashboard RoleId={role} />);
        }
    }, []);

    return (
        <div>
            {dashboard}
        </div>
    )
}

export default Dashboard
