import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetProfile, UpdateProfile } from "../../services/users.service";
import { UploadIcon } from "../../components/SvgIcons";
import { toast } from "react-toastify";
import {
  EmptyLocalStorage,
  GetAccessToken,
  GetUserRole,
  SetAuthUser,
} from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { updateUser } from "../../services/firebase.service";
import UserContext from "../../hooks/UserContext";

function EditProfile(props) {
  let history = useHistory();
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(async () => {
    getProfile();
  }, []);

  const getProfile = async () => {
    await GetProfile()
      .then(async (result) => {
        if (result.success) {
          if (result.data) {
            let dt = result.data;
            if (dt) {
              setValue("id", dt.id);
              setValue("full_name", dt.profile ? dt.profile.full_name : "");
              setValue("email", dt.email);
              setValue("phone", dt.profile ? dt.profile.phone_number : "");
              await setDefaultImage(
                dt.profile
                  ? dt.profile.profile_image
                  : constants.USERIMAGENOTFOUND
              );
            }
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
        toast.error(error.response.data.message);
      });
  };

  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("full_name", data.full_name.trim());
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    // formData.append('dob', '2001-02-02');
    // formData.append('company_name', 'Administrator');
    // formData.append('business_type', 'Admin');
    if (data.media[0]) {
      formData.append("media", data.media[0]);
    }
    setDisableButton(true);
    await UpdateProfile(data.id, formData)
      .then(async (data) => {
        if (data.success) {
          if (
            GetUserRole() == constants.ROLES.ROLE_CSA ||
            GetUserRole() == constants.ROLES.ROLE_VENDOR
          ) {
            //update data on firestore
            let newData = {
              user: data.data,
              access_token: GetAccessToken(),
            };
            await updateUser(data.data);
            await SetAuthUser(JSON.stringify(newData));
          }
          toast.success(data.message);
          history.push("/profile");
          setDisableButton(false);
        } else {
          toast.error(data.message);
          setDisableButton(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <div className={"EditProfile"}>
      <h3 className="page-heading">Edit Profile</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form
            encType="multipart/form-data"
            className="formAddUser"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      {...register("media")}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ul className={"list-inline list-variations mb-0"}>
                    <li
                      className={
                        "list-inline-item d-flex justify-content-between mb-0"
                      }
                    >
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = constants.IMAGENOTFOUND;
                        }}
                        src={file ? file : defaultImage}
                        width="50%"
                        height="50%"
                      />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>

            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                name="full_name"
                maxLength={"25"}
                {...register("full_name", {
                  onChange: (e) => {
                    setValue("full_name", e.target.value);
                  },
                  required: {
                    value: "required",
                    message: "Full name is required",
                  },
                })}
              />
              <Form.Text className="text-muted validationText hasError">
                {errors.full_name && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.full_name.message}
                  </Form.Text>
                )}
              </Form.Text>
            </Form.Group>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    readOnly
                    {...register("email")}
                    maxLength={"255"}
                  />
                  <Form.Text className="text-muted validationText hasError">
                    {errors.email && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
              {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="formContact">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone", {
                      onChange: (e) => {
                        setValue("phone", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\+[0-9]*$/,
                        message: "incorrect pattern +xxxxxxxxxxxx",
                      },
                    })}
                    maxLength={"16"}
                  />
                  <Form.Text className="text-muted validationText hasError">
                    {errors.phone && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.phone.message}
                      </Form.Text>
                    )}
                  </Form.Text>
                </Form.Group>
              </Col> */}
            </Row>

            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <input
                type={"submit"}
                disabled={disableButton}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Edit User"}
              />
            </Col>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default EditProfile;
