import React, {useEffect, useState} from 'react'
import {Row, Col, Button} from 'react-bootstrap';
import {FaChartPie} from "react-icons/fa";
import DashboardStat from '../../../components/DashboardStat'
import {GetDashboard} from "../../../services/admin/dashboard.service"
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom"
import {constants} from "../../../utils/constants";
import {EmptyLocalStorage} from "../../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

const InitialStats = {
    orders_count: '',
    confirmed_orders_count: '',
    cancelled_orders_count: '',
    paid_orders_count: '',
    shipped_orders_count: '',
    completed_orders_count: '',
    reviewed_orders_count: '',
    returned_orders_count: '',
    products_count: '',
    csa_agents_count: '',
    vendors_count: '',
    ads_count: '',
}

function Vendor({RoleId}) {
    const history = useHistory();
    const [stats, setStats] = useState(InitialStats);
    useEffect(async () => {
        let params = {role_id: RoleId}
        await GetDashboard(params).then((data) => {
            if (data.status) {
                setStats({
                    orders_count: data.data.orders_count,
                    confirmed_orders_count: data.data.confirmed_orders_count,
                    cancelled_orders_count: data.data.cancelled_orders_count,
                    paid_orders_count: data.data.paid_orders_count,
                    shipped_orders_count: data.data.shipped_orders_count,
                    completed_orders_count: data.data.completed_orders_count,
                    reviewed_orders_count: data.data.reviewed_orders_count,
                    returned_orders_count: data.data.returned_orders_count,
                    products_count: data.data.products_count,
                    csa_agents_count: data.data.csa_agents_count,
                    vendors_count: data.data.vendors_count,
                    ads_count: data.data.ads_count,
                })
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }, [])

    return (
        <div>
            <Row>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.ORDERED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"theme-green"} title={"Received Orders"} icon={<FaChartPie/>}
                                       countMain={stats.orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.CONFIRMED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"theme-danger"} title={"Confirmed Orders"} icon={<FaChartPie/>}
                                       countMain={stats.confirmed_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.CANCELLED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"theme-success"} title={"Cancelled Orders"} icon={<FaChartPie/>}
                                       countMain={stats.cancelled_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.PAID}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-yellow"} title={"Paid Orders"} icon={<FaChartPie/>}
                                       countMain={stats.paid_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.SHIPPED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-pink"} title={"Shipped Orders"} icon={<FaChartPie/>}
                                       countMain={stats.shipped_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.COMPLETED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-green"} title={"Completed Orders"} icon={<FaChartPie/>}
                                       countMain={stats.completed_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.REVIEWED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Reviewed Orders"} icon={<FaChartPie/>}
                                       countMain={stats.reviewed_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.RETURNED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Returned Orders"} icon={<FaChartPie/>}
                                       countMain={stats.returned_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to="/products" style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                    <DashboardStat variant={"gradient-blue"} title={"Total Products"} icon={<FaChartPie/>}
                                   countMain={stats.products_count}/>
                    </NavLink>
                </Col>
            </Row>
        </div>
    )
}

export default Vendor
