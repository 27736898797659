import { constants, apiUrl } from "../../utils/constants";
import { POST } from "../api.service.wrapper";

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;

export const Login = async (
  email,
  password,
  role_id,
  device_type,
  device_token,
  remember_me
) => {
  const response = await POST(apiUrl.login, {
    email,
    password,
    role_id,
    device_type,
    device_token,
  });
  SetToken(response.data.token);
  SetAuthUser(JSON.stringify(response?.data), remember_me);
  return response;
};

export const SetToken = (token) => {
  if (token) localStorage.setItem(storageKey, token);
  else localStorage.removeItem(storageKey);
};

export const GetToken = () => {
  return localStorage.getItem(storageKey);
};

export const SetAuthUser = (user_data, rememberMe) => {
  if (user_data) {
    localStorage.setItem(storageUser, user_data);

    let expiration = 0;
    const now = new Date();
    if (rememberMe) {
      expiration = 604800; // 7 days
    } else {
      expiration = 86400; // 1 day
    }
    const item = now.getTime() / 1000 + expiration;
    //   localStorage.setItem("rememberMe", JSON.stringify(item))

    localStorage.setItem("rememberMe", JSON.stringify(item));
  } else {
    localStorage.removeItem(storageUser);
    localStorage.removeItem("rememberMe");
  }
};

export const GetAuthUser = () => {
  return JSON.parse(localStorage.getItem(storageUser));
};

export const GetAccessToken = () => {
  return JSON.parse(localStorage.getItem(storageUser))?.access_token;
};

export const Logout = async () => {
  const response = await POST(apiUrl.logout);
  return response;
};

export const EmptyLocalStorage = async () => {
  localStorage.removeItem(storageUser);
  localStorage.removeItem("rememberMe")
  localStorage.removeItem(storageKey);
};

export const GetCurrentUser = () => {
  return JSON.parse(localStorage.getItem(storageUser));
};

export const ForgetPasswordApi = async (email) => {
  const response = await POST(apiUrl.forgot_password, { email });
  return response;
};

export const VerifyOtpApi = async (otp, email) => {
  const response = await POST(apiUrl.verify_otp, { otp, email });
  return response;
};

export const ResendOTP = async (email) => {
  const response = await POST(apiUrl.resend_otp, {email});
  return response;
}

export const ResetPasswordApi = async (
  email,
  otp,
  new_password,
  password_confirmation
) => {
  const response = await POST(apiUrl.reset_password, {email, otp, new_password, password_confirmation});
  return response;
};

export const ChangePasswordApi = async (
  old_password, new_password, password_confirmation
) => {
  const response = await POST(apiUrl.change_password, {
    old_password, new_password, password_confirmation
  });
  return response;
};

export const GetUserRole = () => {
  // const user = GetAuthUser();
  // let role;
  // if (user && user.roles) {
  //     let role_id = user.roles[0].id;
  //     if (role_id == constants.ROLES.ROLE_ADMIN) {
  //         role = constants.ROLES.ROLE_ADMIN;
  //     } else if (role_id == constants.ROLES.ROLE_VENDOR) {
  //         role = constants.ROLES.ROLE_VENDOR;
  //     } else if (role_id == constants.ROLES.ROLE_CSA) {
  //         role = constants.ROLES.ROLE_CSA;
  //     } else {
  //         role = constants.ROLES.ROLE_USER;
  //     }
  // }
  const user = GetAuthUser();
  if (user) {
    return user.role;
  }
  console.log(user+"Helloworld")
//   return role;
};
