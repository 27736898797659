import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import {Editor} from "react-draft-wysiwyg";
import { GetPageById, UpdatePage } from "../../services/pages.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

function PageEdit(props) {
  let history = useHistory();
  const [data, setData] = useState([]);

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  const getPagesById = async (id) => {
    await GetPageById(id)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            setData({
              id: result.data.id,
              slug: result.data.slug,
              title: result.data.title,
              content: result.data.content,
              created_at: moment(result.data.created_at).fromNow(),
            });
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  useEffect(async () => {
    let id = props.match.params.id;
    await getPagesById(id);
    if (data) {
      reset({
        title: data.title,
        content: data.content,
      });
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    let content = "";
    let id = props.match.params.id;
    await UpdatePage(id, {
      title: data.title,
      content: data.content,
    })
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          history.push("/page-list");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
    console.log("This page data!" + "" + data);
  };

  return (
    <div className={"PageEdit"}>
      <h3 className="page-heading">Page Management</h3>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={"PageEditForm mt-3"}>
          <div className="">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Page Title</Form.Label>
                  <Form.Control
                    placeholder="Page Title"
                    defaultValue={data.title}
                    {...register("title", {
                      required: {
                        value: "required",
                        message: "Page Title is required",
                      },
                      minLength: {
                        value: 5,
                        message: "max length is 5",
                      },
                      maxLength: {
                        value: 255,
                        message: "max length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className={""}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="content">
                  <Form.Label>Description</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      {...register("content", {
                        required: {
                          value: "required",
                          message: "Page Content is required",
                        },
                      })}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={data.content}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("content", data);
                        //console.log( { event, editor, data } );
                      }}
                      onBlur={(event, editor) => {
                        //console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        //console.log( 'Focus.', editor );
                      }}
                    />
                  </div>
                  {errors.content && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.content.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  value={"Submit"}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default PageEdit;
