import React, {useEffect, useRef, useState} from 'react'
import {AiOutlineMenu} from "react-icons/ai";
import {FaTimesCircle, FaUsers, FaUser} from "react-icons/fa";
import AttachIcon from "../../assets/images/attch_big.png";
import SendIcon from "../../assets/images/send_icon.png";
import Thread from "./threads/Thread";
import {
    checkUserOnline,
    deleteEveryOneMessage,
    deleteEveryOneUserChatMessage,
    deleteMessage,
    deleteUserChatMessage, deleteVendorMessage,
    getUserImage, readMessage,
    submitMessage,
    submitUserChatMessage,
    updateLastMessage,
    updateUserChatLastMessage
} from "../../services/firebase.service";
import {toast} from "react-toastify";
import {UploadImageToS3} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import BarLoader from "react-spinners/BarLoader";
import ThemeModal from "../ThemeModal";
import {Row, Col, Button, Form} from 'react-bootstrap';
import {ChatNotification} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

const ChatConversation = ({orderChat, setSideBar, active_thread, messages, active_customer, current_user}) => {
    const history = useHistory();
    const [sideBarToggle, setSideBarToggle] = useState(false)
    const [file, setFile] = useState()
    const [fileName, setFileName] = useState()
    const fileInput = useRef()
    const messagesEndRef = useRef(null)
    const [senderImage, setSenderImage] = useState()
    const [receiverImage, setReceiverImage] = useState()
    const [textareaDisabled, setTextareaDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)
    const [delModalShow, setDelModalShow] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState({})

    const onNavClick = () => {
        setSideBar(!sideBarToggle);
        setSideBarToggle(!sideBarToggle);
    }

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            const element = messagesEndRef.current;
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                behavior: "auto"
            })
        }
    }

    useEffect(() => {
        receiver_image(current_user.id)
        sender_image(active_customer)
    }, [active_customer])

    useEffect(() => {
        readMessage({
            active_thread: active_thread,
            customer_id: active_customer
        });

        scrollToBottom()
    }, [messages]);

    const sender_image = async (image) => {
        await getUserImage(image, (sender_image) => {
            setSenderImage(sender_image);
        })
    }

    const receiver_image = async (image) => {
        await getUserImage(image, (receiver_image) => {
            setReceiverImage(receiver_image);
        })
    }

    const onKeyDownMessage = async (e) => {
        setMessage(e);
        if (e.keyCode == 13) {
            if (e.ctrlKey) {
                //todo: on press ctrl + enter break to new line
                return true;
            } else {
                await SubmitMessage(e)
            }
        }
    }

    const SubmitMessage = async (e) => {
        const file = fileInput?.current?.files[0];
        let is_vendor = current_user.roles[0].id == constants.ROLES.ROLE_VENDOR;
        if (e.target.value.trim().length > 0 || file) {
            e.preventDefault();
            let url = '';
            let filename = '';
            let content_type = 'text';
            if (file) {
                setLoading(true);
                let uploadImage = await UploadImageToS3(fileInput);
                if (uploadImage) {
                    if (!uploadImage.success) {
                        toast.error(uploadImage.message);
                        return false;
                    }

                    content_type = constants.CONTENTTYPE[file.type];
                    url = uploadImage.data;
                    filename = file.name;
                }
                setFile('');
                setFileName('');
                let data = {
                    chatId: active_thread.toString(),
                    content: filename,
                    contentType: content_type,
                    delete: 0,
                    deleteAgent: 0,
                    idFrom: current_user.id.toString(),
                    idTo: active_customer.toString(),
                    isread: false,
                    timestamp: new Date().getTime(),
                    url: url,
                    videoThumbnail: "",
                    isVendor: is_vendor,
                }

                if (orderChat) {
                    data.vendorIds = messages[0] ? messages[0].vendorIds : []
                }

                let submit_message = orderChat ? submitMessage(data) : submitUserChatMessage(data);
                //let submit_message = submitMessage(data);
                submit_message.then(async (data) => {
                    if (data) {
                        //Send Push Notifciation To User
                        let cn_data = {
                            user_id: active_customer,
                            active_thread: active_thread,
                        }
                        await chatNotification(cn_data);
                        fileInput.current.value = "";
                        e.target.focus();
                    }
                });
                setLoading(false);
            }

            let message = e.target.value.trim();
            if (message) {
                e.target.value = '';
                fileInput.current.value = "";
                let data = {
                    chatId: active_thread.toString(),
                    content: message,
                    contentType: 'text',
                    delete: 0,
                    deleteAgent: 0,
                    idFrom: current_user.id.toString(),
                    idTo: active_customer.toString(),
                    isread: false,
                    timestamp: new Date().getTime(),
                    url: url,
                    videoThumbnail: "",
                    isVendor: is_vendor,
                }

                if (orderChat) {
                    data.vendorIds = messages[0] ? messages[0].vendorIds : []
                }

                //let submit_message = submitMessage(data);
                let submit_message = orderChat ? submitMessage(data) : submitUserChatMessage(data);
                submit_message.then(async (data) => {
                    //Send Push Notifciation To User
                    let cn_data = {
                        user_id: active_customer,
                        active_thread: active_thread,
                        orderChat: orderChat
                    }

                    await chatNotification(cn_data);

                    setTextareaDisabled(true);

                    if (data) {
                        if (orderChat) {
                            updateLastMessage(current_user.id.toString(), active_thread.toString(), message.substring(0, 70))
                            updateLastMessage(active_customer.toString(), active_thread.toString(), message.substring(0, 70))
                        } else {
                            updateUserChatLastMessage(current_user.id.toString(), active_thread.toString(), message.substring(0, 70))
                            updateUserChatLastMessage(active_customer.toString(), active_thread.toString(), message.substring(0, 70))
                        }

                        e.target.value = '';
                        fileInput.current.value = "";
                        setTextareaDisabled(false);
                        e.target.focus();
                    }
                });
            }
        }
    }

    const chatNotification = async (cn_data) => {
        await checkUserOnline(cn_data, async (data) => {
            if (!data.online) {
                await ChatNotification(cn_data).then((result) => {
                    if (result.status) {
                        return {success: true};
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        console.log(error.response.data.message);
                        return {success: false};
                    }
                })
            }
        });
    }

    function handleChange(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0]['name']);
    }

    const deleteForMe = async (data) => {
        let is_vendor = current_user.roles[0].id == constants.ROLES.ROLE_VENDOR;
        let delete_data = {
            'active_thread': await data.active_thread,
            'message_id': await data.message_id,
            'deleted_at': constants.CHATMESSAGE.CONTENT_SOFT_DELETE
        }
        await orderChat ? is_vendor ? await deleteVendorMessage(delete_data) : deleteMessage(delete_data) : deleteUserChatMessage(delete_data)
        //await deleteMessage(delete_data)
        setDelModalShow(false)
    }

    const deleteForEveryOne = async (data) => {
        let delete_data = {
            'active_thread': await data.active_thread,
            'message_id': await data.message_id,
            'deleted_at': constants.CHATMESSAGE.CONTENT_HARD_DELETE
        }
        await orderChat ? deleteEveryOneMessage(delete_data) : deleteEveryOneUserChatMessage(delete_data)
        //await deleteMessage(delete_data)
        setDelModalShow(false)
    }

    const DeleteModal = (data) => {
        return (
            <div className={"modalBodyContent mt-0"}>
                <Row>
                    <Col md={12}>
                        <ul className={"list-unstyled mb-0"}>
                            <li>
                                <Button onClick={() => deleteForMe(data.data)} className={"btn-green-theme mb-2"}>
                                    <FaUser/> Delete For Me
                                </Button>
                            </li>
                            {
                                data.data.type === 'received' ? <li>
                                    <Button onClick={() => deleteForEveryOne(data.data)}
                                            className={"btn-green-theme"}>
                                        <FaUsers/> Delete For Everyone
                                    </Button>
                                </li> : ''
                            }
                        </ul>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className="tab-content chat-des">
            <div id="conversaion_starts" className="tab-pane active">
                <span className="title">
                    <span className={"MenuIcon"} onClick={() => onNavClick()}>
                        <AiOutlineMenu/>
                    </span>
                    <h3>{orderChat ? 'ORDER#' + active_thread : ''} {/*<strong>is typing....</strong>*/}</h3>
                </span>
                <div className="message-info" ref={messagesEndRef}>
                    {
                        messages && messages.length > 0 ?
                            messages.map((message) => {
                                if (message.mediaType) {
                                    return (
                                        <Thread activeThread={active_thread}
                                                type={message.type}
                                                text={message.text}
                                                profileImg={message.profileImg}
                                                receivedAt={message.timestamp}
                                                media={message.media}
                                                mediaType={message.mediaType}
                                                senderImage={senderImage}
                                                receiverImage={receiverImage}
                                                isRead={message.isRead}
                                                setDelModalShow={setDelModalShow}
                                                setDeleteModalData={setDeleteModalData}
                                                product={message.product ? message.product : ''}
                                        />
                                    )
                                } else {
                                    return (
                                        <Thread activeThread={active_thread}
                                                type={message.type}
                                                text={message.text}
                                                profileImg={message.profileImg}
                                                receivedAt={message.timestamp}
                                                senderImage={senderImage}
                                                receiverImage={receiverImage}
                                                isRead={message.isRead}
                                                setDelModalShow={setDelModalShow}
                                                setDeleteModalData={setDeleteModalData}
                                                product={message.product ? message.product : ''}
                                        />
                                    )
                                }
                            })
                            : <span style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'end',
                                height: '100%',
                                fontSize: '10px'
                            }}>To begin a chat, send a message</span>
                    }
                    <ThemeModal className={"deleteModal"} title={"Delete"} size={"sm"}
                                content={delModalShow ? <DeleteModal data={deleteModalData}/> : ''}
                                show={delModalShow}
                                onHide={() => setDelModalShow(false)}/>
                </div>


                <div className="reply">
                    <BarLoader color={'#12726C'} loading={loading} css={'marginTop: 10px;'} height={'4'}
                               width={'100%'}/>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                    }}>
                        <div className="reply-area">
                            <input type="text"
                                   disabled={textareaDisabled}
                                   className="form-control"
                                   placeholder="Type your message...."
                                   onKeyDown={onKeyDownMessage}
                                   style={{
                                       borderRadius: '20px',
                                       boxShadow: 'none',
                                       marginRight: '0px',
                                       float: 'right',
                                       resize: 'none',
                                       border: '0px',
                                       fontSize: '12px',
                                       height: '40px',
                                       background: '#efeded',
                                       paddingRight: "70px"
                                   }}
                            />
                        </div>

                        <div className="attach" style={{
                            position: 'absolute',
                            top: "15%",
                            right: "20px",
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <Form.Group controlId="userFile" className="" style={{marginRight: "10px"}}>
                                <Form.Label style={{marginBottom: 0}}>
                                    <img src={AttachIcon} alt="Add Attachments" width="16"/>
                                </Form.Label>
                                <Form.Control type="file"
                                              name="file"
                                              className={"d-none"}
                                              onChange={handleChange}
                                              ref={fileInput}
                                              accept="image/png,image/jpeg,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/msexcel,audio/mp3,video/mp4"
                                />
                            </Form.Group>
                            <Form.Label style={{marginBottom: 0, marginRight: "10px"}}>
                                <img src={SendIcon} onClick={() => SubmitMessage(message)} alt="Send Message" width="16"
                                     style={{filter: "invert(0.4)", width: "20px"}}/>
                            </Form.Label>
                        </div>
                    </div>

                    <div className="upload-main-div" style={{
                        width: '100%',
                        background: '#000',
                        color: '#fff',
                        position: 'absolute',
                        bottom: '52px',
                        padding: '4px',
                        display: (file) ? 'block' : 'none'
                    }}>
                        <div className="upload-image-div" style={{width: '100%', position: 'relative'}}>
                            <div>
                                <FaTimesCircle style={{position: 'absolute', right: '0', background: '#000'}}
                                               onClick={() => {
                                                   setFile('')
                                                   setFileName('')
                                                   fileInput.current.value = "";
                                               }}/>
                                <div style={{fontSize: '10px'}}>{fileName}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ChatConversation
