import React, {useRef, useState, useEffect} from 'react'
import {Form, Row, Col, InputGroup, FormControl, Button} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import {useForm} from "react-hook-form";
import {CreateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import {constants} from "../../utils/constants";
import {createUser} from "../../services/firebase.service";
import {format} from "date-fns";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FetchCityById, FetchCountries, FetchStateById, ValidateAddress} from "../../services/aramex.service";

function AddNewVendor(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [disableButton, setDisableButton] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState('');
    const [cities, setCities] = useState([]);
    const [cityId, setCityId] = useState('');
    const [disableField, setDisableField] = useState(true);

    const {register, watch, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    const password = useRef({});
    password.current = watch("password", "");

    /*useEffect(() => {
        fetchCountries()
    }, [])*/

    useEffect(() => {
        if (countryId) {
            fetchStates()
            fetchCities()
        }
    }, [countryId])

    //Fetch Countries
    /*const fetchCountries = async () => {
        await FetchCountries().then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let countries = result.data?.Countries;
                    setCountries(countries)
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }*/

    //Fetch States
    const fetchStates = async () => {
        await FetchStateById(countryId).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let states = result.data?.States;
                    setStates(states)
                    setDisableField(false);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    //Fetch Cities
    const fetchCities = async () => {
        await FetchCityById(countryId).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let cities = result.data?.Cities;
                    setCities(cities)
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const validateAddress = async data => {

    }

    const onSubmit = async data => {
        setDisableButton(true)
        let address_data = {
            address: data.address.trim(),
            city: data.city.trim(),
            state: data.state.trim(),
            zip_code: data.zip_code.trim(),
            country_code: data.country.trim(),
        }

        //Validate Address From Aramex
        await ValidateAddress(address_data).then(async (result) => {
            if (result.status) {
                await CreateUser({
                    full_name: data.full_name.trim(),
                    username: data.username.trim(),
                    email: data.email.trim(),
                    phone: data.phone.trim(),
                    password: data.password.trim(),
                    password_confirmation: data.password_confirmation.trim(),
                    dob: data.dob,
                    company_name: data.company_name.trim(),
                    business_type: data.business_type.trim(),
                    name: data.store_name.trim(),
                    address: data.address.trim(),
                    description: data.description.trim(),
                    city: data.city.trim(),
                    country: data.country.trim(),
                    country_code: data.country.trim(),
                    state: data.state.trim(),
                    zip_code: data.zip_code.trim(),
                    role_id: constants.ROLES.ROLE_VENDOR.toString(),
                    device_type: 'web',
                    device_token: 'vendor'
                }).then(async (data) => {
                    if (data.status) {
                        await createUser(data.data.user).then(() => {
                            toast.success(data.message);
                            history.push('/vendors');
                        })
                    } else {
                        setDisableButton(false)
                        toast.error(data.message);
                    }
                }).catch((error) => {
                    setDisableButton(false)
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            } else {
                setDisableButton(false)
                return toast.error(result.message[0]);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                setDisableButton(false)
                return toast.error(error.response.status == 403 ? error.response.data.message[0] : error.response.data.message);
            }
        })
    };

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Create Vendor</h3>
            {/*<p style={{fontSize: '11px'}}>Important Note: The shipping amount will not be
                                calculated unless you provide a valid address</p>*/}
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        {...register('full_name', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.full_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.full_name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Username"
                                        id={"formSignUpName"}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Username is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="email"/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Phone Number"
                                        {...register("phone", {
                                                required: {
                                                    value: "required",
                                                    message: "Phone is required"
                                                },
                                                pattern: {
                                                    value: /^\+[0-9]*$/,
                                                    message: "incorrect pattern +xxxxxxxxxxxx"
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: "max length is 16"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.phone && <Form.Text
                                        className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date Of Birth</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Date Of Birth"
                                        max={format(new Date(), 'yyyy-MM-dd')}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "Date of birth is required"
                                            },
                                        })} type="date"/>
                                    {errors.dob && <Form.Text
                                        className="text-muted validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "Company name is required"
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "min length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "Business type is required"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "min length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="store_name">
                                    <Form.Label>Store Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Store Name"
                                        {...register('store_name', {
                                            required: {
                                                value: "required",
                                                message: "Store name is required"
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "min length is 6"
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "max length is 50"
                                            },
                                        })} type="text"/>
                                    {errors.store_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.store_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Store Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Address"
                                        {...register("address", {
                                                required: {
                                                    value: "required",
                                                    message: "Address is required"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "min length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.address && <Form.Text
                                        className="text-muted validationText hasError">{errors.address.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Store Country</Form.Label>
                                    <Form.Select
                                        {...register("country", {
                                                required: {
                                                    value: "required",
                                                    message: "Country is required"
                                                }
                                            }
                                        )}
                                        onChange={e => {
                                            setCountryId(e.target.value);
                                            setDisableField(true);
                                        }}
                                        aria-label="Default Filters">
                                        <option value="">Select Country</option>
                                        <option value="cn">China</option>
                                        {/*{
                                            countries ?
                                                countries.map((country) => {
                                                    return (<option value={country.Code}>{country.Name}</option>);
                                                }) : ''
                                        }*/}
                                    </Form.Select>

                                    {errors.country && <Form.Text
                                        className="text-muted validationText hasError">{errors.country.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="state">
                                    <Form.Label>Store State</Form.Label>
                                    <Form.Select
                                        disabled={disableField}
                                        {...register("state")}
                                        onChange={e => {
                                            setStateId(e.target.value);
                                        }}
                                        aria-label="Default Filters">
                                        <option value="">Select State</option>
                                        {
                                            states ?
                                                states.map((state) => {
                                                    return (<option value={state.Name}>{state.Name}</option>);
                                                }) : ''
                                        }
                                    </Form.Select>
                                    {errors.state && <Form.Text
                                        className="text-muted validationText hasError">{errors.state.message}</Form.Text>}
                                </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>Store City</Form.Label>
                                    <Form.Select
                                        disabled={disableField}
                                        {...register("city", {
                                                required: {
                                                    value: "required",
                                                    message: "City is required"
                                                }
                                            }
                                        )}
                                        onChange={e => {
                                            setCityId(e.target.value);
                                        }}
                                        aria-label="Default Filters">
                                        <option value="">Select City</option>
                                        {
                                            cities ?
                                                cities.map((city) => {
                                                    return (<option value={city}>{city}</option>);
                                                }) : ''
                                        }
                                    </Form.Select>
                                    {errors.city && <Form.Text
                                        className="text-muted validationText hasError">{errors.city.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="zip_code">
                                    <Form.Label>Store ZipCode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter ZipCode"
                                        {...register("zip_code", {
                                                required: {
                                                    value: "required",
                                                    message: "Zip code is required"
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "min length is 3"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.zip_code && <Form.Text
                                        className="text-muted validationText hasError">{errors.zip_code.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={10}

                                        placeholder="Enter Description"
                                        {...register('description', {
                                            required: {
                                                value: "required",
                                                message: "Description is required"
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: "max length is 1000"
                                            },
                                        })} type="text"/>
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            {...register('password', {
                                                required: {
                                                    value: "required",
                                                    message: "Password is required"
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            onClick={() => setConfirmPass(!confirmPass)}
                                            type={pass ? "password" : "text"}/>
                                        <Button variant="outline-secondary" id="button-addon2"
                                                onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye/> : <FaEyeSlash/>
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text
                                        className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            disabled={confirmPass}
                                            {...register('password_confirmation', {
                                                required: {
                                                    value: "required",
                                                    message: "Confirm Password is required"
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" disabled={confirmPass}
                                                onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye/> : <FaEyeSlash/>
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text
                                        className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"}
                                       className={"btn btn-green-theme w-100 mt-3"}
                                       disabled={disableButton}
                                       value={"Create Vendor"}/>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AddNewVendor
