import React, { useState, useEffect } from "react";
import {
  AddIcon,
  AddIconOutline,
  DeleteRed,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import {
  Col,
  Row,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import { useForm, Controller } from "react-hook-form";
import { UploadIcon } from "../../components/SvgIcons";
import {
  CreateRestaurant,
  UpdateRestaurant,
  GetRestaurantById,
  GetRestaurantCategories,
  GetRestaurantCategoriesExclude,
} from "../../services/categories.service";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import { Country } from "./Country";
import TimePicker from "react-times";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import { getMonth, getYear } from "date-fns";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const AddNewResturant = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const [file, setFileData] = useState(false);
  // const [time, setTime] = useState(new Date().getHours() + ":" + new Date().getMinutes());
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [utcOpeningTime, setUTCOpeningTime] = useState("");
  const [utcClosingTime, setUTCClosingTime] = useState("");
  const [showTime, setShowTime] = useState(true);
  const [newtimee, setNewTime] = useState(
    new Date().getHours() + ":" + new Date().getMinutes()
  );
  const [addressSource, setAddressSource] = useState("");
  const [destinationFocus, setDestinationFocus] = useState(false);
  const [latLng, setLatLng] = useState({});
  const [addressComponents, setAddressComponents] = useState({});
  const [showNewTime, setShowNewTime] = useState(true);
  const [image, setimage] = useState(null);
  const [rowsCategories, setRowsCategories] = useState([]);
  const [defaultRowsCategories, setdefaultRowsCategories] = useState([]);
  const [restaurant, setRestaurant] = useState([]);
  const [defaultImage, setDefaultImage] = useState(false);
  const [disableCateogies, setDisableCateogies] = useState(true);
  const [url, setUrl] = useState();

  const onOpeningTimeChange = (options) => {
    let time = moment(
      options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let full_start_date = moment(time, "HH:mm:ss").format("HH:mm:ssZ");
    const utc_start_time = moment
      .utc(full_start_date, "HH:mm:ssZ")
      .format("HH:mm:ssZ");
    setOpeningTime(time);
    setUTCOpeningTime(utc_start_time);
  };

  const onClosingTimeChange = (options) => {
    let time = moment(
      options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
      ["h:mm A"]
    ).format("HH:mm:ss");
    let full_end_date = moment(time, "HH:mm:ss").format("HH:mm:ssZ");
    const utc_end_time = moment
      .utc(full_end_date, "HH:mm:ssZ")
      .format("HH:mm:ssZ");
    setClosingTime(time);
    setUTCClosingTime(utc_end_time);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    // let time = moment.utc(new Date()).format("hh:mm a")
    let time = moment(new Date()).format("HH:mm:ss");
    let full_end_date = moment(time, "HH:mm:ss").format("HH:mm:ssZ");
    const utc_end_time = moment
      .utc(full_end_date, "HH:mm:ssZ")
      .format("HH:mm:ssZ");
    setClosingTime(time);
    setUTCClosingTime(utc_end_time);
    setOpeningTime(time);
    setUTCOpeningTime(utc_end_time);

    let id = props.match.params.id;
    setUrl(id);
    if (id) {
      getRestaurantById(id);
    } else {
      getCategories();
    }
  }, []);

  const getCategories = async () => {
    await GetRestaurantCategories(page, perPage, keyword)
      .then((result) => {
        if (result.success) {
          if (result.data.data) {
            const rowData = [];
            result.data.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.name,
              });
            });
            setRowsCategories(rowData);
            setDisableCateogies(false);
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        return toast.error(error);
      });
  };

  const getCategoriesExclude = async (id, selectedRows) => {
    setDisableCateogies(true);
    await GetRestaurantCategoriesExclude(id, page, perPage, keyword)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            setDisableCateogies(false);
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.name,
              });
            });
            setRowsCategories(rowData);

            setdefaultRowsCategories(selectedRows);
            reset({
              categories: selectedRows,
              disableCateogies: false,
            });
          }
        } else {
          setDisableCateogies(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setDisableCateogies(false);
        return toast.error(error);
      });
  };

  const getRestaurantById = async (id) => {
    await GetRestaurantById(id)
      .then(async (result) => {
        if (result.success) {
          if (result.data) {
            setDefaultImage(result.data.image || constants.USERIMAGENOTFOUND);
            setAddressSource(result.data.address);

            const current = new Date();
            let start_time = moment(
              `${current.getFullYear()}-${
                current.getMonth() + 1
              }-${current.getDate()}` +
                "T" +
                result.data.opening_time +
                "Z",
              "YYYY-MM-DDTHH:mm:ssZ"
            )
              .local()
              .format("hh:mm A");
            let end_time = moment(
              `${current.getFullYear()}-${
                current.getMonth() + 1
              }-${current.getDate()}` +
                "T" +
                result.data.closing_time +
                "Z",
              "YYYY-MM-DDTHH:mm:ssZ"
            )
              .local()
              .format("hh:mm A");

            setOpeningTime(start_time);
            setClosingTime(end_time);

            const rowData = [];
            result.data.categories.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.name,
              });
            });

            getCategoriesExclude(id, rowData);
            return setRestaurant(result.data);
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  const handleAddressChange = (address) => {
    setAddressSource(address);
  };

  const handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then((latLng) => {
          setAddressSource(address);
          let countryObj =
            results.length > 0
              ? results[0]?.address_components.filter((val) =>
                  val.types.includes("country")
                )
              : {};
          let cityObj =
            results.length > 0
              ? results[0]?.address_components.filter((val) =>
                  val.types.includes("locality")
                )
              : {};
          let stateObj =
            results.length > 0
              ? results[0]?.address_components.filter((val) =>
                  val.types.includes("administrative_area_level_1")
                )
              : {};
          let addressComponents = {
            country: countryObj[0]["long_name"],
            city: cityObj[0]["long_name"],
            state: stateObj[0]["long_name"],
          };
          setAddressComponents(addressComponents);
          setLatLng(latLng);
        });
      })

      .catch((error) => console.error("Error", error));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let formData = new FormData();
    let opening_time = utcOpeningTime;
    let closing_time = utcClosingTime;

    const categoriesValue = [];

    data.categories.map((data) => {
      categoriesValue.push(data.value);
    });

    formData.append("name", data.catName);
    console.log("addressSource", addressComponents);
    addressSource && formData.append("address", addressSource);
    addressComponents["city"] &&
      formData.append("city", addressComponents["city"]);
    addressComponents["country"] &&
      formData.append("country", addressComponents["country"]);
    addressComponents["state"] &&
      formData.append("state", addressComponents["state"]);
    opening_time && formData.append("opening_time", opening_time);
    closing_time && formData.append("closing_time", closing_time);
    latLng["lat"] && formData.append("latitude", latLng["lat"]);
    latLng["lng"] && formData.append("longitude", latLng["lng"]);
    data.email && formData.append("email", data.email);
    categoriesValue.length > 0 &&
      formData.append("categories", categoriesValue);

    if (data.media[0]) {
      formData.append("image", data.media[0]);
    }

    let id = props.match.params.id;
    if (id) {
      await UpdateRestaurant(id, formData)
        .then((data) => {
          if (data.success) {
            toast.success(data.message);
            setFileData("");
            history.push("/resturants");
            //   setModalShow(false);
            reset();
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    } else {
      await CreateRestaurant(formData)
        .then((data) => {
          if (data.success) {
            toast.success(data.message);
            setFileData("");
            history.push("/resturants");
            //   setModalShow(false);
            reset();
          } else {
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    }
  };

  let id = props.match.params.id;
  console.log(id);
  console.log(disableCateogies, "disable");
  return (
    <div className={"AddNewUser"}>
      <h3 className="page-heading">{id ? "Edit" : "Add New"} Business</h3>
      <hr />

      <Row  style={{paddingBottom: '200px'}}>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row
                  className={"mb-3 justify-content-center align-items-center"}
                 
                >
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Group
                      controlId="formFile"
                      onChange={(e) => handleChange(e)}
                      className="custom-file-upload mb-0"
                    >
                      <Form.Label className={"toggleFileUpload mb-0"}>
                        <UploadIcon />
                        Upload Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept=".jpg, .jpeg, .png, .jfif"
                        {...register(
                          "media",
                          id == undefined && {
                            required: {
                              value: "required",
                              message: "Profile image is required",
                            },
                          }
                        )}
                      />
                      {errors.media && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.media.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6} className={"mt-3"}>
                    <ul className={"list-inline list-variations mb-0"}>
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        {
                          <img
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={file ? file : defaultImage}
                            width="50%"
                            height="50%"
                          />
                        }
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-1" controlId="newCatName">
                  {/*En*/}
                  <Form.Label>
                    Name<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <Form.Control
                    placeholder="Name"
                    id={"formCategoryName"}
                    name="catName"
                    defaultValue={restaurant.name}
                    {...register("catName", {
                      onChange: (e) => {
                        setValue("catName", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "Name is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min Length is 3",
                      },
                      maxLength: {
                        value: 35,
                        message: "Max length is 35",
                      },
                    })}
                    type="text"
                  />
                  <div>
                    {errors.catName && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.catName.message}
                      </Form.Text>
                    )}
                  </div>

                  <>
                    <Form.Label>
                      Email<small style={{ color: "red" }}>*</small>
                    </Form.Label>
                    <Form.Control
                      disabled={url ? true : false}
                      defaultValue={restaurant.email}
                      placeholder="Enter email"
                      id={"formSignUpEmail"}
                      {...register("email", {
                        
                        required: {
                          
                          value:  url ? false : true,
                          message: "Email is required",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                        maxLength: {
                          value: 255,
                          message: "Max length is 255",
                        },
                      })}
                      type="email"
                    />
                    <div>
                      {errors.email && (
                        <span
                          className={"text-muted validationText hasError pr-5 "}
                          role="alert"
                        >
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </>

                  {/*Ar*/}
                  <Form.Label>
                    Address<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  {/* <Form.Control
                    placeholder="Address"
                    id={"formCategoryName"}
                    name="cataddress"
                    {...register("cataddress", {
                      onChange: (e) => {
                        setValue("cataddress", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "Address is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min Length is 3",
                      },
                      maxLength: {
                        value: 255,
                        message: "Max length is 255",
                      },
                    })}
                    type="text"
                  />
                  <div>
                    {errors.cataddress && (
                      <Form.Text className="text-muted validationText hasError ">
                        {errors.cataddress.message}
                      </Form.Text>
                    )}
                  </div> */}

                  {/*CN*/}
                  {/* <Form.Label>
                    city<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <Form.Control
                    placeholder="city"
                    id={"formCategoryName"}
                    name="catcity"
                    {...register("catcity", {
                      onChange: (e) => {
                        setValue("catcity", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "City is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min Length is 3",
                      },
                      maxLength: {
                        value: 35,
                        message: "Max length is 35",
                      },
                    })}
                    type="text"
                  />
                  <div>
                    {errors.catcity && (
                      <Form.Text className="text-muted validationText hasError ">
                        {errors.catcity.message}
                      </Form.Text>
                    )}
                  </div>

                  <Form.Label>
                    state<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <Form.Control
                    placeholder="state"
                    id={"formCategoryName"}
                    name="catstate"
                    {...register("catstate", {
                      onChange: (e) => {
                        setValue("catstate", e.target.value);
                      },
                      required: {
                        value: "required",
                        message: "state is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min Length is 3",
                      },
                      maxLength: {
                        value: 35,
                        message: "Max length is 35",
                      },
                    })}
                    type="text"
                  />
                  <div>
                    {errors.catstate && (
                      <Form.Text className="text-muted validationText hasError ">
                        {errors.catstate.message}
                      </Form.Text>
                    )}
                  </div> */}
                  {/* <Form.Label>
                    Country<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  {<Country />}
                  {errors.catcountry && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.catcountry.message}
                    </Form.Text>
                  )} */}

                  <PlacesAutocomplete
                    value={addressSource}
                    onChange={(address) => handleAddressChange(address)}
                    onSelect={(address) => handleAddressSelect(address)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Search Source",
                            className: "form-control location-search-input",
                          })}
                          onFocus={() => {
                            setDestinationFocus(true);
                          }}
                          onBlur={() => {
                            setDestinationFocus(false);
                          }}
                        />
                        <div
                          className={`autocomplete-dropdown-container ${
                            destinationFocus ? "d-block" : "d-none"
                          }`}
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid rgba(2,2,2,0.1)",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          {loading && (
                            <div className="suggestion-item">Loading...</div>
                          )}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  borderBottom: "1px solid rgba(2,2,2,0.1)",
                                  padding: "5px 0",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                <Form.Label>
                  Category<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Controller
                  control={control}
                  name="categories"
                  rules={{
                    required: {
                      value: true,
                      message: "Category is required",
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      isDisabled={disableCateogies}
                      {...field}
                      placeholder="Category"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isSearchable={true}
                      options={rowsCategories}
                      isMulti
                    />
                  )}
                />
                {/* <Select
              defaultValue={defaultRowsCategories}
              onChange={e => {
              }
              }
              className='react-select-container'
              classNamePrefix="react-select"
              options={rowsCategories}
              isMulti
              /> */}
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="openingtime">
                  <Form.Label>
                    Opening Time<small style={{ color: "red" }}>*</small>
                  </Form.Label>

                  <div>
                    <TimePicker
                      timeMode="12"
                      theme="classic"
                      onTimeChange={(options) => onOpeningTimeChange(options)}
                      className="w-100"
                      time={openingTime}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="closingtime">
                  <Form.Label>
                    CLosing Time<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div>
                    <TimePicker
                      timeMode="12"
                      theme="classic"
                      onTimeChange={(options) => onClosingTimeChange(options)}
                      className="w-100"
                      time={closingTime}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  value={"Submit"}
                  disabled={loading}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default AddNewResturant;
