import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import moment from "moment";
import {
  CreateUser,
  DeleteUser,
  GetHosts,
  UpdateUser,
} from "../../services/users.service";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage, GetAuthUser } from "../../services/auth/auth.service";

function ManageHost() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");

  useEffect(async () => {
    await getUsers();
  }, [keyword, page, perPage]);

  const getUsers = async () => {
    setLoading(true);
    await GetHosts(keyword, constants.ROLES.ROLE_RESTAURANT_STAFF, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data.data) {
            const rowData = [];
            setPage(result.data.meta.current_page);
            setTotalRows(result.data.meta.total);
            result.data.data.map((dt, index) => {
              rowData.push({
                id: dt.id,
                index: ++index,
                full_name: dt.profile.full_name || "-",
                // username: dt.username || "-",
                email: dt.email || "-",
                phone: dt.phone || "-",
                created_at: moment(dt.created_at).fromNow(),
              });
            });
            setRows(rowData);
            setLoading(false);
          }else {
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onUserDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        await DeleteUser(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getUsers();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const onEdit = (data) => {
    history.push("/manage-host/edit-user/" + data.id, {
      data: data,
    });
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={() => onEdit(data)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      id: "full_name",
      name: "Full Name",
      maxWidth: "160px",
      selector: (rowData) => rowData.full_name,
    },
    {
      id: "email",
      name: "Email",
      selector: (rowData) => rowData.email,
    },
    /*{
            id: 'created_at',
            name: 'Created At',
            selector: rowData => rowData.created_at,
        },*/
    {
      id: "action",
      name: "Action",
      cell: (rowData) => userActions(rowData),
    },
  ];

  return (
    <div className={"usersPage"}>
      <h3 className="page-heading">Manage Hosts</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 2
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Link to={"/manage-host/add-user"}>
                  <Button className={"btn btn-green-theme h40 w-100"}>
                    <AddIconOutline /> Add New
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#12726C"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />
      </div>
    </div>
  );
}

export default ManageHost;
