import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  InputGroup,
  FormControl,
  Form,
  DropdownButton,
  Dropdown,
  MenuItem,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import ProductImageIcon from "../../assets/images/product-image@2x.png";
import {
  GetOrdersById,
  UpdateOrderStatus,
} from "../../services/csa/orders.service";
import { toast } from "react-toastify";
import moment from "moment";
import { MoneyFormat } from "../../utils/helpers";
import { constants } from "../../utils/constants";
import ReadMore from "../../components/ReadMore";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from "react-router-dom";
import { GetUserRole } from "../../services/auth/auth.service";
import { useForm } from "react-hook-form";

const InitialOrderProducts = {
  id: "",
  name: "",
  image: "",
  price: "",
  tax_percentage: "",
  about: "",
  quantity: "",
};

const InitialData = {
  order_number: "",
  total_amount: "",
  description: "",
  order_products: [InitialOrderProducts],
  order_summary: {
    status: "",
    created_at: "",
    shipping_date: "",
    total_amount: "",
    total_shipping_fees: "",
  },
  shipping_address: {
    address_line_1: "",
    street: "",
    postal_code: "",
    city: "",
    country: "",
  },
  customer: {
    full_name: "",
    phone: "",
    email: "",
    visits: "",
  },
  restaurant: {
    name: "",
    address: "",
    timings: "",
  },
};

function OrderDetails(props) {
  const history = useHistory();
  const [data, setData] = useState(InitialData);

  useEffect(() => {
    let order_id = props.match.params.id;
    GetOrdersById(order_id)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            console.log("data: ", result.data);
            setData({
              id: result.data.id,
              order_number: result.data.order_no,
              total_amount: result.data.total,
              description: result.data.description || "",
              order_products: result.data.cart.flatMap((v) => {
                return {
                  id: v.id,
                  name: v.item.name,
                  image: v.item.image,
                  price: v.item.price,
                  tax_percentage: v.item.tax_percentage,
                  about: v.item.description,
                  quantity: v.quantity,
                };
              }),
              order_summary: {
                status: result.data.status,
                created_at:
                  moment
                    .utc(result?.data?.created_at)
                    .format("ddd, MMM DD, YYYY") || "",
                shipping_date:
                  moment
                    .utc(result?.data?.shipping_date)
                    .format("ddd, MMM DD, YYYY") || "",
                total_amount: `$${result?.data?.sub_total}` || "",
                total_shipping_fees:
                  MoneyFormat(result?.data?.total_shipping_fees) || "",
              },
              shipping_address: {
                address_line_1:
                  result?.data?.shipping_address?.address_line_1 || "",
                street: result?.data?.shipping_address?.street || "",
                postal_code: result?.data?.shipping_address?.zip || "",
                city: result?.data?.shipping_address?.city || "",
                country: result?.data?.shipping_address?.country || "",
              },
              customer: {
                full_name: result?.data?.customer?.profile?.full_name || "",
                phone: result?.data?.customer?.profile?.phone_number || "",
                email: result?.data?.customer?.email || "",
                visits: result?.data?.customer?.profile?.vists || "",
              },
              restaurant: {
                name: result?.data?.restaurant?.name || "",
                address: result?.data?.restaurant?.address || "",
              },
            });
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        console.log("kia idher aa rha hai?", error);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  }, []);

  const orderSummary = data.order_products.flatMap((product, indexProduct) => {
    console.log("image: ", product);

    let total_price = parseInt(product.price) * parseInt(product.quantity);
    return (
      <tbody key={indexProduct}>
        <tr>
          <td colSpan={5}>
            <div className={"ItemDescription"}>
              <Row className={"g-0"}>
                <Col xs={2}>
                  <div className={"img-bg"}>
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={product.image || ProductImageIcon}
                      alt={"Product Image"}
                      title={"Product Image"}
                      className={"img-fluid"}
                      width={"36px"}
                      //  width={"49px"}
                    />
                  </div>
                </Col>
                <Col xs={10} className="ps-1">
                  <h4>{product.name}</h4>
                  <p>{product.about}</p>

                  {/*<ReadMore textLength={"40"}>*/}
                  {/*    {product.about}*/}
                  {/*</ReadMore>*/}
                </Col>
              </Row>
            </div>
          </td>
          <td>x {product.quantity}</td>
          <td>{`$${product.price}`}</td>
          <td>{`$${total_price}`}</td>
        </tr>
      </tbody>
    );
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [orderId, setOrderId] = useState(false);
  let role = GetUserRole();

  const updateOrderStatus = async (id, statusId, oldStatus) => {
    let formData = new FormData();
    formData.append("status", statusId);

    await UpdateOrderStatus(id, formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          reset();
        } else {
          toast.error(data.message);
          reset();
          setData((current) => {
            return {
              ...current,
              order_summary: {
                ...current.order_summary,
                status: oldStatus,
              },
            };
          });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  return (
    /*add page name as class to each parent div of the page like here*/
    <div className={"orderPage OrderDetails"}>
      {/*<BreadcrumbsComponent/>*/}
      <h3 className="page-heading">
        Order Number <span>#{data.order_number}</span>
      </h3>
      <hr />

      <Row>
        <Col xs={12} sm={12} md={7} lg={7} xl={7}>
          <div className={"BoxCustom ItemSummary mb-4"}>
            <Table>
              <thead>
                <tr>
                  <th colSpan={5}>Items Summary</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Total Price</th>
                </tr>
              </thead>

              {orderSummary}
            </Table>
          </div>
          <div className={"BoxCustom TotalRow mb-4"}>
            <div className={"box-header mb-0"}>
              <Row className={"g-0 align-items-center"}>
                <Col xs={8}>
                  <h4>Total</h4>
                </Col>
                <Col xs={4}>
                  <h4 className={"text-end"}>
                    {`$${Number(data.total_amount).toFixed(2)}`}
                  </h4>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
          {/* <div className="productDetails mb-4">
                        <h4 className={"mb-3"}>Product Description</h4>
                        <p>{data.description}</p>
                    </div> */}
          <div className={"BoxCustom OrderSummary mb-4"}>
            <div className={"box-header"}>
              <Row className={"g-0 align-items-center"}>
                <Col xs={8}>
                  <h4>Order Summary</h4>
                </Col>
                <Col xs={4}>
                  {role == constants.ROLES.ROLE_ADMIN ? (
                    <div
                      className={
                        "status-badge status-" +
                        data.order_summary.status.toLowerCase()
                      }
                    >
                      <span>{data.order_summary.status}</span>
                    </div>
                  ) : (
                    <div>
                      <Form.Group className="mb-3" controlId="formStatus">
                        <Form.Label>Order Status</Form.Label>
                        <Form.Select
                          aria-label="Order Status"
                          {...register("orderStatus", {
                            onChange: (e) => {
                              var oldStatus = Object.entries(
                                constants.ORDERSTATUS
                              ).filter((item) => {
                                return (
                                  item[0] ===
                                    data.order_summary.status.toUpperCase() &&
                                  item[1]
                                );
                              });
                              e.target.value != '' &&
                              updateOrderStatus(
                                data.id,
                                e.target.value,
                                oldStatus
                              );
                            },
                          })}
                        >
                          <option value="">Select Order Status</option>
                          {/* {Object.entries(constants.ORDERSTATUS).map((item) => {
                            if (
                              item[1] == constants.ORDERSTATUS.PREPARING ||
                              item[1] == constants.ORDERSTATUS.READY_TO_PICK ||
                              item[1] == constants.ORDERSTATUS.SERVED
                            ) {
                              return (
                                <>
                                  <option
                                    key={item[1]}
                                    selected={item[0] === data.order_summary.status.toUpperCase() && true}
                                    value={item[1]}
                                  >
                                    {item[0]}
                                  </option>
                                </>
                              );
                            }
                          })} */}
                          {Object.entries(constants.ORDERSTATUSVALUES).map(
                            (item, index) => {
                              return (
                                <option
                                  selected={item[1] === data.order_summary.status.toUpperCase() && true}
                                  key={item[1]}
                                  value={index + 1}
                                >
                                  {item[1]}
                                </option>
                              );
                            }
                          )}
                        </Form.Select>
                        {errors.orderStatus && (
                          <Form.Text className="text-muted validationText hasError">
                            {errors.orderStatus.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className={"box-body"}>
              <ul className={"list-unstyled"}>
                <li>
                  <span>Order Created</span>
                  <span>{data.order_summary.created_at}</span>
                </li>
                {/*<li>
                                    <span>Shipping </span>
                                    <span>{data.order_summary.shipping_date}</span>
                                </li>*/}
                <li>
                  <span>Sub Total</span>
                  <span>{data.order_summary.total_amount}</span>
                </li>
                {/*<li>
                                    <span>Shipping Amount</span>
                                    <span>{data.order_summary.total_shipping_fees}</span>
                                </li>*/}
              </ul>
            </div>
          </div>
          <div className={"BoxCustom Details mb-4"}>
            <div className={"box-header mb-0"}>
              <Row className={"g-0 align-items-center"}>
                <Col xs={12}>
                  <h4>Customer Details</h4>
                </Col>
              </Row>
            </div>
            <hr className={"mb-3"} />
            <div className={"box-body"}>
              <ul className={"list-unstyled"}>
                <li>
                  <span>Customer Name</span>
                  <span>{data.customer.full_name}</span>
                </li>
                <li>
                  <span>Phone Number</span>
                  <span>{data.customer.phone}</span>
                </li>
                <li>
                  <span>Email</span>
                  <span>{data.customer.email}</span>
                </li>
                <li>
                  <span>Visits</span>
                  <span>{data.customer.visits}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={"BoxCustom ShippingAddress mb-4"}>
            <div className={"box-header"}>
              <Row className={"g-0 align-items-center"}>
                <Col xs={8}>
                  <h4>Business Details</h4>
                </Col>
              </Row>
            </div>
            <div className={"box-body"}>
              <ul className={"list-unstyled"}>
                <li>
                  <span>Name</span>
                  <span>{data.restaurant.name}</span>
                </li>
                <li>
                  <span>address</span>
                  <span className="address-details">
                    {data.restaurant.address}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderDetails;
