import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  PagesApi,
  SinglePageApi,
  SingleFaqApi,
} from "../../services/auth/pages.service";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useHistory } from "react-router";

function FaqsView(props) {
  const history = useHistory();
  const [answer, setAnswer] = useState();
  const [id, setId] = useState();
  const [question, setQuestion] = useState();
  useEffect(async () => {
    let param = props.match.params.id;
    await SingleFaqApi(param)
      .then((data) => {
        if (data.success) {
          setQuestion(data.data.question);
          setAnswer(parse(data.data.answer));
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          console.log(error);
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  return (
    <div className={"policyPage PrivacyPolicy"}>
      <h3 className="page-heading">{question}</h3>
      <hr />
      <div>{answer || "Loading..."}</div>
    </div>
  );
}

export default FaqsView;
