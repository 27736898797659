import React, {useEffect, useState} from 'react'
import {AddIcon, AddIconOutline, MenuDots, TrashIcon, UploadIcon, SearchIcon} from "../../components/SvgIcons";
import {Col, Row, Form, Button, Dropdown, DropdownButton, InputGroup, FormControl} from "react-bootstrap"
import MaterialTable from "material-table";
import swal from "sweetalert";
import {useForm} from "react-hook-form";
import ThemeModal from "../../components/ThemeModal";
import {
    CreateColor,
    DeleteColor,
    GetColors,
    UpdateColor
} from "../../services/colors.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

function ColorList(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [color, setColor] = useState(false);
    const [editModalData, setEditModalData] = useState(false);
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getColors();
    }, [page, perPage, keyword])

    useEffect(() => {
        if (!modalShow && !editModalShow) {
            setEditModalData(false)
            reset();
        }
    }, [modalShow, editModalShow])

    const getColors = async () => {
        setLoading(true)
        await GetColors(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result.data) {
                    console.log(result.meta)
                    const rowData = [];
                    //setPage(result.meta.current_page)
                    //setTotalRows(result.meta.total);
                    result.data.map((dt, index) => {
                        rowData.push({
                            Id: dt.id,
                            Index: ++index,
                            Name: dt.name,
                            ColorCode: dt.color_code,
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onColorDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected Color?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteColor(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getColors(color.id)
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const colorActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => {
                    setEditModalShow(true)
                    setValue('Id', data.Id);
                    setValue('Name', data.Name);
                    setValue('ColorCode', data.ColorCode);
                }}>
                    Edit
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onColorDelete(data.Id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const statusCheck = (status) => {
        if (status === 10) {
            return <div className={"status-badge status-completed"}>Active</div>
        }
        if (status === 20) {
            return <div className={"status-badge status-cancelled"}>In Active</div>
        }
        return <div className={"status-badge status-undefined"}>Undefined</div>
    }

    const columns = [
        {
            id: 'Name',
            name: 'Name',
            selector: rowData => rowData.Name,
        },
        {
            id: 'ColorCode',
            name: 'Color Code',
            selector: rowData => rowData.ColorCode,
        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => colorActions(rowData),
        },
    ];

    const onModalSubmit = async data => {
        await CreateColor({
            name: data.Name,
            color_code: data.ColorCode,
        }).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getColors(color.id);
                setModalShow(false)
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const onEditModalSubmit = async data => {
        await UpdateColor(data.Id, {
            name: data.Name,
            color_code: data.ColorCode,
        }).then((data) => {
            if (data.status) {
                toast.success(data.message);
                getColors(color.id);
                setEditModalShow(false)
                reset();
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    /*
   *
   *  form elements inside popup
   *
   * */

    const ModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form onSubmit={handleSubmit(onModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="ColorName">
                                <Form.Label>Color Name<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Color Name"
                                    {...register('Name', {
                                        onChange: (e) => {
                                            setValue('Name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.Name && <Form.Text
                                    className="text-muted validationText hasError">{errors.Name.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="ColorCode">
                                <Form.Label>Color Code<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Control
                                    placeholder="Color Code"
                                    defaultValue={"#12726C"}
                                    {...register('ColorCode', {
                                        onChange: (e) => {
                                            setValue('ColorCode', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "ColorCode is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="color"
                                />
                                {errors.ColorCode && <Form.Text
                                    className="text-muted validationText hasError">{errors.ColorCode.message}</Form.Text>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    const EditModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form onSubmit={handleSubmit(onEditModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="ColorName">
                                <Form.Label>Color Name</Form.Label>
                                <Form.Control
                                    placeholder="Name"
                                    {...register('Name', {
                                        onChange: (e) => {
                                            setValue('Name', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Name is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="text"
                                />
                                {errors.ColorCode && <Form.Text
                                    classColorCode="text-muted validationText hasError">{errors.ColorCode.message}</Form.Text>}
                            </Form.Group>
                            <Form.Group classColorCode="mb-3" controlId="ColorCode">
                                <Form.Label>Color ColorCode</Form.Label>
                                <Form.Control
                                    placeholder="ColorCode"

                                    {...register('ColorCode', {
                                        onChange: (e) => {
                                            setValue('ColorCode', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Color code is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max Length is 255"
                                        }
                                    })} type="color"
                                />
                                {errors.ColorCode && <Form.Text
                                    className="text-muted validationText hasError">{errors.ColorCode.message}</Form.Text>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"} value={"Submit"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    return (
        <div className={"manageColors"}>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h3 className="page-heading">Manage Colors</h3>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h3 className="page-heading text-themegreen text-end">{color.name}</h3>
                </Col>
            </Row>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Button className={"btn btn-green-theme h40 w-100"} onClick={() => setModalShow(true)}>
                                    <AddIconOutline/> Add New
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className={"mt-3"}>
                <div className="theme-dataGrid products-dataGrid">
                    <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#12726C'} loading={loading} css={'marginTop: 10px'}
                                                      height={'4'} width={'100%'}/>}
                    />
                </div>
            </div>
            {/* Modal Form to add new product*/}
            <ThemeModal title={"Add New Color"} size={"md"} content={<ModalForm/>} show={modalShow}
                        onHide={() => setModalShow(false)}/>
            <ThemeModal title={"Edit Color"} size={"md"} content={<EditModalForm/>} show={editModalShow}
                        onHide={() => setEditModalShow(false)}/>
        </div>
    )
}

export default ColorList
