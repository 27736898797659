import React, {useRef, useState, useEffect} from 'react'
import {Form, Row, Col, InputGroup, FormControl, Button} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import {useForm} from "react-hook-form";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import Calendar from "react-multi-date-picker";
import moment from "moment"
import {updateUser} from "../../services/firebase.service";
import {EmptyLocalStorage, GetUserRole} from "../../services/auth/auth.service";
import {constants} from "../../utils/constants";
import {format} from "date-fns";
import {FaEye, FaEyeSlash} from "react-icons/fa";

function EditUser(props) {
    let history = useHistory();
    const [disableButton, setDisableButton] = useState(false);
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);

    const {register, watch, reset, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    const password = useRef({});
    password.current = watch("password", "");

    useEffect(async () => {
        let id = props.match.params.id;
        await getUserByid(id);
        if (data) {
            reset({
                'full_name': data.full_name,
                // 'username': data.username,
                'email': data.email,
                'phone': data.phone,
                'dob': data.dob,
                //'company_name': data.company_name,
                //'business_type': data.business_type,
            });
        }
    }, []);

    const getUserByid = async (id) => {
        await GetUserById(id).then((result) => {
            if (result.success) {
                if (result.data) {
                    setData({
                        full_name: result.data.profile.full_name,
                        // username: result.data.username,
                        email: result.data.email,
                        phone: result.data.profile.phone_number,
                        dob: moment(result.data.profile.dob).format('yyyy-MM-DD'),
                        //company_name: result.data.company_name,
                        //business_type: result.data.business_type,
                    });
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async data => {
        let id = props.match.params.id;
        let dt = {
            full_name: data.full_name,
            // username: data.username,
            email: data.email,
            phone_number: data.phone,
            dob: data.dob,
            // company_name: ' ',
            // business_type: ' ',
            // role_id: constants.ROLES.ROLE_USER,
        }
        
        setDisableButton(true)
        await UpdateUser(id, dt).then(async (data) => {
            if (data.success) {
                // await updateUser(data.data).then(() => {
                toast.success(data.message);
                history.push('/manage-host');
                // })
            } else {
                setDisableButton(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit User - {data.full_name}</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        defaultValue={data.full_name}
                                        {...register('full_name', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.full_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.full_name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        disabled={true}
                                        defaultValue={data.email}
                                        {...register('email', {
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="email"/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Phone Number"
                                        defaultValue={data.phone}
                                        {...register("phone", {
                                                required: {
                                                    value: "required",
                                                    message: "Phone is required"
                                                },
                                                pattern: {
                                                    value: /^\+[0-9]*$/,
                                                    message: "incorrect pattern +xxxxxxxxxxxx"
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: "max length is 16"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.phone && <Form.Text
                                        className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date Of Birth</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Date Of Birth"
                                        defaultValue={data.dob}
                                        max={format(new Date(), 'yyyy-MM-dd')}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "dob is required"
                                            },
                                        })} type="date"/>
                                    {errors.dob && <Form.Text
                                        className="text-muted validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        defaultValue={data.company_name}
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "company name is required"
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "min length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        defaultValue={data.business_type}
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "business type is required"
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "min length is 6"
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: "max length is 50"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                       disabled={disableButton}
                                       value={"Edit User"}/>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditUser
