import React, {useEffect, useState} from 'react'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent"
import {Col, Row, Form, Button} from "react-bootstrap"
import {UploadIcon, AddIcon, DeleteRed, LinkIcon} from '../../components/SvgIcons'
import ThemeModal from "../../components/ThemeModal";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useDropzone} from 'react-dropzone';
import {useForm} from "react-hook-form";
import swal from "sweetalert";
import {GetCategories} from "../../services/categories.service";
import {GetSubCategories} from "../../services/sub_categories.service";
import {toast} from "react-toastify";
import {CreateProduct} from "../../services/products.service";
import {useHistory, Link} from 'react-router-dom';
import {constants} from "../../utils/constants";
import {GetUsers} from "../../services/users.service";
import {EmptyLocalStorage, GetAuthUser} from "../../services/auth/auth.service";
import {GetAllColors, GetColors} from "../../services/colors.service";

function AddProduct() {
    let history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [colorModalShow, setColorModalShow] = useState(false);
    const [bulkPriceModalShow, setBulkPriceModalShow] = useState(false);
    const [editorState, onEditorStateChange] = useState(false);

    const [disableButton, setDisableButton] = useState(false);
    //media files
    const [files, setFiles] = useState([]);

    //Pricing Match States
    const [siteName, setSiteName] = useState("");
    const [matchingPrice, setMatchingPrice] = useState("");
    const [siteURL, setSiteURL] = useState("");

    //Pricing Match Validation States
    const [siteNameRequired, setSiteNameRequired] = useState(false);
    const [matchingPriceRequired, setMatchingPriceRequired] = useState(false);
    const [siteURLRequired, setSiteURLRequired] = useState(false);

    //Pricing Array State
    const [matchData, setMatchData] = useState([]);

    const [filterStore, setFilterStore] = useState('');
    const [filterColor, setFilterColor] = useState('');
    //Category and Sub Category States
    const [filterCategory, setFilterCategory] = useState('');
    const [filterSubCategory, setFilterSubCategory] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');

    //Variation Modal State
    const [showVariation, setShowVariation] = useState(true);
    const [variationData, setVariationData] = useState([]);

    //Bulk Modal State
    const [showBulkPrice, setShowBulkPrice] = useState(true);
    const [bulkPriceData, setBulkPriceData] = useState([]);

    //Color Modal State
    const [showColor, setShowColor] = useState(true);
    const [colorData, setColorData] = useState([]);

    //Product Variant Checkbox
    const [variantCheck, setVariantCheck] = useState(true);

    //Custom Errors
    const [variantRequiredError, setVariantRequiredError] = useState(false);
    const [mediaRequiredError, setMediaRequiredError] = useState(false);
    const [documentRequiredError, setDocumentRequiredError] = useState(false);

    //Product Type
    const [productType, setProductType] = useState([]);
    const [productTypeValue, setProductTypeValue] = useState('');
    const [csaRequiredValue, setCsaRequiredValue] = useState('');

    //Form Hook
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const {register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2}, reset: reset2, setValue: setValue2,} = useForm({
        mode: "onChange",
    });

    let [role, setRole] = useState();

    /*
*
*  DropZone settings and temporary file name mapping
*
* */
    function CustomDropZone(props) {
        const {
            acceptedFiles,
            fileRejections,
            getRootProps,
            getInputProps,
            onChange,
        } = useDropzone({
            accept: 'image/jpeg, image/jpg, image/png',
            maxFiles: 10,
            onDrop: files => {
                setValue("media", files);

                setMediaRequiredError(false)
            },
        });

        const acceptedFileItems = acceptedFiles.map((file, index) => (
            <li key={file.path} style={{listStyle: 'none', fontSize: '10px'}}>
                {index > 1 ? index + 'Files Selected' : index + 'File Selected'}
                {/*{file.path} - {file.size} bytes*/}
            </li>
        ));

        const thumbs = files.map(file => (
            <div className={"thumb"} key={file.name}>
                <div className={"thumbInner"}>
                    <img
                        src={file.preview}
                        className={"img"}
                    />
                </div>
            </div>
        ));

        const fileRejectionItems = fileRejections.map(({file, errors}) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => (
                        <li key={e.code}>{e.message}</li>
                    ))}
                </ul>
            </li>
        ));

        return (
            <div className="dropZoneContainer">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input type="file" name="media" {...getInputProps()}/>
                    <UploadIcon/>
                    <p>Drag images or click to upload</p>
                </div>
                <aside className={"thumbsContainer"}>
                    {thumbs}
                </aside>
                {/*{
                    acceptedFileItems.length > 0 ?
                        <div style={{marginLeft: '10px'}}>
                            <span style={{fontSize: '10px'}}>Selected files</span>
                            <p>{acceptedFileItems.length}</p>
                        </div> : ''
                }*/}

                {/*<aside>
                <h4>Accepted files</h4>
                <ul>{acceptedFileItems}</ul>
                <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul>
            </aside>*/}
            </div>
        );
    }

    useEffect(() => {
        getCategories();
        getVendors();
        getColors();
        setRole(GetAuthUser()?.roles[0]?.id)
    }, [])


    //Match Data Result Div
    const MatchDataDiv = ({index, dt}) => {
        return (
            <li className={"list-inline-item d-flex justify-content-between"}>
                <span><span onClick={() => onPriceMatchDelete(index)}><DeleteRed/></span> {dt.siteName}</span>
                <span>{dt.matchingPrice}</span>
                <span>{dt.siteURL}</span>
            </li>
        )
    }

    const getVendors = async () => {
        const stores = [];
        await GetUsers('', constants.ROLES.ROLE_VENDOR).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    result.data.data.map((dt) => {
                        stores.push({
                            id: dt.store?.id,
                            name: dt.store?.name,
                        });
                    })
                    return setFilterStore(stores);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getCategories = async () => {
        const categories = [];
        await GetCategories().then((result) => {
            if (result.success) {
                if (result.data.data) {
                    result.data.data.map((dt) => {
                        categories.push({
                            id: dt.id,
                            name: dt.name,
                            type: dt.type
                        });
                    })
                    return setFilterCategory(categories);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const getSubCategories = async (category_id) => {
        const sub_category = [];
        if (!category_id) {
            setFilterSubCategory("")
            return setSubCategory("")
        }
        await GetSubCategories(category_id).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    result.data.data.map((dt) => {
                        sub_category.push({
                            id: dt.id,
                            name: dt.name
                        });
                    })
                    return setFilterSubCategory(sub_category);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getColors = async () => {
        const colors = [];
        await GetColors().then((result) => {
            if (result.status) {
                if (result.data) {
                    result.data.map((dt) => {
                        colors.push({
                            id: dt?.name,
                            name: dt?.name
                        });
                    })
                    return setFilterColor(colors);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async (data) => {
        if (data.media == undefined) {
            return setMediaRequiredError(true);
        }
        setMediaRequiredError(false);

        if (!data.product_description_document[0]) {
            return setDocumentRequiredError(true);
        }
        setDocumentRequiredError(false);

        if (variationData.length == 0) {
            return setVariantRequiredError(true);
        }
        setVariantRequiredError(false);

        if (colorData.length == 0) {
            return setVariantRequiredError(true);
        }
        setVariantRequiredError(false);


        //Main Form Submit
        setDisableButton(true);
        let formData = new FormData();

        if (variantCheck) {
            colorData.flatMap((v, index) => {
                formData.append('colors[]', v.productColor);
            })

            variationData.flatMap((v, index) => {
                formData.append('sizes[' + index + '][size]', v.productValue);
                formData.append('sizes[' + index + '][price]', v.productPrice);
                formData.append('sizes[' + index + '][weight]', v.productWeight);
            })

            bulkPriceData.flatMap((v, index) => {
                formData.append('product_bulk_prices[' + index + '][discount]', v.bulkDiscount);
                formData.append('product_bulk_prices[' + index + '][min]', v.minPrice);
                formData.append('product_bulk_prices[' + index + '][max]', v.maxPrice);
            })
        }

        if (matchData) {
            matchData.flatMap((v, index) => {
                formData.append('price_matching[' + index + '][platform]', v.siteName);
                formData.append('price_matching[' + index + '][price]', v.matchingPrice);
                formData.append('price_matching[' + index + '][external_url]', v.siteURL);
            })
        }

        formData.append('name', data.name);
        formData.append('name_ar', data?.name_ar);
        formData.append('name_cn', data?.name_cn);
        formData.append('about_appearance', data.product_description);
        formData.append('about_appearance_ar', data?.product_description_ar);
        formData.append('about_appearance_cn', data?.product_description_cn);
        formData.append('delivery_and_return', data.delivery_and_return);
        formData.append('delivery_and_return_ar', data?.delivery_and_return_ar);
        formData.append('delivery_and_return_cn', data?.delivery_and_return_cn);
        formData.append('min_order', data.min_order);

        if (role && role == constants.ROLES.ROLE_VENDOR) {
            formData.append('store_id', parseInt(GetAuthUser()?.store?.id));
        } else {
            formData.append('store_id', data.store_id);
        }
        formData.append('category_id', data.category_id);
        formData.append('sub_category_id', data.sub_category_id);

        productType && productType == constants.CATEGORYSTATUS.CUSTOMIZE ?
            formData.append('csa_required', '1') :
            formData.append('csa_required', '0')

        if (data.media) {
            data.media.flatMap((v, index) => {
                formData.append('media[]', v);
            })
        }

        if (data.product_description_document[0]) {
            formData.append('product_description_document', data.product_description_document[0]);
        }

        //Submit Product Form
        await CreateProduct(formData).then((data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/products');
                reset();
            } else {
                setDisableButton(false);
                toast.error(data.message);
            }
        }).catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    //Variation Modal Submit
    const onModalSubmit = async (data) => {
        setVariationData(setVariationData => [...setVariationData, {
            productValue: data.productVariantValue,
            productPrice: data.productVariantPrice,
            productWeight: data.productVariantWeight,
        }]);
        reset2()
    };

    //Bulk Price Modal Submit
    const onBulkPriceModalSubmit = async (data) => {
        setBulkPriceData(setBulkPriceData => [...setBulkPriceData, {
            bulkDiscount: data.bulkDiscountValue,
            minPrice: data.bulkMinQunatityValue,
            maxPrice: data.bulkMaxQuantityValue,
        }]);
        reset2()
    };

    //Colors Modal Submit
    const onColorModalSubmit = async data => {
        setColorData(setColorData => [...setColorData, {
            productColor: data.productColorValue,
        }]);
        reset2()
    };

    /*
    *
    *  Variant Modal
    *
    * */

    const ModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form onSubmit={handleSubmit2(onModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="productVariantValue">
                                <Form.Label>Product Size Value</Form.Label>
                                <Form.Control
                                    placeholder="Enter Product Size Value"
                                    {...register2('productVariantValue', {
                                        required: {
                                            value: "required",
                                            message: "Product Size is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                />
                                {errors2.productVariantValue && <Form.Text
                                    className="text-muted validationText hasError">{errors2.productVariantValue.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="productVariantPrice">
                                <Form.Label>Product Size Price</Form.Label>
                                <Form.Control
                                    placeholder="Enter Product Price"
                                    {...register2('productVariantPrice', {
                                        required: {
                                            value: "required",
                                            message: "Product Variant Price is required"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Max length is 11"
                                        },
                                    })} type="number"
                                />
                                {errors2.productVariantPrice && <Form.Text
                                    className="text-muted validationText hasError">{errors2.productVariantPrice.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="productVariantWeight">
                                <Form.Label>Product Weight (KG)</Form.Label>
                                <Form.Control
                                    placeholder="Enter Product Weight"
                                    {...register2('productVariantWeight', {
                                        required: {
                                            value: "required",
                                            message: "Product Variant Weight is required"
                                        },
                                    })} type="text"
                                />
                                {errors2.productVariantWeight && <Form.Text
                                    className="text-muted validationText hasError">{errors2.productVariantWeight.message}</Form.Text>}
                            </Form.Group>

                            <input type={"submit"} className={"btn btn-green-theme py-2 px-12 mt-3"}
                                   value={"Add Variant"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    /*
    *
    *  Bulk Price Modal
    *
    * */

    const BulkPriceModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form onSubmit={handleSubmit2(onBulkPriceModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="bulkDiscountValue">
                                <Form.Label>Bulk Discount %</Form.Label>
                                <Form.Control
                                    placeholder="Enter Discount"
                                    {...register2('bulkDiscountValue', {
                                        required: {
                                            value: "required",
                                            message: "Discount value is required"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                />
                                {errors2.bulkDiscountValue && <Form.Text
                                    className="text-muted validationText hasError">{errors2.bulkDiscountValue.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="bulkMinQunatityValue">
                                <Form.Label>Min Quantity</Form.Label>
                                <Form.Control
                                    placeholder="Enter Min Quantity"
                                    {...register2('bulkMinQunatityValue', {
                                        required: {
                                            value: "required",
                                            message: "Discount Min Quantity is required"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Max length is 11"
                                        },
                                    })} type="number"
                                />
                                {errors2.bulkMinQunatityValue && <Form.Text
                                    className="text-muted validationText hasError">{errors2.bulkMinQunatityValue.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="bulkMaxQuantityValue">
                                <Form.Label>Max Quantity</Form.Label>
                                <Form.Control
                                    placeholder="Enter Max Quantity"
                                    {...register2('bulkMaxQuantityValue', {
                                        required: {
                                            value: "required",
                                            message: "Discount Max Quantity is required"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Max length is 11"
                                        },
                                    })} type="number"
                                />
                                {errors2.bulkMaxQuantityValue && <Form.Text
                                    className="text-muted validationText hasError">{errors2.bulkMaxQuantityValue.message}</Form.Text>}
                            </Form.Group>

                            <input type={"submit"} className={"btn btn-green-theme py-2 px-12 mt-3"}
                                   value={"Add Bulk Discount"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    /*
    *
    *  Color Modal
    *
    * */

    const ColorModalForm = () => {
        return (
            <div className={"modalBodyContent"}>
                <Form onSubmit={handleSubmit2(onColorModalSubmit)}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="productColorValue">
                                {/*<Form.Label>Product Color</Form.Label>*/}
                                <Select
                                    label={'Product Color'}
                                    options={filterColor}
                                    {...register2("productColorValue", {
                                            required: {
                                                value: "required",
                                                message: "Product Color is required"
                                            },
                                        },
                                    )} />
                                {/*<Form.Control
                                    placeholder="Enter Product Color"
                                    {...register2('productColorValue', {
                                        required: {
                                            value: "required",
                                            message: "Product Color is required"
                                        },
                                        maxLength: {
                                            value: 60,
                                            message: "Max length is 60"
                                        },
                                    })} type="text"
                                />*/}
                                {errors2.productColorValue && <Form.Text
                                    className="text-muted validationText hasError">{errors2.productColorValue.message}</Form.Text>}
                            </Form.Group>

                            <input type={"submit"} className={"btn btn-green-theme py-2 px-5 mt-3"}
                                   value={"Add Color"}/>
                        </Col>
                        {/*<Col xs={12} sm={12} md={12} lg={{span: 4, offset: 2}} xl={{span: 4, offset: 2}}>
                            <Form.Label className={"form-label"}>Product Image</Form.Label>
                            <CustomDropZone/>
                        </Col>*/}
                    </Row>
                </Form>
            </div>
        )
    }

    /*
    *
    *  Variation Delete
    *
    * */
    const onVariationDelete = (index) => {
        variationData.splice(index, 1)
        setVariationData(variationData.filter(item => item.index !== index))
    }

    /*
    *
    *  Bulk Price Delete
    *
    * */
    const onBulkPriceDelete = (index) => {
        bulkPriceData.splice(index, 1)
        setBulkPriceData(bulkPriceData.filter(item => item.index !== index))
    }

    /*
    *
    *  Variation Delete
    *
    * */
    const onColorDelete = (index) => {
        colorData.splice(index, 1);
        setColorData(colorData.filter(item => item.index !== index))
    }

    /*
    *
    *  Price Match Delete
    *
    * */
    const onPriceMatchDelete = (index) => {
        matchData.splice(index, 1)
        setMatchData(matchData.filter(item => item.index !== index))
    }

    /*
    *
    *   Add Match Click
    *
    * */
    const addMatchClick = () => {
        if (siteName && matchingPrice && siteURL) {
            setMatchData(matchData => [...matchData, {
                siteName: siteName,
                matchingPrice: matchingPrice,
                siteURL: siteURL,
            }]);

            setSiteName("");
            setMatchingPrice("");
            setSiteURL("");
        } else {
            if (!siteName) {
                setSiteNameRequired(true);
            }
            if (!matchingPrice) {
                setMatchingPriceRequired(true);
            }
            if (!siteURL) {
                setSiteURLRequired(true);
            }

            setSiteName(siteName);
            setMatchingPrice(matchingPrice);
            setSiteURL(siteURL);
        }


    }

    // you can use React.forwardRef to pass the ref too
    const Select = React.forwardRef(({options, onChange, name, label}, ref) => (
        <>
            <Form.Select name={name} ref={ref} onChange={onChange}>
                <option value="">{label}</option>
                {
                    options ?
                        options.map((d) => {
                            return (<option value={d.id}>{d.name}</option>);
                        }) : ''
                }
            </Form.Select>
        </>
    ));

    const onChangeCategory = (e) => {
        setCategory(e.target.value);
        getSubCategories(e.target.value);
        filterCategory.filter((category) => {
            if (e.target.value == category.id) {
                setProductType(category.type);
            }
        })
    }

    const handleCheckClick = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            setShowVariation(true);
            setShowColor(true);
            setVariantCheck(true);
        } else {
            setShowVariation(false);
            setShowColor(false);
            setVariantCheck(false);
        }
    }

    const VariationDiv = () => {
        return (
            <>
                <Button variant={"link"} className={"btn-custom-add"} type={"button"} size={"sm"}
                        onClick={() => setModalShow(true)}>
                    <AddIcon/> Add Variations
                </Button>

                <ul className={"list-inline list-variations mt-3"}>
                    {
                        variationData ?
                            variationData.map((dt, index) => {
                                return (
                                    <li className={"list-inline-item d-flex justify-content-between"}>
                                        <span onClick={() => onVariationDelete(index)}><DeleteRed/></span>
                                        <span style={{width: '100%'}}>Size: {dt.productValue}</span>
                                        <span style={{width: '100%'}}>Price: {dt.productPrice}</span>
                                        <span style={{width: '100%'}}>Weight: {dt.productWeight}</span>
                                    </li>
                                )
                            }) : ''
                    }
                </ul>
            </>
        );
    }

    const BulkPriceDiv = () => {
        return (
            <>
                <Button variant={"link"} className={"btn-custom-add"} type={"button"} size={"sm"}
                        onClick={() => setBulkPriceModalShow(true)}>
                    <AddIcon/> Add Bulk Price
                </Button>

                <ul className={"list-inline list-variations mt-3"}>
                    {
                        bulkPriceData ?
                            bulkPriceData.map((dt, index) => {
                                return (
                                    <li className={"list-inline-item d-flex justify-content-between"}>
                                        <span onClick={() => onBulkPriceDelete(index)}><DeleteRed/></span>
                                        <span style={{width: '100%'}}>Discount: {dt.bulkDiscount}</span>
                                        <span style={{width: '100%'}}>Min: {dt.minPrice}</span>
                                        <span style={{width: '100%'}}>Max: {dt.maxPrice}</span>
                                    </li>
                                )
                            }) : ''
                    }
                </ul>
            </>
        );
    }

    const ColorDiv = () => {
        return (
            <>
                <Button variant={"link"} className={"btn-custom-add"} type={"button"} size={"sm"}
                        onClick={() => setColorModalShow(true)}>
                    <AddIcon/> Add Colors
                </Button>

                <ul className={"list-inline list-variations mt-3"}>
                    {
                        colorData ?
                            colorData.map((dt, index) => {
                                return (
                                    <li className={"list-inline-item d-flex justify-content-between"}>
                                        <span onClick={() => onColorDelete(index)}><DeleteRed/></span>
                                        <span style={{width: '100%'}}>Color: {dt.productColor}</span>
                                    </li>
                                )
                            }) : ''
                    }
                </ul>
            </>
        );
    }

    return (
        /*add page name as class to each parent div of the page like here*/
        <div className={"productsPage addNewProduct"}>
            {/*<BreadcrumbsComponent/>*/}
            <h3 className="page-heading">Add New Product</h3>
            <hr/>
            <Form className={"FormAddProduct"} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Product Title - EN<small style={{color: "red"}}>*</small></Form.Label>
                            <Form.Control
                                placeholder="Enter Product Title"
                                {...register('name', {
                                    onChange: (e) => {
                                        setValue('name', e.target.value)
                                    },
                                    required: {
                                        value: "required",
                                        message: "Product Title is required"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    },
                                })} type="text"
                            />
                            {errors.name && <Form.Text
                                className="text-muted validationText hasError">{errors.name.message}</Form.Text>}
                            <br/>
                            {/*Ar */}
                            <Form.Label>Product Title - AR</Form.Label>
                            <Form.Control
                                placeholder="Enter Product Title - AR"
                                {...register('name_ar', {
                                    onChange: (e) => {
                                        setValue('name_ar', e.target.value)
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    },
                                })} type="text"
                            />

                            {/*Cn */}
                            <Form.Label>Product Title - CN</Form.Label>
                            <Form.Control
                                placeholder="Enter Product Title - CN"
                                {...register('name_cn', {
                                    onChange: (e) => {
                                        setValue('name_cn', e.target.value)
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    },
                                })} type="text"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="product_description">
                            <Form.Label>About Appearance - EN<small style={{color: "red"}}>*</small></Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter About Appearance - EN"
                                    {...register('product_description', {
                                        onChange: (e) => {
                                            setValue('product_description', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "About appearance is required"
                                        },
                                    })}
                                />
                            </div>
                            {errors.product_description && <Form.Text
                                className="text-muted validationText hasError">{errors.product_description.message}</Form.Text>}
                            <br/>
                            {/*Ar */}
                            <Form.Label>About Appearance - AR</Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter About Appearance - AR"
                                    {...register('product_description_ar', {
                                        onChange: (e) => {
                                            setValue('product_description_ar', e.target.value)
                                        }
                                    })}
                                />
                            </div>
                            {/*Cn */}
                            <Form.Label>About Appearance - CN</Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter About Appearance - CN"
                                    {...register('product_description_cn', {
                                        onChange: (e) => {
                                            setValue('product_description_cn', e.target.value)
                                        }
                                    })}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="product_description">
                            <Form.Label>Delivery and Return - EN<small style={{color: "red"}}>*</small></Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter Delivery and Return - EN"
                                    {...register('delivery_and_return', {
                                        onChange: (e) => {
                                            setValue('delivery_and_return', e.target.value)
                                        },
                                        required: {
                                            value: "required",
                                            message: "Delivery and Return is required"
                                        },
                                    })}
                                />
                            </div>
                            {errors.delivery_and_return && <Form.Text
                                className="text-muted validationText hasError">{errors.delivery_and_return.message}</Form.Text>}
                            <br/>
                            {/*Ar */}
                            <Form.Label>Delivery and Return - AR</Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter Delivery and Return - AR"
                                    {...register('delivery_and_return_ar', {
                                        onChange: (e) => {
                                            setValue('delivery_and_return_ar', e.target.value)
                                        }
                                    })}
                                />
                            </div>
                            {/*Cn */}
                            <Form.Label>Delivery and Return - CN</Form.Label>
                            <div className={"wysiwyg-editor-containerxx"}>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    placeholder="Enter Delivery and Return - CN"
                                    {...register('delivery_and_return_cn', {
                                        onChange: (e) => {
                                            setValue('delivery_and_return_cn', e.target.value)
                                        }
                                    })}
                                />
                            </div>
                        </Form.Group>

                        <Row className={"g-0"}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Label className={"form-label"}>Price Matching</Form.Label>
                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <Form.Group className="mb-3" controlId="siteName">
                                            <Form.Control
                                                placeholder="Site name"
                                                name="SiteName"
                                                type="text"
                                                value={siteName}
                                                onChange={(e) => {
                                                    setSiteName(e.target.value)
                                                    setSiteNameRequired(false)
                                                }}
                                            />
                                            {siteNameRequired &&
                                            <Form.Text className="text-muted validationText hasError">Site name is
                                                required</Form.Text>}
                                        </Form.Group>

                                    </Col>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Form.Group className="mb-3" controlId="matchingPrice">
                                            <Form.Control
                                                placeholder="Price"
                                                name="matchingPrice"
                                                type="number"
                                                value={matchingPrice}
                                                onChange={(e) => {
                                                    setMatchingPrice(e.target.value)
                                                    setMatchingPriceRequired(false)
                                                }}
                                            />
                                            {matchingPriceRequired &&
                                            <Form.Text className="text-muted validationText hasError">Price is
                                                required</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Form.Group className="mb-3" controlId="siteURL">
                                            <Form.Control
                                                className={"urlField"}
                                                placeholder="Site Address"
                                                name="siteURL"
                                                type="url"
                                                value={siteURL}
                                                onChange={(e) => {
                                                    setSiteURL(e.target.value)
                                                    setSiteURLRequired(false)
                                                }}
                                            />
                                            {siteURLRequired &&
                                            <Form.Text className="text-muted validationText hasError">Site url is
                                                required</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Row>
                                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <Button onClick={addMatchClick}
                                                        className={"btn btn-green-theme active w-100"}>
                                                    Add a Match
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                                        <ul className={"list-inline list-variations listPriceMatch mt-3"}>
                                            {
                                                matchData ?
                                                    matchData.map((dt, index) => {
                                                        return (
                                                            <li className={"list-inline-item d-flex justify-content-between"}>
                                                                <span><span
                                                                    onClick={() => onPriceMatchDelete(index)}><DeleteRed/></span> {dt.siteName}</span>
                                                                <span>{dt.matchingPrice}</span>
                                                                <span>
                                                                    <Link className={"link-theme"} to={dt.siteURL}>
                                                                        <LinkIcon/> Link
                                                                    </Link>
                                                                </span>
                                                            </li>
                                                        )
                                                    }) : ''
                                            }
                                        </ul>

                                    </Col>
                                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                               value={"Add Product"} disabled={disableButton}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Form.Group className="mb-3" controlId="min_order" onSubmit={handleSubmit(onSubmit)}>
                            <Form.Label>Product Min Order<small style={{color: "red"}}>*</small></Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Product Min Order"
                                {...register('min_order', {
                                    pattern: {
                                        value: /^[1-9]\d*(\.\d+)?$/,
                                        message: "Enter value in numbers only"
                                    },
                                    required: {
                                        value: "required",
                                        message: "Product Price is required"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Max length is 255"
                                    },
                                })}
                            />
                            {errors.min_order && <Form.Text
                                className="text-muted validationText hasError">{errors.min_order.message}</Form.Text>}
                        </Form.Group>

                        {
                            role && role != constants.ROLES.ROLE_VENDOR ?
                                <Form.Group className="mb-3" controlId="store_id">
                                    <Form.Label>Store<small style={{color: "red"}}>*</small></Form.Label>
                                    <Select
                                        label={'Select Store'}
                                        options={filterStore}
                                        {...register("store_id", {
                                                required: {
                                                    value: "required",
                                                    message: "Store is required"
                                                },
                                            },
                                        )} />
                                    {errors.store_id && <Form.Text
                                        className="text-muted validationText hasError">{errors.store_id.message}</Form.Text>}
                                </Form.Group>
                                : ''
                        }

                        <Form.Group className="mb-3" controlId="category_id">
                            <Form.Label>Category<small style={{color: "red"}}>*</small></Form.Label>

                            <Select
                                label={'Select Category'}
                                options={filterCategory}
                                {...register("category_id", {
                                        required: {
                                            value: "required",
                                            message: "Category name is required"
                                        },
                                        onChange: (e) => {
                                            onChangeCategory(e)
                                        }
                                    },
                                )} />
                            {errors.category_id && <Form.Text
                                className="text-muted validationText hasError">{errors.category_id.message}</Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="sub_category_id">
                            <Form.Label>Sub Category<small style={{color: "red"}}>*</small></Form.Label>
                            <Select
                                label={'Select Sub Category'}
                                options={filterSubCategory}
                                onChange={(e) => {
                                    setSubCategory(e.target.value);
                                }}
                                {...register("sub_category_id", {
                                        required: {
                                            value: "required",
                                            message: "Sub Category name is required"
                                        }
                                    }
                                )} />
                            {errors.sub_category_id && <Form.Text
                                className="text-muted validationText hasError">{errors.sub_category_id.message}</Form.Text>}
                        </Form.Group>

                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="product_type">
                                    <Form.Label>Product Types</Form.Label>
                                    <Form.Control as={"select"}
                                        disabled
                                        aria-label="Prodcut Type"
                                        name="product_type"
                                        {...register("product_type", {
                                                onChange: (e) => {
                                                    setValue('product_type', e.target.value)
                                                    //setProductTypeValue(e.target.value);
                                                },
                                            }
                                        )}>
                                        <option value="">Select Category Status</option>
                                        {
                                            constants.CATEGORYSTATUS ?
                                                Object.entries(constants.CATEGORYSTATUS).map(item => {
                                                    return (<option selected={productType == item[1]} key={item[1]}
                                                                    value={item[1]}>{item[0]}</option>);
                                                }) : ''
                                        }
                                    </Form.Control>
                                    {errors.product_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.product_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="csa_required">
                                    <Form.Label>Need for CSA?<small style={{color: "red"}}>*</small></Form.Label>
                                    <Form.Control as={"select"}
                                        disabled
                                        aria-label="Need for CSA?"
                                        name="csa_required"
                                        {...register("csa_required", {
                                                onChange: (e) => {
                                                    setValue('csa_required', e.target.value)
                                                    //setCsaRequiredValue(e.target.value);
                                                },
                                            }
                                        )}>
                                        <option value="">Need for CSA?</option>
                                        <option selected={productType && productType == '10'} value="1">Yes</option>
                                        <option selected={productType && productType != '10'} value="0">No</option>
                                    </Form.Control>
                                    {errors.csa_required && <Form.Text
                                        className="text-muted validationText hasError">{errors.csa_required.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Label className={"form-label"}>Product Images<small
                            style={{color: "red"}}>*</small></Form.Label>
                        <div className={"box-dotted"}>
                            <CustomDropZone ref={{...register("media")}}/>
                            {
                                mediaRequiredError ?
                                    <Form.Text
                                        className="text-muted validationText hasError">Media is required</Form.Text>
                                    : ''
                            }
                        </div>

                        <Form.Label className={"form-label mt-3"}>Upload Description<small
                            style={{color: "red"}}>*</small></Form.Label>
                        <Form.Group controlId="filePDF" className="custom-file-upload mb-3">
                            <Form.Label className={"toggleFileUpload"}><UploadIcon/> Upload PDF</Form.Label>
                            <Form.Control type="file" size="lg"
                                          accept=".pdf"
                                          className={"d-none"} {...register('product_description_document')}/>

                            {
                                documentRequiredError ?
                                    <Form.Text
                                        className="text-muted validationText hasError">Document is required</Form.Text>
                                    : ''
                            }
                        </Form.Group>

                        <Form.Label className={"form-label"}>Product Variants<small
                            style={{color: "red"}}>*</small></Form.Label>
                        <Form.Group className="mb-3 checkbox bg-light p-3" controlId="formBasicCheckbox">
                            <Form.Check
                                type="checkbox"
                                checked={variantCheck}
                                label="Product has multiple options! like colors or sizes"
                                onChange={(e) => {
                                    handleCheckClick(e)
                                }}
                            />
                            {
                                variantRequiredError ?
                                    <Form.Text
                                        className="text-muted validationText hasError">product variation is
                                        required</Form.Text>
                                    : ''
                            }

                        </Form.Group>

                        {
                            showVariation ? <VariationDiv/> : ''
                        }

                        {
                            showBulkPrice ? <BulkPriceDiv/> : ''
                        }

                        {
                            showColor ? <ColorDiv/> : ''
                        }
                    </Col>
                </Row>
            </Form>

            {/* Modal Form to add variant*/}
            <ThemeModal title={"Add Variants"} content={<ModalForm/>} size={"sm"} show={modalShow}
                        onHide={() => setModalShow(false)}/>

            {/* Modal Form to add color*/}
            <ThemeModal title={"Add Colors"} content={<ColorModalForm/>} size={"sm"} show={colorModalShow}
                        onHide={() => setColorModalShow(false)}/>

            {/* Modal Form to add bulk price*/}
            <ThemeModal title={"Add Bulk Price"} content={<BulkPriceModalForm/>} size={"sm"} show={bulkPriceModalShow}
                        onHide={() => setBulkPriceModalShow(false)}/>
        </div>
    )
}

export default AddProduct
