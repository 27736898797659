import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaChartPie } from "react-icons/fa";
import DashboardStat from "../../../components/DashboardStat";
import { GetDashboard } from "../../../services/admin/dashboard.service";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { constants } from "../../../utils/constants";
import { EmptyLocalStorage } from "../../../services/auth/auth.service";
import { useHistory } from "react-router-dom";

const InitialStats = {
  user_count: 0,
  restaurant_count: 0,
};

function Admin({ RoleId }) {
  const history = useHistory();
  const [stats, setStats] = useState(InitialStats);
  useEffect(async () => {
    let params = { role_id: RoleId };
    await GetDashboard(params)
      .then((data) => {
        if (data.success) {
          setStats({
            user_count: data.data.total_users,
            restaurant_count: data.data.total_restaurants,
            
          });
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  }, []);

  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/manage-users"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Total Users"}
              icon={<FaChartPie />}
              countMain={stats.user_count}
            />
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to="/resturants"
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"gradient-blue"}
              title={"Total Resturants"}
              icon={<FaChartPie />}
              countMain={stats.restaurant_count}
            />
          </NavLink>
        </Col>
      </Row>
    </div>
  );
}

export default Admin;
