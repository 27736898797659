import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ProfileIcon from "../../assets/images/profile-icon.png";
import { AiOutlineEdit } from "react-icons/ai";
import {IoReloadOutline} from "react-icons/io5";
import {AiOutlineCloudDownload} from "react-icons/ai";
import { toast } from "react-toastify";
import { GetProfile, GenerateQRCode } from "../../services/users.service";
import { constants } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { EmptyLocalStorage, GetAuthUser } from "../../services/auth/auth.service";
import { useHistory } from "react-router-dom";
import dummyQRCode from "../../assets/images/QR.jpg"

const initialData = {
  id: '',
  full_name: '',
  email: '',
  phone: '',
  image: constants.USERIMAGENOTFOUND,
}

function ViewProfile() {
  const history = useHistory();
  const [data, setData] = useState(initialData);

  useEffect(() => {
    getProfile();
  }, []);

  useLayoutEffect(() => {
    getProfile();
  }, []);

  
  const generateQRCode = async () => {
    await GenerateQRCode().then(async (data) => {
      if (data.success) {
        toast.success(data.message);
        getProfile();
      } else {
          toast.error(data.message);
      }
    }).catch((error) => {
      console.log(error)
        if (error.response.status == 401) {
            EmptyLocalStorage()
            history.push('/');
        } else {
            return toast.error(error.response.data.message);
        }
    })
  }

  const getProfile = async () => {
    await GetProfile().then(async (result) => {
        if (result.success) {
            if (result.data) {
                let dt = result.data;
                setData(
                    {
                        id: dt.id,
                        full_name: dt.profile ? dt.profile.full_name : "-",
                        email: dt.email,
                        phone: dt.profile ? dt.profile.phone_number : "-",
                        image: dt.profile ? dt.profile.profile_image : constants.USERIMAGENOTFOUND,
                        qr_code: dt.qr_code ? dt.qr_code : "-"
                    }
                )
            }
        } else {
            toast.error(result.message);
        }
    }).catch((error) => {
        if (error.response.status == 401) {
            EmptyLocalStorage()
            history.push('/');
        } else {
            return toast.error(error.response.data.message);
        }
    })
  };

  return (
    <div className={"EditProfile"}>
      <h3 className="page-heading">View Profile</h3>
      <hr />
      <div className={"profileCard"}>
        <div className={"profileCardHeader"}>
          <Row>
            <Col xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className={"profileImg mx-auto text-center"}>
                <img
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = constants.USERIMAGENOTFOUND;
                  }}
                  src={data?.image}
                  alt={"Profile Image"}
                  className={"img-fluid"}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={9} lg={9} xl={9}>
              <div className={"profileDetails"}>
                <h4>{data?.full_name}</h4>
                <p>
                  <b>Email:</b> {data?.email}
                </p>
                {/* <p>
                  <b>Phone:</b> {data?.phone}
                </p> */}
              </div>
              {GetAuthUser()?.restaurant && 
              <div className="QR-Code">
                  <button className="qr-code-generator" onClick={generateQRCode}>
                      <IoReloadOutline/>
                  </button>

                <a download="custom-filename.jpg" href={data?.qr_code} target="_blank" title="ImageName">

                  <AiOutlineCloudDownload/>
                </a>
                  <img src={data?.qr_code} className="img-fluid" alt="ImageName" />
              </div>
              }
            </Col>
          </Row>
        </div>
        <NavLink
          className={"floating-link"}
          to={{ pathname: "/edit-profile", state: data }}
        >
          <AiOutlineEdit />
        </NavLink>

       
      </div>
      
     
    </div>
  );
}

export default ViewProfile;
