import React from "react";
import { NavLink } from "react-router-dom";
import {
  OrderIcon,
  NotificationIcon,
  ManageCategoryIcon,
  ManagepProductsIcon,
  ManageUsersIcon,
  ManageAgentsIcon,
  ManageVendorsIcon,
  ManagePaymentsIcon,
  ReportsIcon,
  SettingsIcon,
  HomeIcon,
  HelpIcon,
  ContactIcon,
} from "../SvgIcons";
import { constants } from "../../utils/constants";

const Admin = () => {
  return (
    <div className="d-flex flex-column flex-shrink-0 p-2 h-100 sideBar">
      <ul className="nav nav-pills sidebarNav flex-column mb-auto">
        <li className="nav-item">
          <NavLink
            to="/dashboard"
            className="nav-link"
            activeClassName="active"
          >
            <HomeIcon />
            Home
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink to="/orders" className="nav-link" activeClassName="active">
            <OrderIcon />
            Orders
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink to="/category" className="nav-link" activeClassName="active">
            <ManageCategoryIcon />
            Menu Category
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/manageitem"
            className="nav-link"
            activeClassName="active"
          >
            <ManageCategoryIcon />
            Menu Items
          </NavLink>
        </li>

        {/* <li className="nav-item">
                    <NavLink to="/resturantcategory" className="nav-link" activeClassName="active">
                        <ManageCategoryIcon/>
                        Resturant Category
                    </NavLink>
                </li> */}

        {/* <li className="nav-item">
          <NavLink
            to="/resturants"
            className="nav-link"
            activeClassName="active"
          >
            <ManageCategoryIcon />
            Resturant
          </NavLink>
        </li> */}

        {/* <li className="nav-item">
                    <NavLink to="/colors" className="nav-link" activeClassName="active">
                        <ManageCategoryIcon/>
                        Manage Color
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/products" className="nav-link" activeClassName="active">
                        <ManagepProductsIcon/>
                        Manage Products
                    </NavLink>
                </li> */}

        <li className="nav-item">
          <NavLink
            to="/manage-users"
            className="nav-link"
            activeClassName="active"
          >
            <ManageUsersIcon />
            Manage Customers
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/manage-host"
            className="nav-link"
            activeClassName="active"
          >
            <ManageUsersIcon />
            Manage Hosts
          </NavLink>
        </li>

        {/* <li className="nav-item">
                    <NavLink to="/manage-agents" className="nav-link" activeClassName="active">
                        <ManageAgentsIcon/>
                        Manage CS Agents
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/vendors" className="nav-link" activeClassName="active">
                        <ManageVendorsIcon/>
                        Manage Vendors
                    </NavLink>
                </li> */}

        {/* <li className="nav-item">
          <NavLink
            to="/payments-management"
            className="nav-link"
            activeClassName="active"
          >
            <ManagePaymentsIcon />
            Payments Management
          </NavLink>
        </li> */}

        {/* <li className="nav-item">
                    <NavLink to="/ads-management" className="nav-link" activeClassName="active">
                        <ManagePaymentsIcon/>
                        Ads Management
                    </NavLink>
                </li> */}

        {/*<li className="nav-item">
                    <NavLink to="/reports" className="nav-link" activeClassName="active">
                        <ReportsIcon/>
                        Analytics &amp; Reports
                    </NavLink>
                </li>*/}

        {/* <li className="nav-item">
                    <NavLink to="/reviews" className="nav-link" activeClassName="active">
                        <ManageCategoryIcon/>
                        Reviews
                    </NavLink>
                </li> */}

        <li className="nav-item">
          <NavLink
            to="/notifications"
            className="nav-link"
            activeClassName="active"
          >
            <NotificationIcon />
            Notifications
          </NavLink>
        </li>
        {/* <li className="nav-item">
                    <NavLink to={'/page-list/'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Pages
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to={'/Faqs'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        FAQs
                    </NavLink>
                </li> */}

        {/*<li className="nav-item">
                    <NavLink to="/settings" className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Settings
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to={'/page/' + constants.PAGES.TERMS} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Terms
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to={'/page/' + constants.PAGES.PRIVACY_POLICY} className="nav-link"
                             activeClassName="active">
                        <SettingsIcon/>
                        Privacy Policy
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/settings" className="nav-link" activeClassName="active">
                        <HelpIcon/>
                        Help Center
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/settings" className="nav-link" activeClassName="active">
                        <ContactIcon/>
                        Contact Us
                    </NavLink>
                </li>*/}
      </ul>
    </div>
  );
};

export default Admin;
