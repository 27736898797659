import {constants, apiUrl} from "../../utils/constants";
import {GET, PUT, POST} from "../api.service.wrapper";
import {GetAuthUser, GetUserRole} from "../../services/auth/auth.service";
import moment from "moment";

const user = GetAuthUser();
let user_role = GetUserRole();

export const GetOrders = async (user = "", keyword = "", status = "", date = "", page = "", perPage = "") => {
    let params = {};
    let user_id = await user?.id;
    let role_id = await user?.role_id;
    let store_id = await user?.store?.id;

    console.log("user_id:", user_id);

    if (page) {
        params["page"] = page;
    }

    if (perPage) {
        params["per-page"] = perPage;
    }

    if (keyword) {
        params["search"] = keyword;
    }

    if (status) {
        params["status"] = status;
    }

    if (date) {
        params["created_at"] = moment.utc(date).format("YYYY-MM-DD");
    }

    // if (role_id == constants.ROLES.ROLE_CSA) {
    //     params['agent_id'] = user_id;
    // }

    // if (role_id == constants.ROLES.ROLE_VENDOR) {
    //     params['store_id'] = store_id;
    // }

    const response = await GET(apiUrl.orders, params);
    console.log("response orders:", response);
    return response;
};

export const GetOrdersById = async (id) => {
    let response = "";
    response = await GET(apiUrl.order_detail + "/" + id);
    return response;
};
export const GetOrdersByCustomerId = async (customer) => {
    let params = {};
    if (customer) {
        params["customer"] = customer;
    }
    let response = "";
    response = await GET(apiUrl.customerorder, params);
    return response;
};
export const UpdateOrder = async (id, data) => {
    const response = await PUT(apiUrl.orders, id, data);
    return response;
};

export const UpdateOrderStatus = async (orderId, data) => {
    const response = await PUT(`order/${orderId}/${apiUrl.update_order}`, null, data);
    return response;
};

export const UpdateStatus = async (id, data) => {
    const response = await PUT(apiUrl.updateStatus, id, data);
    return response;
};
