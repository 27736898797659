import React, {useRef, useState, useEffect} from 'react'
import {Form, Row, Col, InputGroup, FormControl, Button} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import {useForm} from "react-hook-form";
import {GetAdsById, UpdateAds} from "../../services/advertisement.service";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {constants} from "../../utils/constants";

function EditAdvertisements(props) {
    let history = useHistory();
    const [disableButton, setDisableButton] = useState(false);
    const [data, setData] = useState([]);
    const [adType, setAdType] = useState("image");

    const {register, watch, reset, setValue, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur"
    });

    useEffect(async () => {
        let id = props.match.params.id;
        await getAdsById(id);
    }, []);

    const getAdsById = async (id) => {
        await GetAdsById(id).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    console.log(result.data)
                    await setData({
                        title: result.data.title ? result.data.title : '',
                        description: result.data.description ? result.data.description : '',
                        external_url: result.data.external_url ? result.data.external_url : '',
                        ad_type: result.data.image ? 'image' : 'video',
                        image: result.data.image ? result.data.image : '',
                        video: result.data.video ? result.data.video : '',
                        mediumImage: result.data.mediumImage ? result.data.mediumImage : '',
                        videoUrl: result.data.videoUrl ? result.data.videoUrl : ''
                    });
                    await setAdType(result.data.image ? 'image' : 'video')
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async data => {
        let id = props.match.params.id;
        setDisableButton(true)
        let formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('external_url', data.external_url);
        formData.append('ad_type', adType);
        if (data?.media[0]) {
            formData.append('media', data?.media[0]);
        }

        await UpdateAds(id, formData).then(async (data) => {
            if (data.status) {
                toast.success(data.message);
                history.push('/ads-management');
            } else {
                setDisableButton(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Advertisement - {data.title}</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title<small style={{color: "red"}}>*</small></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"
                                        id={"formTitle"}
                                        defaultValue={data?.title}
                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3"
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Max length is 50"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description<small style={{color: "red"}}>*</small></Form.Label>
                                    <div className={"wysiwyg-editor-containerxx"}>
                                        <Form.Control
                                            as="textarea"
                                            rows={6}
                                            placeholder="Enter Description"
                                            defaultValue={data?.description}
                                            {...register('description', {
                                                onChange: (e) => {
                                                    setValue('description', e.target.value)
                                                },
                                                required: {
                                                    value: "required",
                                                    message: "Description is required"
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="external_url">
                                    <Form.Label>External URL</Form.Label>
                                    <Form.Control
                                        placeholder="Enter External URL"
                                        id={"formTitle"}
                                        defaultValue={data?.external_url}
                                        {...register('external_url', {
                                            maxLength: {
                                                value: 255,
                                                message: "Max length is 255"
                                            },
                                        })} type="text"
                                    />
                                    {errors.external_url && <Form.Text
                                        className="text-muted validationText hasError">{errors.external_url.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="AdType">
                                    <Form.Label>Ad Type</Form.Label>
                                    <Form.Select
                                        aria-label="Ad Type"
                                        name="AdType"
                                        onChange={(e) => {
                                            setAdType(e.target.value)
                                        }}
                                    >
                                        <option selected={data.ad_type == "image"} value="image">Image</option>
                                        <option selected={data.ad_type == "video"} value="video">Video</option>
                                    </Form.Select>
                                    {errors.AdType && <Form.Text
                                        className="text-muted validationText hasError">{errors.AdType.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Media</Form.Label>
                                    <Form.Control
                                        id={"formTitle"}
                                        {...register('media')} type="file"
                                        accept={adType == constants.ADTYPE.IMAGE ? "image/jpeg, image/jpg, image/png" : "video/mp4"}
                                    />
                                    {errors.media && <Form.Text
                                        className="text-muted validationText hasError">{errors.media.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Group className="mb-3" controlId="image">
                                <Form.Label>Uploaded Media</Form.Label>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    {
                                        data?.ad_type && data.ad_type == "image" ?
                                            <img class="img img-thumbnail" src={data?.mediumImage} alt="-" width="150"
                                                 height="150"/>
                                            :
                                            <video width="320" style={{borderRadius: '5px'}} controls>
                                                <source src={data?.videoUrl} type="video/mp4"/>
                                                Your browser does not support the audio element.
                                            </video>
                                    }
                                </Col>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                       disabled={disableButton}
                                       value={"Edit User"}/>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditAdvertisements
