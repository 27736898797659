import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  SearchIcon,
  FilterIcon,
  StatusIcon,
  CalenderIcon,
  MenuDots,
} from "../../components/SvgIcons";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import { GetOrders, UpdateStatus } from "../../services/csa/orders.service";
import { toast } from "react-toastify";
import moment from "moment";
import { constants, customStyles } from "../../utils/constants";
import { useHistory } from "react-router";
import { MoneyFormat } from "../../utils/helpers";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {
  EmptyLocalStorage,
  GetAuthUser,
} from "../../services/auth/auth.service";
import {
  CreateOrderShipping,
  CreateShipping,
} from "../../services/order_shipping.service";

function Orders(props) {
  const history = useHistory();
  const [rows, setRows] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(props?.location?.search);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    getOrderFunction();
  }, [keyword, status, date, page, perPage]);

  const getOrderFunction = async () => {
    if (!status && query.get("status")) {
      setStatus(query.get("status"));
    } else {
      setRows([{}]);
      setLoading(true);
      const user = GetAuthUser();
      await GetOrders(user, keyword, status, date != "" ? new moment(new Date(date), "YYYY/MM/DD").utc().format() : null, page, perPage)
        .then((result) => {
          if (result.success) {
            if (result.data.data) {
              const rowData = [];
              setPage(result.data.meta.current_page);
              setTotalRows(result.data.meta.total);
              result?.data?.data.map((dt) => {
                // let delivery_date = dt.shipping_date
                //   ? moment.utc(dt.shipping_date).format("DD-MM-YYYY")
                //   : "";
                // let status = dt.status
                //   ? constants.ORDERSTATUSKEY[dt.status]
                //   : "";

                rowData.push({
                  id: dt?.id,
                  oid: dt?.order_no,
                  customerName: dt?.customer,
                  agentName: dt?.restaurant || "-",
                  //order: 'Red Velvet Bag',
                  // deliveryDate: delivery_date,
                  keyword: keyword,
                  price: dt?.total,
                  // date: moment(dt?.created_at).fromNow(),
                  date: (dt?.order_date),
                  status: dt?.status,
                  // action: "",
                });
              });
              setRows(rowData);
              setLoading(false);
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  const updateOrder = async (orderId, data) => {
    await UpdateStatus(orderId, data)
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          setModalShow(false);
          reset();
          getOrderFunction();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const statusCheck = (status) => {
    if (status) {
      let statusClass = "status-badge status-" + status.toLowerCase();
      return <div className={statusClass}>{status}</div>;
    } else {
      return <div className={"status-badge status-undefined"}>{status}</div>;
    }
  };

  const onMarkCompleted = (order_id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to mark this product as completed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        UpdateStatus(order_id, { status: constants.ORDERSTATUS.COMPLETED });
        reset();
        getOrderFunction();
        swal("Your order has been marked as completed", {
          icon: "success",
        });
      }
    });
  };

  const onMarkReturned = (order_id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to mark this product as returned?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        UpdateStatus(order_id, { status: constants.ORDERSTATUS.RETURNED });
        reset();
        getOrderFunction();
        swal("Your order has been marked as returned", {
          icon: "success",
        });
      }
    });
  };

  const onMarkShipped = (order_id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to mark this product as shipped?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willShip) => {
        if (willShip) {
          //Create Shipping
          let create_shipping = await CreateShipping(order_id);
          //console.log('cs==>',create_shipping)
          if (create_shipping.status) {
            await UpdateStatus(order_id, {
              status: constants.ORDERSTATUS.SHIPPED,
            });
            reset();
            getOrderFunction();
            swal("Your order has been marked as shipped", {
              icon: "success",
            });
          } else {
            swal(create_shipping.message, {
              icon: "error",
            });
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  //   const orderActions = (data) => {
  //     return (
  //       <div>
  //         {data.status !=
  //         constants.ORDERSTATUSKEY[constants.ORDERSTATUS.RETURNED] ? (
  //           <DropdownButton
  //             align="end"
  //             className={"actionDropdown"}
  //             id="dropdown-item-button"
  //             title={<MenuDots />}
  //           >
  //             {data.status ==
  //               constants.ORDERSTATUSKEY[constants.ORDERSTATUS.PREPARING] ||
  //             data.status ==
  //               constants.ORDERSTATUSKEY[constants.ORDERSTATUS.READY_TO_PICK] ||
  //             data.status ==
  //               constants.ORDERSTATUSKEY[constants.ORDERSTATUS.SERVED] ? (
  //               <Dropdown.Item
  //                 as="button"
  //                 onClick={() => {
  //                   setModalShow(true);
  //                   setOrderId(data);
  //                 }}
  //               >
  //                 Change Status
  //               </Dropdown.Item>
  //             ) : (
  //               ""
  //             )}

  //             {/* {data.status ==
  //             constants.ORDERSTATUSKEY[constants.ORDERSTATUS.PAID] ? (
  //               <Dropdown.Item
  //                 as="button"
  //                 onClick={() => {
  //                   onMarkShipped(data.id);
  //                 }}
  //               >
  //                 Mark as Shipped
  //               </Dropdown.Item>
  //             ) : (
  //               ""
  //             )}
  //  */}
  //             {data.status ==
  //             constants.ORDERSTATUSKEY[constants.ORDERSTATUS.SHIPPED] ? (
  //               <Dropdown.Item
  //                 as="button"
  //                 onClick={() => {
  //                   onMarkCompleted(data.id);
  //                 }}
  //               >
  //                 Mark as Completed
  //               </Dropdown.Item>
  //             ) : (
  //               ""
  //             )}

  //             {data.status ==
  //               constants.ORDERSTATUSKEY[constants.ORDERSTATUS.REVIEWED] ||
  //             data.status ==
  //               constants.ORDERSTATUSKEY[constants.ORDERSTATUS.COMPLETED] ? (
  //               <Dropdown.Item
  //                 as="button"
  //                 onClick={() => {
  //                   onMarkReturned(data.id);
  //                 }}
  //               >
  //                 Mark as Returned
  //               </Dropdown.Item>
  //             ) : (
  //               ""
  //             )}
  //           </DropdownButton>
  //         ) : (
  //           ""
  //         )}
  //       </div>
  //     );
  //   };

  const columns = [
    {
      id: "oid",
      name: "Order no",
      sortable: true,
      selector: (rowData) => rowData.id,
      format: (rowData) => (
        rowData.oid
        // <a
        //   target={"_self"}
        //   style={{ color: "#9F9F9F", textDecoration: "none" }}
        //   href={"/orders/order-details/" + rowData.id}
        // >
        // </a>
      ),
    },
    {
      id: "customerName",
      name: "Customer",
      maxWidth: "160px",
      selector: (rowData) => rowData.customerName,
    },
    {
      id: "agentName",
      name: "Restaurant",
      selector: (rowData) => rowData.agentName,
    },
    {
      id: "price",
      name: "Price",
      sortable: true,
      selector: (rowData) => `$${Number(rowData.price).toFixed(2)}`,
    },
    {
      id: "date",
      name: "Date",
      selector: (rowData) => rowData.date,
    },

    {
      id: "status",
      name: "Status",
      selector: (rowData) => statusCheck(rowData.status),
    },
    // {
    //   id: "action",
    //   name: "Action",
    //   cell: (rowData) => orderActions(rowData),
    // },
  ];

  const onModalSubmit = async (data) => {
    await updateOrder(orderId.id, { status: data.orderStatus });
  };
  /*
   *
   *  form elements inside popup
   *
   * */

  const updateStatusOnChange = async (val) => {
    try {
      // update code goes here
    }
    catch (e) {
      // error code goes here
    }
  }

  const ModalForm = () => {
    return (
      <div className={"modalBodyContent"}>
        <Form
          className={"form-changeStatus"}
          onSubmit={handleSubmit(onModalSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3" controlId="formStatus">
                <Form.Label>Order Status</Form.Label>
                <Form.Select
                  aria-label="Order Status"
                  {...register("orderStatus", {
                    onChange: (e) => {
                      updateStatusOnChange(e.target.value)
                    },
                    required: {
                      value: "required",
                      message: "Order Status name is required",
                    },
                  })}
                >
                  <option selected value="">
                    Select Order Status
                  </option>
                  {Object.entries(constants.ORDERSTATUS).map((item) => {
                    if (
                      item[1] == constants.ORDERSTATUS.PREPARING ||
                      item[1] == constants.ORDERSTATUS.READY_TO_PICK ||
                      item[1] == constants.ORDERSTATUS.SERVED
                    ) {
                      return (
                        <option
                          key={item[1]}
                          selected={
                            constants.ORDERSTATUS[orderId.status] === item[1]
                          }
                          value={item[1]}
                        >
                          {item[0]}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
                {errors.orderStatus && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.orderStatus.message}
                  </Form.Text>
                )}
              </Form.Group>
              <inputform
                type={"submit"}
                className={"btn btn-green-theme py-2 px-5 mt-3"}
                value={"Update Order"}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  /*
   *
   *
   *  Date Range
   *
   * */
  const CustomDateRange = ({ openCalendar, value }) => {
    return (
      <input
        type={"text"}
        onFocus={openCalendar}
        value={value}
        placeholder="Select Date"
        readOnly
        className={"form-control"}
      />
    );
  };

  return (
    /*add page name as class to each parent div of the page like here*/
    <div className={"orderPage"}>
      <h3 className="page-heading">Orders</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 0
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                <InputGroup className="mb-3 select-group DateRangePicker">
                  <InputGroup.Text id="basic-addon1">
                    <CalenderIcon />
                  </InputGroup.Text>
                  <DatePicker
                    inputClass="dateRange"
                    render={<CustomDateRange />}
                    value={date}
                    onChange={setDate}
                  >
                    <button
                      className="btn-green-theme"
                      style={{ margin: "5px" }}
                      onClick={() => {
                        setDate("");
                      }}
                    >
                      RESET
                    </button>
                  </DatePicker>
                </InputGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <InputGroup className="mb-3 select-group">
                  <InputGroup.Text id="basic-addon1">
                    <StatusIcon />
                  </InputGroup.Text>
                  <Form.Select
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    aria-label="Default Filters"
                  >
                    <option selected value="">
                      STATUS
                    </option>
                    {Object.entries(constants.ORDERSTATUSVALUES).map((item, index) => {
                      return (
                        <option
                          selected={status == item[1]}
                          key={item[1]}
                          value={index + 1}
                        >
                          {item[1]}
                        </option>
                      );
                    })}
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid new-orders-dataGrid">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          onRowClicked={rowData => {
            history.push(`/orders/order-details/${rowData.id}`)
          }}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#12726C"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />
      </div>
      {/* Modal Form to add new product*/}
      <ThemeModal
        title={"Change Status"}
        size={"sm"}
        content={<ModalForm />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Orders;
