const roles = {
    ROLE_ADMIN: 4,
    RESTAURANT_OWNER: 2,
    ROLE_RESTAURANT_STAFF: 3,
    ROLE_ROLE_USER: 20,
    ROLE_DESIGNER: 30,
    ROLE_VENDOR: 40,
    ROLE_CSA: 50,
};

const orderStatus = {
    PREPARING: 1,
    READY_TO_PICK: 2,
    SERVED: 3,
};

 const orderStatusValues = {
    PREPARING: "PREPARING",
    READY_TO_PICK: "READY FOR PICKUP",
    SERVED: "SERVED",
};

const orderStatusKey = {
    [orderStatus.PREPARING]: "PREPARING",
    [orderStatus.READY_TO_PICK]: "READY FOR PICKUP",
    [orderStatus.SERVED]: "SERVED",
    [orderStatus.PAID]: "SERVED",
    [orderStatus.SHIPPED]: "SHIPPED",
    [orderStatus.COMPLETED]: "COMPLETED",
    [orderStatus.REVIEWED]: "REVIEWED",
    [orderStatus.RETURNED]: "RETURNED",
};

const page = {
    PRIVACY_POLICY: "privacy-policy",
    TERMS: "terms-and-conditions",
};

const categoryStatus = {
    CUSTOMIZE: 10,
    IN_STOCK: 20,
    DESIGN: 30,
    New: 44,
};

const categoryStatusKey = {
    [categoryStatus.CUSTOMIZE]: "CUSTOMIZE",
    [categoryStatus.IN_STOCK]: "IN_STOCK",
    [categoryStatus.DESIGN]: "DESIGN",
};

const collections = {
    CHATROOM: "chatRoom_live",
    USERS: "users_live",
    CHATLIST: "chatlist",
    GENERALCHATLIST: "general_chat_users_live",
    GENERALCHATROOM: "general_chatRoom_live",
};

const s3Credential = {
    /* Live Credentials*/
    /*bucketName: "imprint-media",
      dirName: "chat",
      region: "us-east-2",
      accessKeyId: "AKIAQHMBY74RRTTHPF6X",
      secretAccessKey: "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",
      s3EndPoint: "'https://imprint-media.s3.ap-south-1.amazonaws.com/",
      fileSize: '5000000'*/

    /* Local Credentials for Bucket */
    bucketName: "imprint-app",
    dirName: "chat",
    region: "ap-south-1",
    accessKeyId: "AKIAXCCCZ7TIGCSQPJIW",
    secretAccessKey: "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",
    s3EndPoint: "https://imprint-app.s3.ap-south-1.amazonaws.com",
    fileSize: "5000000",
};

const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
};

const contentType = {
    "image/jpeg": "image",
    "image/jpg": "image",
    "image/png": "image",
    "image/gif": "image",
    "application/pdf": "doc",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "doc",
    "audio/mpeg": "audio",
    "video/mp4": "video",
};

const chatMessage = {
    CONTENT: 0,
    CONTENT_SOFT_DELETE: 1,
    CONTENT_HARD_DELETE: 2,
};

const AdType = {
    IMAGE: "image",
    VIDEO: "video",
};

export const constants = {
    dnone: "d-none",
    notdnone: "",
    LOCAL_STORAGE_TOKEN: "AUTH_ACCESS_TOKEN",
    LOCAL_STORAGE_USER: "USER_DATA",
    BASE_URL: process.env.REACT_APP_BASE_URL,
    // CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
    // ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
    // VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
    ROLES: roles,
    ORDERSTATUS: orderStatus,
    ORDERSTATUSVALUES: orderStatusValues,
    ORDERSTATUSKEY: orderStatusKey,
    LOCALE: "en-OM",
    CURRENCY: "OMR",
    PAGES: page,
    PERPAGE: 20,
    // IMAGENOTFOUND: "https://imprint.tekstaging.com/not-found.png",
    IMAGENOTFOUND: "https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg",
    USERIMAGENOTFOUND: "https://imprint.tekstaging.com/not-found-user.png",
    CATEGORYSTATUS: categoryStatus,
    CATEGORYSTATUSKEY: categoryStatusKey,
    COLLECTION: collections,
    S3CREDENTIAL: s3Credential,
    ERRORMESSAGES: errorMessages,
    CONTENTTYPE: contentType,
    CHATMESSAGE: chatMessage,
    ADTYPE: AdType,
};

export const apiUrl = {
    login: "signin",
    logout: "user/logout",
    forgot_password: "user/forgot_password",
    verify_otp: "verify",
    resend_otp: "verify/send_otp",
    reset_password: "reset-password",
    change_password: "change-password",
    pages: "pages",
    orders: "order/admin",
    update_order: "update_order_status",
    order_detail: "restaurant-order",
    customerorder: "order",
    updateStatus: "update-status",
    dashboard: "dashboard",
    products: "products",
    categories: "restaurant",
    sub_categories: "sub-categories",
    profile: "profile",
    notifications: "notification",
    notifications_count: "notification/count",
    mark_all_as_read: "notification/mark_all_as_read",
    send_bulk_message: "notification/send_bulk_message",
    users: "user",
    generate_qr_code: "generate_qr_code",
    restaurant_users: "checked_in_users",
    hosts: "user/host_list",
    deleteusers: "check_in",
    checkout: "check_in",
    blockuser: "user/block",
    register: "register",
    register: "signup/host",
    product_variants: "product-variants",
    order_product_variants: "order-product-variants",
    update_order_product_variants: "update-order-product-variant",
    remove_attachment: "remove-attachment",
    product_comparisons: "product-comparisons",
    colors: "colors",
    get_colors: "get-colors",
    ads: "ads",
    create_shipping: "create-shipping",
    track_shipping: "track-shipping",
    order_shipping: "order-shippings",
    transactions: "transactions",
    update_transaction_status: "update-transaction-status",
    fetch_countries: "fetch-countries",
    fetch_states: "fetch-states",
    fetch_cities: "fetch-cities",
    validate_address: "check-address",
    chat_notification: "chat-notification",
    reviews: "reviews",
    menu_item: "menu/menu_items_admin",
    // menu_item_dropdown: "restaurant/5/menu_category_dropdown",
    // menu_categories: "restaurant/5/menu_category_flat",
    // add_menu: "restaurant/5/add_menu",
    menu: "menu",
    restaurant: "restaurant",
    category: "category",
    menu_ite_update: "menu-item",
    faqs: "faq",
};

export const customStyles = {
    rows: {
        style: {
            minHeight: "50px",
        },
    },
    headCells: {
        style: {
            color: "#000",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "19px",
            fontFamily: "Poppins",
            padding: "5px",
        },
    },
    cells: {
        style: {
            padding: "5px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "19px",
            fontFamily: "Poppins",
        },
    },
};
