import React, {useEffect, useState} from "react";
import {Col, Row,} from "react-bootstrap";
import defaultimage from "../../assets/images/default.png";
import {GetOrdersByCustomerId, UpdateOrderStatus,} from "../../services/csa/orders.service";
import {toast} from "react-toastify";
import moment from "moment";
import {constants} from "../../utils/constants";
import {EmptyLocalStorage, GetUserRole} from "../../services/auth/auth.service";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {GetCustomerById} from "../../services/users.service";
import ThemeModal from "../../components/ThemeModal";

const InitialOrderProducts = {
    id: "", name: "", image: "", price: "", tax_percentage: "", about: "", quantity: "",
};
const InitialData = {
    order_number: "", total_amount: "", description: "", order_products: [InitialOrderProducts], order_summary: {
        status: "", created_at: "", shipping_date: "", total_amount: "", total_shipping_fees: "",
    }, shipping_address: {
        address_line_1: "", street: "", postal_code: "", city: "", country: "",
    }, customer: {
        full_name: "", phone: "", email: "", visits: "",
    }, restaurant: {
        name: "", address: "", timings: "",
    },
};

function CustomerDetails(props) {
    const history = useHistory();
    const [data, setData] = useState(InitialData);
    const [customerid, setCustomerId] = useState()
    useEffect(() => {
        let customer_id = props.match.params.id;
        setCustomerId(customer_id)
    }, [])
    const [customerData, setCustomerData] = useState([])
    const [customerOrderData, setCustomerOrderData] = useState([])
    const [frontPic, setFrontPic] = useState(false)
    const [backPic, setBackPic] = useState(false)
    useEffect(() => {
        if (customerid) {
            getcustomerbyId(customerid)
        }
    }, [customerid])
    useEffect(() => {
        if (customerid) {
            getOrdersByCustomerId()
        }
    }, [customerid])
    const getcustomerbyId = async () => {
        await GetCustomerById(customerid).then((result) => {
            let rowData = []
            setCustomerData(result)
        })
    }
    // frontpic modal
    const type = (data)=>{
        return (data === 1? "Driving License": data === 2? "Government ID Card": data === 3? "Passport":"N/A")
    }
    const FrontPic = () => {
        return (<>
            <span>{customerData && customerData?.id_card && customerData?.id_card?.front_photo ?
                <div className={"img-bg"} onClick={() => setFrontPic(true)}>
                    <img
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                        }}
                        src={customerData?.id_card?.front_photo || defaultimage}
                        alt={"Product Image"}
                        title={"Product Image"}
                        className={"img-fluid"}
                        width={"300px"}
                        //  width={"49px"}
                    />
                </div> : "N/A"}
            </span>
        </>)
    }
    const BackPic = () => {
        return (<>
            <span>{customerData && customerData?.id_card && customerData?.id_card?.back_photo ?
                <div className={"img-bg"}>
                    <img
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                        }}
                        src={customerData?.id_card?.back_photo || defaultimage}
                        alt={"Product Image"}
                        title={"Product Image"}
                        className={"img-fluid"}
                        width={"300px"}
                        //  width={"49px"}
                    />
                </div> : "N/A"}
            </span>
        </>)
    }

    const getOrdersByCustomerId = async () => {
        await GetOrdersByCustomerId(customerid).then((result) => {
            setCustomerOrderData(result.data.data)
            console.log(customerOrderData, "daaaaaaa")
        })
    }

    const orderSummary = data.order_products.flatMap((product, indexProduct) => {
        console.log("image: ", product);

        let total_price = parseInt(product.price) * parseInt(product.quantity);
        return (<div className={"ItemDescription"}>
            <Row className={"g-0"}>
                <Col xs={2}>
                    <div className={"img-bg"}>
                        <img
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={product.image || defaultimage}
                            alt={"Product Image"}
                            title={"Product Image"}
                            className={"img-fluid"}
                            width={"36px"}
                            //  width={"49px"}
                        />
                    </div>
                </Col>
                <Col xs={10} className="ps-1">
                    <h4>{product.name}</h4>
                    <p>{product.about}</p>

                    {/*<ReadMore textLength={"40"}>*/}
                    {/*    {product.about}*/}
                    {/*</ReadMore>*/}
                </Col>
            </Row>
        </div>);
    });
    const {
        register, handleSubmit, reset, formState: {errors},
    } = useForm({
        mode: "onChange",
    });
    const [orderId, setOrderId] = useState(false);
    let role = GetUserRole();

    const updateOrderStatus = async (id, statusId, oldStatus) => {
        let formData = new FormData();
        formData.append("status", statusId);

        await UpdateOrderStatus(id, formData)
            .then((data) => {
                if (data.success) {
                    toast.success(data.message);
                    reset();
                } else {
                    toast.error(data.message);
                    reset();
                    setData((current) => {
                        return {
                            ...current, order_summary: {
                                ...current.order_summary, status: oldStatus,
                            },
                        };
                    });
                }
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    EmptyLocalStorage();
                    history.push("/");
                } else {
                    return toast.error(error.response.data.message);
                }
            });
    };
    return (/*add page name as class to each parent div of the page like here*/
        <div className={"orderPage OrderDetails"}>
            {/*<BreadcrumbsComponent/>*/}
            <h3 className="page-heading">
                Customer Detail
            </h3>
            <hr/>

            <Row>
                <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <div className={"BoxCustom OrderSummary mb-4"}>
                        <div className={"box-header"}>
                            <Row className={"g-0 align-items-center"}>
                                <Col xs={8}>
                                    <h4>{customerData && customerData.profile ? customerData?.profile?.full_name : "N/A"}</h4>
                                </Col>
                            </Row>
                        </div>
                        <hr className={"mb-3"}/>
                        <div className={"box-body"}>
                            <ul className={"list-unstyled cusrtomer_details"}>
                                <li>
                                    <div className={"img-bg customer_image"}>
                                        <img
                                            onError={({currentTarget}) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = constants.IMAGENOTFOUND;
                                            }}
                                            src={customerData?.profile?.profile_image || defaultimage}
                                            alt={"Product Image"}
                                            title={"Product Image"}
                                            className={"img-fluid"}
                                            width={"100px"}
                                            //  width={"49px"}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>D.O.B :</span>
                                    <span>{customerData && customerData?.profile ? customerData?.profile?.dob : "N/A"}</span>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Zip Code :</span>
                                    <span>{customerData && customerData?.profile ? customerData?.profile?.zipcode : "N/A"}</span>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Contact :</span>
                                    <span>{customerData && customerData?.profile ? customerData?.profile?.phone_number : "N/A"}</span>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>BIO :</span>
                                    <span>{customerData && customerData?.bio ? customerData?.profile?.bio : "N/A"}</span>
                                </li>

                                <hr className={"mb-3"}/>
                                <li>
                                    <h6 style={{fontWeight: "800", fontStyle: "bold"}}>Document</h6>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Type :</span>
                                    <span>{customerData && customerData?.id_card ? type(customerData?.id_card?.type) : "N/A"}</span>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Images :</span>

                                </li>
                                <li>
                                    <Row>
                                        <Col md={5} sm={5}>
                                            <span>{customerData && customerData?.id_card && customerData?.id_card?.front_photo ?
                                                <div className={"img-bg"} onClick={() => setFrontPic(true)}>
                                                    <img
                                                        onError={({currentTarget}) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = constants.IMAGENOTFOUND;
                                                        }}
                                                        src={customerData?.id_card?.front_photo || defaultimage}
                                                        alt={"Product Image"}
                                                        title={"Product Image"}
                                                        className={"img-fluid"}
                                                        width={"80px"}
                                                        //  width={"49px"}
                                                    />
                                                </div> : "N/A"}
                                            </span>
                                        </Col>
                                        <Col md={5} sm={5}>
                                            <span>{customerData && customerData?.id_card && customerData?.id_card?.back_photo ?
                                                <div className={"img-bg"} onClick={() => setBackPic(true)}>
                                                    <img
                                                        onError={({currentTarget}) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = constants.IMAGENOTFOUND;
                                                        }}
                                                        src={customerData?.id_card?.back_photo || defaultimage}
                                                        alt={"Product Image"}
                                                        title={"Product Image"}
                                                        className={"img-fluid"}
                                                        width={"80px"}
                                                        //  width={"49px"}
                                                    />
                                                </div> : "N/A"}
                                            </span>
                                        </Col>
                                    </Row>

                                </li>
                                <hr className={"mb-3"}/>
                                <li>
                                    <h6 style={{fontWeight: "800", fontStyle: "bold"}}>Join Us</h6>
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Facebook :</span>
                                    {customerData && customerData?.profile && customerData?.profile?.facebook_url ?
                                        <a target={"blank"}
                                           style={{
                                               textDecoration: "none",
                                               fontSize: "15px",
                                               fontStyle: "inherit",
                                               color: "#4099ff",
                                           }}
                                           href={customerData?.profile?.facebook_url}>{customerData?.profile?.facebook_url}</a> : "N/A"}

                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Twiter :</span>
                                    {customerData && customerData?.profile && customerData?.profile?.twitter_url ?
                                        <a target={"blank"}
                                           style={{
                                               textDecoration: "none",
                                               fontSize: "15px",
                                               fontStyle: "inherit",
                                               color: "#4099ff",
                                           }}
                                           href={customerData?.profile?.twitter_url}>{customerData?.profile?.twitter_url}</a> : "N/A"}

                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>Instagram :</span>
                                    {customerData && customerData?.profile && customerData?.profile?.instagram_url ?
                                        <a target={"blank"}
                                           style={{
                                               textDecoration: "none",
                                               fontSize: "15px",
                                               fontStyle: "inherit",
                                               color: "#4099ff",
                                           }}
                                           href={customerData?.profile?.instagram_url}>{customerData?.profile?.instagram_url}</a> : "N/A"}
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>LinkedIn :</span>
                                    {customerData && customerData?.profile && customerData?.profile?.linkedin_url ?
                                        <a target={"blank"}
                                           style={{
                                               textDecoration: "none",
                                               fontSize: "15px",
                                               fontStyle: "inherit",
                                               color: "#4099ff",
                                           }}
                                           href={customerData?.profile?.linkedin_url}>{customerData?.profile?.linkedin_url}</a> : "N/A"}
                                </li>
                                <li>
                                    <span style={{fontWeight: "600", fontStyle: "bold"}}>URL :</span>
                                    {customerData && customerData?.profile && customerData?.profile?.url ?
                                        <a target={"blank"}
                                           style={{
                                               textDecoration: "none",
                                               fontSize: "15px",
                                               fontStyle: "inherit",
                                               color: "#4099ff",
                                           }}
                                           href={customerData?.profile?.url}>{customerData?.profile?.url}</a> : "N/A"}

                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*  <div className={"BoxCustom Details mb-4"}>*/}
                    {/*      <div className={"box-header mb-0"}>*/}
                    {/*          <Row className={"g-0 align-items-center"}>*/}
                    {/*              <Col xs={12}>*/}
                    {/*                  <h4>Customer Details</h4>*/}
                    {/*              </Col>*/}
                    {/*          </Row>*/}
                    {/*      </div>*/}
                    {/*      <hr className={"mb-3"}/>*/}
                    {/*      <div className={"box-body"}>*/}
                    {/*          <ul className={"list-unstyled"}>*/}
                    {/*              <li>*/}
                    {/*                  <span>Customer Name</span>*/}
                    {/*                  <span>{data.customer.full_name}</span>*/}
                    {/*              </li>*/}
                    {/*              <li>*/}
                    {/*                  <span>Phone Number</span>*/}
                    {/*                  <span>{data.customer.phone}</span>*/}
                    {/*              </li>*/}
                    {/*              <li>*/}
                    {/*                  <span>Email</span>*/}
                    {/*                  <span>{data.customer.email}</span>*/}
                    {/*              </li>*/}
                    {/*              <li>*/}
                    {/*                  <span>Visits</span>*/}
                    {/*                  <span>{data.customer.visits}</span>*/}
                    {/*              </li>*/}
                    {/*          </ul>*/}
                    {/*      </div>*/}
                    {/*  </div>*/}
                    {/*  <div className={"BoxCustom ShippingAddress mb-4"}>*/}
                    {/*      <div className={"box-header"}>*/}
                    {/*          <Row className={"g-0 align-items-center"}>*/}
                    {/*              <Col xs={8}>*/}
                    {/*                  <h4>Business Details</h4>*/}
                    {/*              </Col>*/}
                    {/*          </Row>*/}
                    {/*      </div>*/}
                    {/*      <div className={"box-body"}>*/}
                    {/*          <ul className={"list-unstyled"}>*/}
                    {/*              <li>*/}
                    {/*                  <span>Name</span>*/}
                    {/*                  <span>{data.restaurant.name}</span>*/}
                    {/*              </li>*/}
                    {/*              <li>*/}
                    {/*                  <span>address</span>*/}
                    {/*                  <span className="address-details">*/}
                    {/*  {data.restaurant.address}*/}
                    {/*</span>*/}
                    {/*              </li>*/}
                    {/*          </ul>*/}
                    {/*      </div>*/}
                    {/*  </div>*/}
                </Col>

                {/* order detail for customer*/}
                {customerOrderData ? customerOrderData.map((dt) => {
                    return (<Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className={"BoxCustom OrderSummary mb-4"}>
                            <div className={"box-header"}>
                                <Row className={"g-0 align-items-center"}>
                                    <Col xs={8}>
                                        <h4>Order Deatils</h4>
                                    </Col>
                                </Row>
                            </div>
                            <hr className={"mb-3"}/>
                            <div className={"box-body"}>
                                <ul className={"list-unstyled cusrtomer_details"}>
                                    <li>
                                            <span
                                                style={{fontWeight: "600", fontStyle: "bold"}}>Restaurant Name :</span>
                                        <span>{dt && dt?.restaurant ? dt?.restaurant?.name : "N/A"}</span>
                                    </li>

                                    <li>
                                        <span style={{fontWeight: "600", fontStyle: "bold"}}>Created At :</span>
                                        <span>{dt ? moment.utc(dt.created_at).fromNow() : "N/A"}</span>
                                    </li>
                                    <li>
                                        <span style={{fontWeight: "600", fontStyle: "bold"}}>status :</span>
                                        <span>{dt ? dt.status : "N/A"}</span>
                                    </li>
                                    <li>
                                        <span style={{fontWeight: "600", fontStyle: "bold"}}>Sub Total :</span>
                                        <span>{dt ? dt.sub_total : "N/A"}</span>
                                    </li>
                                    <li>
                                        <span style={{fontWeight: "600", fontStyle: "bold"}}>Tax Price :</span>
                                        <span>{dt ? dt.tax_price : "N/A"}</span>
                                    </li>
                                    <li>
                                        <span style={{fontWeight: "600", fontStyle: "bold"}}>Total :</span>
                                        <span>{dt ? dt.total : "N/A"}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>)
                }) : "Hello"}
            </Row>
            <ThemeModal
                show={frontPic}
                onHide={() => setFrontPic(false)}
                size={"md"}
                className={'front_pic'}
                title={"Front Picture"}
                content={<FrontPic/>}
            />
            <ThemeModal
                show={backPic}
                onHide={() => setBackPic(false)}
                size={"md"}
                className={'back_pic'}
                title={"Back Picture"}
                content={<BackPic/>}
            />
        </div>);
}

export default CustomerDetails;
