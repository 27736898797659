import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import {Editor} from "react-draft-wysiwyg";
import { CreatePage, CreateFaqs } from "../../services/pages.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

function FaqAdd() {
  let history = useHistory();

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onModalSubmit = async (data) => {
    await CreateFaqs({
      question: data.question,
      answer: data.answer,
    })
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          history.push("/Faqs");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  return (
    <div className={"PageAdd"}>
      <h3 className="page-heading">Add New FAQs</h3>
      <hr />
      <Form onSubmit={handleSubmit(onModalSubmit)}>
        <div className={"PageAddForm mt-3"}>
          <div className="">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Question</Form.Label>
                  <Form.Control
                    placeholder="Page Title"
                    {...register("question", {
                      required: {
                        value: "required",
                        message: "Page Title is required",
                      },
                      minLength: {
                        value: 5,
                        message: "max length is 5",
                      },
                      maxLength: {
                        value: 255,
                        message: "max length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className={""}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="content">
                  <Form.Label>Answer</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      {...register("answer", {
                        required: {
                          value: "required",
                          message: "Page Content is required",
                        },
                      })}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data=""
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("answer", data);
                        // console.log( { event, editor, data } );
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                    />
                    {errors.description && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.description.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  value={"Submit"}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default FaqAdd;
