import { apiUrl } from "../utils/constants";
import { GET, POST, PUT, DELETE } from "./api.service.wrapper";
import { GetAuthUser } from "../services/auth/auth.service";

export const GetCategoryDropdown = async (
  page = "",
  perPage = "",
  keyword = ""
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }
  const response = await GET(
    `${apiUrl.restaurant}/${
      GetAuthUser()?.restaurant.id
    }/menu_category_dropdown`,
    params
  );
  return response;
};

export const GetCategories = async (page = "", perPage = "", keyword = "") => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  const response = await GET(
    `${apiUrl.restaurant}/${GetAuthUser()?.restaurant.id}/menu_category_flat`,
    params
  );
  return response;
};

export const GetRestaurantCategories = async (page = "", perPage = "", keyword = "") => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  const response = await GET(
    `${apiUrl.category}`,
    params
  );
  return response;
};


export const GetRestaurantCategoriesExclude = async (id = "", page = "", perPage = "", keyword = "") => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  const response = await GET(
    `${apiUrl.restaurant}/${id}/menu_category_wo_selected`,
    params
  );
  return response;
};

export const GetRestaurant = async (page = "", perPage = "", keyword = "") => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }

  const response = await GET(apiUrl.restaurant, params);
  return response;
};


export const GetRestaurantById = async (id) => {
  let response = "";
  response = await GET(apiUrl.restaurant + "/" + id);
  return response;
};

export const GetCategoryById = async (id) => {
  let response = "";
  response = await GET(apiUrl.categories + "/" + id);
  return response;
};

export const CreateRestaurant = async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await POST(`${apiUrl.restaurant}`, data, config);
  return response;
};

export const CreateCategory = async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await POST(
    `${apiUrl.restaurant}/${GetAuthUser()?.restaurant.id}/add_menu`,
    data,
    config
  );
  return response;
};

export const CreateRestaurantCategory = async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await POST(
    `${apiUrl.category}`,
    data,
    config
  );
  return response;
};

export const CreateCategoryItem = async (data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await POST(
    apiUrl.menu + `/add_menu_items`,
    data,
    config
  );
  return response;
};

export const UpdateRestaurant = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(apiUrl.restaurant, id, data, config);
  return response;
};

export const UpdateCategory = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(apiUrl.menu, id, data, config);
  return response;
};

export const UpdateRestaurantCategory = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(`${apiUrl.category}`, id, data, config);
  return response;
};

export const UpdateCategoryItem = async (id, data) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const response = await PUT(apiUrl.menu_ite_update, id, data, config);
  return response;
};

export const DeleteRestaurant = async (id) => {
  const response = await DELETE(apiUrl.restaurant, id);
  return response;
};

export const DeleteCategory = async (id) => {
  const response = await DELETE(apiUrl.menu, id);
  return response;
};

export const DeleteRestaurantCategory = async (id) => {
  const response = await DELETE(`${apiUrl.category}`, id);
  return response;
};

export const DeleteCategoryItem = async (id) => {
  const response = await DELETE(apiUrl.menu_ite_update, id);
  return response;
};

export const GetMenuItem = async (
  page = "",
  perPage = "",
  keyword = ""
) => {
  let params = {};

  if (page) {
    params["page"] = page;
  }

  if (perPage) {
    params["per-page"] = perPage;
  }

  if (keyword) {
    params["keyword"] = keyword;
  }
  params["restaurant"] = GetAuthUser()?.restaurant.id;
  const response = await GET(apiUrl.menu_item, params);
  return response;
};
