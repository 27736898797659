import React, { useState, useEffect } from "react";
import {
  AddIcon,
  AddIconOutline,
  DeleteRed,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import {
  Col,
  Row,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { UploadIcon } from "../../components/SvgIcons";
import {
  CreateRestaurantCategory,
  DeleteRestaurantCategory,
  GetRestaurantCategories,
  UpdateRestaurantCategory,
} from "../../services/categories.service";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

function ResturantCategory() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [file, setFileData] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    getCategories();
  }, [page, perPage, keyword]);

  useEffect(async () => {
    if (!modalShow && !editModalShow) {
      setFileData(false);
      setEditModalData([]);
      reset();
    }
  }, [modalShow, editModalShow]);

  const getCategories = async () => {
    setLoading(true);
    await GetRestaurantCategories(page, perPage, keyword)
      .then((result) => {
        if (result.success) {
          if (result.data.data) {
            console.log("result.data.data: ", result.data.data)
            const rowData = [];
            setPage(result.data.meta.current_page);
            setTotalRows(result.data.meta.total);
            result.data.data.map((dt, index) => {
              rowData.push({
                Id: dt.id,
                Index: ++index,
                CategoryName: dt.name,
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        return toast.error(error);
      });
  };

  const onCatDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete the selected Category?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await DeleteRestaurantCategory(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getCategories();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const statusCheck = (status) => {
    return (
      <div
        className={
          "status-badge status-" +
          constants.CATEGORYSTATUSKEY[status].toLowerCase()
        }
      >
        {constants.CATEGORYSTATUSKEY[status]}
      </div>
    );
  };

  const catActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item
          as="button"
          onClick={async () => {
            setEditModalShow(true);
            setEditModalData(data.Id);
            setValue("id", data.Id);
            setValue("catName", data.CategoryName);
            setValue("CatStatus", data.CategoryStatus);
            setValue("catNameAr", data?.CategoryNameAr);
            setValue("catNameCn", data?.CategoryNameCn);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onCatDelete(data.Id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    {
      id: "Name",
      name: "Name",
      selector: (rowData) => rowData.CategoryName,
    },
    {
      id: "action",
      name: "Action",
      cell: (rowData) => catActions(rowData),
    },
  ];

  const onModalSubmit = async (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", data.catName);

    await CreateRestaurantCategory(formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          getCategories();
          setModalShow(false);
          reset();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onEditModalSubmit = async (data) => {
    setLoading(true);
    let id = data.id;
    let formData = new FormData();
    formData.append("name", data.catName);

    await UpdateRestaurantCategory(id, formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          getCategories();
          setEditModalShow(false);
          reset();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  /*
   *
   *  form elements inside popup
   *
   * */

  const ModalForm = () => {
    return (
      <div className={"modalBodyContent"}>
        <Form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onModalSubmit)}
        >
          <Row>
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row className={"mb-3 justify-content-center align-items-center"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group controlId="formFile" onChange={(e) => handleChange(e)}
                                                className="custom-file-upload mb-0">
                                        <Form.Label className={"toggleFileUpload mb-0"}>
                                            <UploadIcon/>
                                            Upload Image
                                        </Form.Label>
                                        <Form.Control type="file" {...register('media')} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations mb-0"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img src={file} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col> */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3" controlId="newCatName">
                {/*En*/}
                <Form.Label>
                  Name<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Category Name"
                  id={"formCategoryName"}
                  name="catName"
                  autoFocus
                  MaxLength="25"
                  {...register("catName", {
                    onChange: (e) => {
                      setValue("catName", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Category Name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min Length is 3",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type="text"
                />
                {errors.catName && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catName.message}
                  </Form.Text>
                )}

                {/*Ar*/}
                {/* <Form.Label>Category Name - AR</Form.Label>
                                <Form.Control
                                    placeholder="فئة نام"
                                    id={"formCategoryName"}
                                    name="catNameAr"
                                    {...register('catNameAr', {
                                        onChange: (e) => {
                                            setValue('catNameAr', e.target.value)
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                /> */}

                {/*CN*/}
                {/* <Form.Label>Category Name - CN</Form.Label>
                                <Form.Control
                                    placeholder="分类名称"
                                    id={"formCategoryName"}
                                    name="catNameCn"
                                    {...register('catNameCn', {
                                        onChange: (e) => {
                                            setValue('catNameCn', e.target.value)
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                /> */}
              </Form.Group>
            </Col>
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="formCatStatus">
                                <Form.Label>Category Status<small style={{color: "red"}}>*</small></Form.Label>
                                <Form.Select
                                    aria-label="Category Status"
                                    name="CatStatus"
                                    {...register("CatStatus", {
                                            onChange: (e) => {
                                                setValue('CatStatus', e.target.value)
                                            },
                                            required: {
                                                value: "required",
                                                message: "Category Status is required"
                                            }
                                        }
                                    )}>
                                    <option selected value="">Select Category Status</option>
                                    {
                                        constants.CATEGORYSTATUS ?
                                            Object.entries(constants.CATEGORYSTATUS).map(item => {
                                                return (<option key={item[1]} value={item[1]}>{item[0]}</option>);
                                            }) : ''
                                    }
                                </Form.Select>
                                {errors.CatStatus && <Form.Text
                                    className="text-muted validationText hasError">{errors.CatStatus.message}</Form.Text>}
                            </Form.Group>
                        </Col> */}
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <input
                type={"submit"}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Submit"}
                disabled={loading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const EditModalForm = () => {
    return (
      <div className={"modalBodyContent"}>
        <Form
          className={"form-updateCategory"}
          onSubmit={handleSubmit(onEditModalSubmit)}
        >
          <Form.Control {...register("id")} type="hidden" />
          <Row>
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row className={"mb-3 justify-content-center align-items-center"}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group onChange={(e) => handleChange(e)} controlId="formFile"
                                                className="custom-file-upload mb-0">
                                        <Form.Label className={"toggleFileUpload mb-0"}>
                                            <UploadIcon/>
                                            Upload Image
                                        </Form.Label>
                                        <Form.Control type="file" {...register('media')}/>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations mb-0"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img src={file} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col> */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3" controlId="newCatName">
                <Form.Label>
                  Name<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Category Name"
                  autoFocus
                  {...register("catName", {
                    onChange: (e) => {
                      setValue("catName", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Category Name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min Length is 3",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max Length is 255",
                    },
                  })}
                  type="text"
                />
                {errors.catName && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catName.message}
                  </Form.Text>
                )}

                {/*Ar */}
                {/* <Form.Label>Category Name - AR</Form.Label>
                                <Form.Control
                                    placeholder="فئة نام"
                                    id={"formCategoryName"}
                                    name="catNameAr"
                                    {...register('catNameAr', {
                                        onChange: (e) => {
                                            setValue('catNameAr', e.target.value)
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                /> */}
                {/*CN*/}
                {/* <Form.Label>Category Name - CN</Form.Label>
                                <Form.Control
                                    placeholder="分类名称"
                                    id={"formCategoryName"}
                                    name="catNameCn"
                                    {...register('catNameCn', {
                                        onChange: (e) => {
                                            setValue('catNameCn', e.target.value)
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        },
                                    })} type="text"
                                /> */}
              </Form.Group>
            </Col>
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Group className="mb-3" controlId="formCatStatus">
                                <Form.Label>Category Status</Form.Label>
                                <Form.Select aria-label="Category Status"
                                             {...register("CatStatus", {
                                                     onChange: (e) => {
                                                         setValue('CatStatus', e.target.value)
                                                     },
                                                     required: {
                                                         value: "required",
                                                         message: "Category Status is required"
                                                     }
                                                 }
                                             )}>
                                    <option value="">Select Category Status</option>
                                    {
                                        constants.CATEGORYSTATUS ?
                                            Object.entries(constants.CATEGORYSTATUS).map(item => {
                                                return (<option key={item[1]} value={item[1]}>{item[0]}</option>);
                                            }) : ''
                                    }
                                </Form.Select>
                                {errors.CatStatus && <Form.Text
                                    className="text-muted validationText hasError">{errors.CatStatus.message}</Form.Text>}
                            </Form.Group>
                        </Col> */}
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <input
                type={"submit"}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Save"}
                disabled={loading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className={"manageCategory"}>
      <h3 className="page-heading">Manage Business Category</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 1
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button
                  className={"btn btn-green-theme h40 w-100"}
                  onClick={() => setModalShow(true)}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className={"mt-3"}>
        <div className="theme-dataGrid products-dataGrid">
          <DataTable
            columns={columns}
            data={rows}
            progressPending={loading}
            customStyles={customStyles}
            selectableRows={false}
            striped
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(currentRowsPerPage) => {
              setPerPage(currentRowsPerPage);
            }}
            progressComponent={
              <BarLoader
                color={"#12726C"}
                loading={loading}
                css={"marginTop: 10px"}
                height={"4"}
                width={"100%"}
              />
            }
          />
        </div>
      </div>
      {/* Category Popup*/}
      <ThemeModal
        title={"Add New Category"}
        size={"md"}
        content={<ModalForm />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ThemeModal
        title={"Edit Category"}
        size={"md"}
        content={<EditModalForm />}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
    </div>
  );
}

export default ResturantCategory;
