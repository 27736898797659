import { GET } from "../api.service.wrapper";
import { apiUrl } from "../../utils/constants";

export const PagesApi = async (slug) => {
  const response = await GET(apiUrl.pages, { slug: slug });
  return response;
};

export const SinglePageApi = async (slug) => {
  const response = await GET(apiUrl.pages + "/" + slug);
  return response;
};

export const SingleFaqApi = async (id) => {
  const response = await GET(apiUrl.faqs + "/" + id);
  return response;
};