import React, { useState, useEffect } from "react";
import {
  AddIcon,
  AddIconOutline,
  DeleteRed,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import {
  Col,
  Row,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { UploadIcon } from "../../components/SvgIcons";
import {
  CreateRestaurant,
  DeleteRestaurant,
  GetRestaurant,
  UpdateRestaurant,
} from "../../services/categories.service";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import TimeKeeper from "react-timekeeper";
import moment from "moment";
import { Country } from "./Country";
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import { getMonth, getYear } from "date-fns";
import Select from "react-select";
import dummyQRCode from "../../assets/images/QR.jpg"
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import {Redirect, Route} from "react-router-dom";
import ReactStars from "react-rating-stars-component";

// faizan
function ResturantItem() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [file, setFileData] = useState(false);

  const [destinationFocus, setDestinationFocus] = useState(false);
  const [addressSource, setAddressSource] = useState("");
  const [addressComponents, setAddressComponents] = useState({})
  const [latLng, setLatLng] = useState({})
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [utcOpeningTime, setUTCOpeningTime] = useState("");
  const [utcClosingTime, setUTCClosingTime] = useState("");
  const [rowsCategories, setRowsCategories] = useState([
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'chocolate', label: 'Chocolate' },
  ]
  );

  console.log("openingTime",openingTime)

  const onOpeningTimeChange = (options) => {
    let time = moment(options.hour + ":" + options.minute + ":00" + " " + options.meridiem, ["h:mm A"]).format("HH:mm:ss");
    // let full_start_date = moment(time, "HH:mm:ss").format("HH:mm:ssZ");
    // const utc_start_time = moment.utc(full_start_date, "HH:mm:ssZ").format("HH:mm:ssZ")
    setOpeningTime(time)
    // setUTCOpeningTime(utc_start_time)
  }

  const onClosingTimeChange = (options) => {
    let time = moment(options.hour + ":" + options.minute + ":00" + " " + options.meridiem, ["h:mm A"]).format("HH:mm:ss");
    // let full_end_date = moment(time, "HH:mm:ss").format("HH:mm:ssZ");
    // const utc_end_time = moment.utc(full_end_date, "HH:mm:ssZ").format("HH:mm:ssZ")
    setClosingTime(time)
    // setUTCClosingTime(utc_end_time)
  }

  const handleAddressChange = (address) => {
    console.log("address: ", address)
    setAddressSource(address)
  };
  
  const handleAddressSelect = (address) => {
    console.log("address selected: ", address)
    geocodeByAddress(address)
        .then(results => {
           getLatLng(results[0])
                .then(latLng => {
                    setAddressSource(address)
                    console.log("results: ", results)
                    let countryObj = results.length > 0 ? results[0]?.address_components.filter((val)=>val.types.includes('country')) :{}
                    let cityObj = results.length > 0 ? results[0]?.address_components.filter((val)=>val.types.includes("locality")) :{}
                    let stateObj = results.length > 0 ? results[0]?.address_components.filter((val)=>val.types.includes("administrative_area_level_1")) :{}
                    let addressComponents = {
                      'country': countryObj['long_name'],
                      'city': cityObj['long_name'],
                      'state': stateObj['long_name'],
                    }
                    setAddressComponents(addressComponents)
                    setLatLng(latLng)
            })
        })

        .catch(error => console.error('Error', error));
    };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    shouldFocusError: true,
    focus: {
      onSubmitError: false, // focus on the error input on submit
      onInputMount: false, // auto focus when first input mount
    },
  });

  useEffect(() => {
    getRestaurants();
  }, [page, perPage, keyword]);

  useEffect(async () => {
    if (!modalShow && !editModalShow) {
      setFileData(false);
      setEditModalData([]);
      reset();
    }
  }, [modalShow, editModalShow]);

  const [image, setimage] = useState(null);

  const getRestaurants = async () => {
    setLoading(true);
    await GetRestaurant(page, perPage, keyword)
      .then((result) => {
        if (result.success) {
          if (result.data.data) {
            const rowData = [];
            setPage(result.data.meta.current_page);
            setTotalRows(result.data.meta.total);
            result.data.data.map((dt, index) => {
              setFileData(dt.image);
              const current = new Date();

              let start_time = moment(
                `${current.getFullYear()}-${current.getMonth() + 1
                }-${current.getDate()}` +
                "T" +
                dt.opening_time +
                "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              let end_time = moment(
                `${current.getFullYear()}-${current.getMonth() + 1
                }-${current.getDate()}` +
                "T" +
                dt.closing_time +
                "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              rowData.push({
                id: dt?.id,
                // Index: ++index,
                CategoryImg: dt?.image || constants.IMAGENOTFOUND,
                CategoryName: dt.name,
                CategoryEmail: dt.email,
                OpeningTime: start_time,
                ClosingTime: end_time,
                resTiming: start_time + "-" + end_time,
                cataddress: dt.address,
                catcity: dt.city,
                catcountry: dt.country,
                catstate: dt.state,
                email: dt.owner,
                rating: dt.rating
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onCatDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete the selected Category?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await DeleteRestaurant(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getRestaurants();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const statusCheck = (status) => {
    return (
      <div
        className={
          "status-badge status-" +
          constants.CATEGORYSTATUSKEY[status].toLowerCase()
        }
      >
        {constants.CATEGORYSTATUSKEY[status]}
      </div>
    );
  };

  const catActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >

        <Dropdown.Item
          as="button"
          onClick={async () => {
            history.push( `/resturants/${data.id}/edit/`);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onCatDelete(data.id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };  

  const columns = [
    {
      id: "CategoryImg",
      name: "Item Image",
      selector: (rowData) => rowData.oid,
      format: (rowData) => (
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = constants.IMAGENOTFOUND;
          }}
          src={rowData.CategoryImg}
          className={"img-table img-thumbnail"}
          alt={"Image"}
        />
      ),
    },
    {
      id: "CategoryName",
      name: "Name",
      selector: (rowData) => rowData.CategoryName,
    },
    {
      id: "CategoryEmail",
      name: "Email",
      selector: (rowData) => rowData.CategoryEmail,
    },
    {
      id: "OpeningTime",
      name: "Timings",
      selector: (rowData) => rowData.resTiming,
    },
    {
      id: "RestaurantRating",
      name: "Rating",
      // selector: (rowData) => rowData.rating,
      cell: (rowData) => {
        return (
            <div className="start-date">
            {
              <ReactStars
              count={5}
              onChange={ratingChanged}
              size={20}
              value={rowData.rating}
              isHalf={true}
              edit={false}
              activeColor="#ffd700"
            />}
            </div>
        );
      }
    },
    {
      id: "action",
      name: "Action",
      cell: (rowData) => catActions(rowData),
    },
  ];

  const onEditModalSubmit = async (data) => {
    let optime = moment(openingTime, ["h:mm A"]).format("HH:mm:ss");
    let full_start_date = moment(optime, "HH:mm:ss").format("HH:mm:ssZ");
    const utc_start_time = moment.utc(full_start_date, "HH:mm:ssZ").format("HH:mm:ssZ")

    let clostime = moment(closingTime, ["h:mm A"]).format("HH:mm:ss");
    let end_start_date = moment(clostime, "HH:mm:ss").format("HH:mm:ssZ");
    const utc_end_time = moment.utc(end_start_date, "HH:mm:ssZ").format("HH:mm:ssZ")
    
    let id = data.id;
    let formData = new FormData();

    formData.append("name", data.catName);
    formData.append("address", addressSource);
    formData.append("city", addressComponents['city']);
    formData.append("country", addressComponents['country']);
    formData.append("state", addressComponents['state']);
    formData.append("latitude", latLng['lat']);
    formData.append("longitude", latLng['lng']);
    formData.append("opening_time", utc_start_time);
    formData.append("closing_time", utc_end_time);
    formData.append("categories", []);
    formData.append("email", data.email);

    if (data.media[0]) {
      formData.append("image", data.media[0]);
    }

    await UpdateRestaurant(id, formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          getRestaurants();
          reset();
          setEditModalShow(false);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setimage(false);
  };


  const EditModalForm = () => {
    const [time, setTime] = useState("12:34pm");
    const [showTime, setShowTime] = useState(true);
    const [newtimee, setNewTime] = useState("12:34pm");
    const [showNewTime, setShowNewTime] = useState(true);



    return (
      <div className={"modalBodyContent"}>
        <Form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onEditModalSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      accept=".jpg, .jpeg, .png, .jfif"
                      name="media"
                      type="file"
                      {...register("media")}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ul className={"list-inline list-variations mb-0"}>
                    {image ? (
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        <img src={image} onError={e=>e.target.src = file} width="50%" height="50%" />
                      </li>
                    ) : (
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        <img src={file} onError={e=>e.target.src = file} width="50%" height="50%" />
                      </li>
                    )}
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-1" controlId="newCatName">
                {/*En*/}
                <Form.Label>
                  Name<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Name"
                  name="catName"
                  minLength={3}
                  maxLength={35}
                  {...register("catName", {
                    onChange: (e) => {
                      e.preventDefault();
                      setValue("catName", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Name is required"
                    },
                   
                  })}
                  type="text"
                />
                {errors.catName && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catName.message}
                  </Form.Text>
                )}

                <Form.Label>
                  Email<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Enter email"
                  id={"formSignUpEmail"}
                  {...register("email", {
                    required: {
                      value: "required",
                      message: "Email is required",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type="text"
                />
                {errors.email && (
                  <span className={"validation-error pr-5"} role="alert">
                    {errors.email.message}
                  </span>
                )}

                {/*Ar*/}
                
                <Form.Label
                for="location" >
                    Address<small style={{ color: "red" }}>*</small>
                  </Form.Label>

                  <PlacesAutocomplete
                      value={addressSource}
                      onChange={(address) => handleAddressChange(address)}
                      onSelect={(address) => handleAddressSelect(address)}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input
                          id="location"
                        {...getInputProps({
                            placeholder: 'Search Source',
                            className: 'form-control location-search-input',
                        })}
                        onFocus={()=>{setDestinationFocus(true)}}
                        onBlur={()=>{setDestinationFocus(false)}}
                        />
                        <div className={`autocomplete-dropdown-container ${destinationFocus ? "d-block" : "d-none"}`} style={{'backgroundColor':'#fff', 'border': '1px solid rgba(2,2,2,0.1)', 'padding': '5px', 'borderRadius': '5px'}}>
                        {loading && <div className='suggestion-item'>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer', borderBottom: '1px solid rgba(2,2,2,0.1)', padding: '5px 0' };
                            return (
                            <div
                                {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                                })}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                            );
                        })}
                        </div>
                    </div>
                    )}
                  </PlacesAutocomplete>                    
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3" >
              <Form.Label>
                Category<small style={{ color: "red" }}>*</small>
              </Form.Label>

              <Select
                defaultValue={rowsCategories[0]}
                onChange={(e) => console.log(e,"e")}
                className='react-select-container'
                classNamePrefix="react-select"
                options={rowsCategories}
                isMulti
              />
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3" controlId="openingtime">
                <Form.Label>
                  Opening Time<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <div>
                  <TimePicker
                    timeMode="12"
                    theme="classic"
                    onTimeChange={(options) => onOpeningTimeChange(options)}
                    className="w-100"
                    time={openingTime}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3" controlId="closingtime">
                <Form.Label>
                  CLosing Time<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <div>
                  <TimePicker
                    timeMode="12"
                    theme="classic"
                    onTimeChange={(options) => onClosingTimeChange(options)}
                    className="w-100"
                    time={closingTime}
                  />
                </div>

              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <input
                type={"submit"}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Submit"}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className={"manageCategory"}>
      <h3 className="page-heading">Manage Business</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 1
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <NavLink to="/resturants/add-resturants">
                  <Button
                    className={"btn btn-green-theme h40 w-100"}
                  // onClick={() => setModalShow(true)}
                  >
                    <AddIconOutline /> Add New
                  </Button>
                </NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className={"mt-3"}>
        <div className="theme-dataGrid products-dataGrid">
          <DataTable
            columns={columns}
            data={rows}
            progressPending={loading}
            customStyles={customStyles}
            selectableRows={false}
            striped
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(currentRowsPerPage) => {
              setPerPage(currentRowsPerPage);
            }}
            progressComponent={
              <BarLoader
                color={"#12726C"}
                loading={loading}
                css={"marginTop: 10px"}
                height={"4"}
                width={"100%"}
              />
            }
          />
        </div>
      </div>
      {/* Category Popup*/}

   

      <ThemeModal
        title={"Edit Business"}
        size={"md"}
        content={<EditModalForm />}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
    </div>
  );
}

export default ResturantItem;
