import React, {useEffect, useState} from 'react'
import {constants} from "../utils/constants";
import {GetUserRole} from "../services/auth/auth.service"
import {Admin, Restaurant, Csa} from "./SideMenu/index"

function SideBar() {
    const [sideBar, setSideBar] = useState();

    useEffect(() => {
        let role = GetUserRole();
        console.log(role)
        if(role == constants.ROLES.ROLE_ADMIN){
            setSideBar(<Admin />);
        }
        else if(role == constants.ROLES.RESTAURANT_OWNER){
            setSideBar(<Restaurant />);
        }
        else if(role == constants.ROLES.ROLE_CSA){
            setSideBar(<Csa/>);
        }
    }, []);

    return (
        <div className="d-flex flex-column flex-shrink-0 p-2 h-100 sideBar">
            {sideBar}
        </div>
    )
}

export default SideBar
