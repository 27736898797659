import React, { useState, useEffect } from "react";
import {
  AddIcon,
  AddIconOutline,
  DeleteRed,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import {
  Col,
  Row,
  Form,
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import ThemeModal from "../../components/ThemeModal";
import { useForm, Controller } from "react-hook-form";
import { UploadIcon } from "../../components/SvgIcons";
import {
  CreateCategoryItem,
  DeleteCategoryItem,
  GetCategories,
  UpdateCategoryItem,
  GetCategoryDropdown,
  GetMenuItem,
} from "../../services/categories.service";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { MoneyFormat } from "../../utils/helpers";
import Select from "react-select"

// faizan
function ManageItem() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalData, setEditModalData] = useState([]);
  const [file, setFileData] = useState(false);
  const [rowsCategories, setRowsCategories] = useState();
  const [categoryId, setCategoryId] = useState("0");
  const [disableCateogies, setDisableCateogies] = useState(false);
  const [defaultRowsCategories, setdefaultRowsCategories] = useState([]);
  
  const [selectedCats, setSelectedCats] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });

  useEffect(() => {
    getCategoryDropdown();
  }, []);

  useEffect(() => {
    getMenuitem();
  }, [page, perPage, keyword]);

  useEffect(async () => {
    if (!modalShow && !editModalShow) {
      setFileData(false);
      setEditModalData([]);
      reset();
    }
  }, [modalShow, editModalShow]);

  const getCategoryDropdown = async () => {
    setLoading(true);
    await GetCategoryDropdown(page, perPage, keyword)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.name || "-",
              });
           
            });
            setRowsCategories(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("idher: ", error);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getMenuitem = async () => {
    setLoading(true);
    await GetMenuItem(page, perPage, keyword)
      .then((result) => {
        console.log(result);
        if (result.success) {
          if (result.data.data) {
            const rowData = [];
            setPage(result.data.meta.current_page);
            setTotalRows(result.data.meta.total);
            console.log(result.data.data, "mydata")

            result.data.data.map((dt) => {
              console.log(dt, "category")
              
              rowData.push({
                id: dt?.id,
                // Index: ++index,
                item_name: dt.name,
                price: dt.price,
                image: dt.image || constants.IMAGENOTFOUND,
                description: dt.description,
                categories: dt.menu_category,
                tax_percentage: dt.tax_percentage,
                age: dt.above_age_limit,
                isActive: dt.is_active
                // CategoryNameAr: nameAr,
                // CategoryNameCn: nameCn,
              });
              
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onCatDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete the selected Category?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await DeleteCategoryItem(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getMenuitem();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const statusCheck = (status) => {
    return (
      <div
        className={
          "status-badge status-" +
          constants.CATEGORYSTATUSKEY[status].toLowerCase()
        }
      >
        {constants.CATEGORYSTATUSKEY[status]}
      </div>
    );
  };

  const catActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        {/* <Dropdown.Item as="button">
          <Link
                        to={{
                            pathname: "/sub-category/" + data.Id,
                            state: {category_name: data.CategoryName},
                        }}>
                        View Sub Categories
                    </Link>
        </Dropdown.Item> */}

        <Dropdown.Item
          as="button"
          onClick={async () => {
            setEditModalShow(true);
            setEditModalData(data.id);

            const rowDataMenuCategory = [];

            data.categories.map((data) => {
              rowDataMenuCategory.push({
                value: data.id,
                label: data.name,
              });
            });
            
            reset({
              categories: rowDataMenuCategory
            })
            setdefaultRowsCategories(rowDataMenuCategory);
            // setValue("category", rowDataMenuCategory);
            setValue("id", data.id);
            setValue("catName", data.item_name);
            setValue("catdescriptions", data.description);
            setValue("catPrice", data.price);
            setValue("cattax", data.tax_percentage);
            setValue("media", data.media);
            setValue("CatStatus", data.CatStatus);
            setValue("age", data.age);
            setValue("isActive", data.isActive);
            setFileData(data.image)
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onCatDelete(data.id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const MyPrice = () => {
    const price = [{ id: 1, price: "$100" }];

    return (
      <div className="price">
        {price.map((user) => (
          <div className="user">{user.price}</div>
        ))}
      </div>
    );
  };
  const columns = [
    {
      id: "Image",
      name: "Item Image",
      selector: (rowData) => rowData.oid,
      format: (rowData) => (
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = constants.IMAGENOTFOUND;
          }}
          src={rowData.image}
          className={"img-table img-thumbnail"}
          alt={"Image"}
        />
      ),
    },
    {
      id: "item_name",
      name: "Item Name",
      selector: (rowData) => rowData.item_name,
    },
    {
      id: "price",
      name: "Price",
      selector: (rowData) => `$${rowData.price}`,
    },
    {
      id: "category",
      name: "Categories",
      selector: (rowData) => rowData.categories.map((result) => {
        return `${result.name}`
      }, "").join(', '),
    },
    {
      id: "action",
      name: "Action",
      cell: (rowData) => catActions(rowData),
    },
  ];

  const onModalSubmit = async (data) => {
    let formData = new FormData();
    console.table(data);

    formData.append("menu_item_name", data.catName);
    formData.append("description", data.catdescriptions);
    formData.append("price", data.catPrice);
    formData.append("tax_percentage", data.cattax);
    
    const categoriesValue = []

    data.categories.map((data) => {
      categoriesValue.push(Number(data.value))
    })

    categoriesValue.length > 0 && formData.append("categories", categoriesValue)

    formData.append("above_age_limit", data.age ? "True" : "False");
    formData.append("is_active", data.isActive ? "True" : "False");
    if (data.media[0]) {
      formData.append("image", data.media[0]);
    }

    setLoading(true);
    await CreateCategoryItem(formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          getMenuitem();
          setModalShow(false);
          reset();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onEditModalSubmit = async (data) => {
    setLoading(true);
    // console.log("idher hai: ", data);
    let id = data.id;
    let formData = new FormData();
    formData.append("menu_item_name", data.catName);
    formData.append("description", data.catdescriptions);
    formData.append("price", data.catPrice);
    formData.append("tax_percentage", data.cattax);
    console.log("data.CatStatus: ", data.CatStatus)
    
    const categoriesValue = []

    data.categories.map((data) => {
      categoriesValue.push(Number(data.value))
    })

    categoriesValue.length > 0 && formData.append("categories", categoriesValue)

    formData.append("above_age_limit", data.age ? "True" : "False");
    formData.append("is_active", data.isActive ? "True" : "False");
    if (data.mediaEdit[0]) {
      formData.append("image", data.mediaEdit[0]);
    }

    await UpdateCategoryItem(id, formData)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          getMenuitem();
          reset();
          setEditModalShow(false);
          console.log("ended here");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
      
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    console.log(file,"img url")
  };

  /*
   *
   *  form elements inside popup
   *
   * */

  console.log(file,"file")
  const ModalForm = () => {
    return (
      <div className={"modalBodyContent"}>
        <Form
          encType="multipart/form-data"
          onSubmit={handleSubmit(onModalSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control type="file" {...register("media", {
                      required: {
                        value: false,
                        message: "Menu image is required",
                      },
                    })} />
                    {errors.media && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.media.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ul className={"list-inline list-variations mb-0"}>
                    <li
                      className={
                        "list-inline-item d-flex justify-content-between mb-0"
                      }
                    >
                      <img src={file} width="50%" height="50%" />
                   
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="">
                {/*En*/}
                <Form.Label>
                  Name<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Name"
                  id={"formCategoryName"}
                  name="catName"
                  {...register("catName", {
                    onBlur: (e) => {
                      setValue("catName", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min Length is 3",
                    },
                    maxLength: {
                      value: 35,
                      message: "Max length is 35",
                    },
                  })}
                  type="text"
                />
                {errors.catName && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catName.message}
                  </Form.Text>
                )}

                {/*Ar*/}
                <Form.Label>
                  Description<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Description"
                  id={"formCategoryName"}
                  name="catdescriptions"
                  {...register("catdescriptions", {
                    onChange: (e) => {
                      setValue("catdescriptions", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Description is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Min Length is 3",
                    },
                    maxLength: {
                      value: 255,
                      message: "Max length is 255",
                    },
                  })}
                  type="text"
                />
                {errors.catdescriptions && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catdescriptions.message}
                  </Form.Text>
                )}

                {/*CN*/}
                <Form.Label>
                  Price<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Price"
                  id={"formCategoryName"}
                  name="catPrice"
                  {...register("catPrice", {
                    onChange: (e) => {
                      setValue("catPrice", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Price is required",
                    },
                    pattern: {
                      value: /^[1-9]\d*(\.\d+)?$/,
                      message: "Incorrect price value",
                    },
                  })}
                  type="number"
                />
                {errors.catPrice && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catPrice.message}
                  </Form.Text>
                )}

                <Form.Label>
                  Tax Percentage<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                  placeholder="Tax Percentage"
                  id={"formCategoryName"}
                  name="cattax"
                  {...register("cattax", {
                    onChange: (e) => {
                      setValue("cattax", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Tax Percentage is required",
                    },
                    pattern: {
                      value: /^[1-9]\d*(\.\d+)?$/,
                      message: "Incorrect price value",
                    },
                  })}
                  type="text"
                />
                {errors.cattax && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.cattax.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label>
                Category<small style={{ color: "red" }}>*</small>
              </Form.Label>
              <Controller
                  control={control}
                  name="categories"
                  rules={{
                      required: {
                          value: true,
                          message: "Category is required",
                      },
                  }}
                  render={({field})=>(
                      <Select
                          isDisabled={disableCateogies}
                          {...field}
                          placeholder="Category"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isSearchable={true}
                          options={rowsCategories}
                          isMulti
                      />
                  )}
              />
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label className="mb-1">
                Age<small style={{ color: "red" }}>*</small>
              </Form.Label>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Is this age restricted?"
                {...register("age", {
                })}
              />
              {errors.age && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.age.message}
                </Form.Text>)
              }
            </Col>


            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label className="mb-1">
                Active/Inactive<small style={{ color: "red" }}>*</small>
              </Form.Label>
            <Form.Check
                type="switch"
                id="custom-switch"
                label="Make this item active/inactive"
                {...register("isActive", {
                })}
              />
              {errors.isActive && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.isActive.message}
                </Form.Text>)
              }
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <input
                type={"submit"}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Submit"}
                disabled={loading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const EditModalForm = () => {
    return (
      <div className={"modalBodyContent"}>
        <Form
          className={"form-updateCategory"}
          onSubmit={handleSubmit(onEditModalSubmit)}
        >
          <Form.Control {...register("id")} type="hidden" />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control type="file" {...register("mediaEdit")} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ul className={"list-inline list-variations mb-0"}>
                    <li
                      className={
                        "list-inline-item d-flex justify-content-between mb-0"
                      }
                    >
                      <img src={file} width="50%" height="50%" />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name<small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Control
                
                  minLength={3}
                  maxLength={35}
                  placeholder="Category Name"
                  
                  {...register("catName", {
                    onChange: (e) => {
                      setValue("catName", e.target.value);
                    },
                    required: {
                      value: "required",
                      message: "Category Name is required",
                    },
                    // minLength: {
                    //   value: 3,
                    //   message: "Min Length is 3",
                    // },
                    // maxLength: {
                    //   value: 35,
                    //   message: "Max Length is 35",
                    // },
                  })}
                  type="text"
                />
                {errors.catName && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.catName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {/*Ar */}
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Description"
                id={"formCategoryName"}
                name="catdescriptions"
                {...register("catdescriptions", {
                  onChange: (e) => {
                    setValue("catdescriptions", e.target.value);
                  },
                  required: {
                    value: "required",
                    message: "Description is required",
                  },
                  minLength: {
                    value: 3,
                    message: "Min Length is 3",
                  },
                  maxLength: {
                    value: 255,
                    message: "Max length is 255",
                  },
                })}
                type="text"
              />
              {errors.catdescriptions && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.catdescriptions.message}
                </Form.Text>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {/*CN*/}
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  placeholder="Price"
                  id={"formCategoryName"}
                  name="catPrice"
                  {...register("catPrice", {
                    onChange: (e) => {
                      setValue("catPrice", e.target.value);
                    },
                    maxLength: {
                      value: 35,
                      message: "Max length is 35",
                    },
                  })}
                  type="text"
                />
                {errors.catPrice && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.catPrice.message}
                </Form.Text>
              )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Group className="mb-3">
                <Form.Label>Tax Percentage</Form.Label>
                <Form.Control
                  placeholder="Tax Percentage"
                  id={"formCategoryName"}
                  name="cattax"
                  {...register("cattax", {
                    onChange: (e) => {
                      setValue("cattax", e.target.value);
                    },
                    maxLength: {
                      value: 35,
                      message: "Max length is 35",
                    },
                  })}
                  type="text"
                />
                {errors.cattax && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.cattax.message}
                </Form.Text>
              )}
              </Form.Group>
            </Col>

           <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label>
                Category<small style={{ color: "red" }}>*</small>
              </Form.Label>

              <Controller
                  control={control}
                  name="categories"
                  rules={{
                      required: {
                          value: true,
                          message: "Category is required",
                      },
                  }}
                  render={({field})=>(
                      <Select
                          isDisabled={disableCateogies}
                          {...field}
                          placeholder="Category"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isSearchable={true}
                          options={rowsCategories}
                          isMulti
                      />
                  )}
              />
              
              {errors.menu_category && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.menu_category.message}
                </Form.Text>
              )}
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label className="mb-1">
                Age<small style={{ color: "red" }}>*</small>
              </Form.Label>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Is this age restricted?"
                {...register("age", {
                })}
              />
              {errors.age && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.age.message}
                </Form.Text>)
              }
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Label className="mb-1">
                Active/Inactive<small style={{ color: "red" }}>*</small>
              </Form.Label>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Make this item active/inactive"
                {...register("isActive", {
                })}
              />
              {errors.isActive && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.isActive.message}
                </Form.Text>)
              }
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <input
                type={"submit"}
                className={"btn btn-green-theme w-100 mt-3"}
                value={"Submit"}
                disabled={loading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className={"manageCategory"}>
      <h3 className="page-heading">Manage Menu</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 0
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <Button
                  className={"btn btn-green-theme h40 w-100"}
                  onClick={() => setModalShow(true)}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className={"mt-3"}>
        <div className="theme-dataGrid products-dataGrid">
          <DataTable
            columns={columns}
            data={rows}
            progressPending={loading}
            customStyles={customStyles}
            selectableRows={false}
            striped
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(currentRowsPerPage) => {
              setPerPage(currentRowsPerPage);
            }}
            progressComponent={
              <BarLoader
                color={"#12726C"}
                loading={loading}
                css={"marginTop: 10px"}
                height={"4"}
                width={"100%"}
              />
            }
          />
        </div>
      </div>
      {/* Category Popup*/}
      <ThemeModal
        title={"Add New Item"}
        size={"md"}
        content={<ModalForm />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ThemeModal
        title={"Edit Category"}
        size={"md"}
        content={<EditModalForm />}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
    </div>
  );
}

export default ManageItem;
