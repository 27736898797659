import React, {useRef, useState, useEffect} from 'react'
import {Form, Row, Col, InputGroup, FormControl, Button} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import {useForm} from "react-hook-form";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import {constants} from "../../utils/constants";
import moment from "moment";
import {updateUser} from "../../services/firebase.service";
import {format} from "date-fns";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {FetchCityById, FetchCountries, FetchStateById, ValidateAddress} from "../../services/aramex.service";

function EditVendor(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState('');
    const [cities, setCities] = useState([]);
    const [cityId, setCityId] = useState('');
    const [disableField, setDisableField] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    const {register, watch, reset, setFocus, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur",
    });


    const password = useRef({});
    password.current = watch("password", "");

    useEffect(async () => {
        let id = props.match.params.id;
        await getVendorById(id);
        if (data) {
            reset({
                'full_name': data.full_name,
                'username': data.username,
                'email': data.email,
                'phone': data.phone,
                'dob': data.dob,
                'company_name': data.company_name,
                'business_type': data.business_type,
                'city': data.store_city,
                'country': data.store_country,
                'country_code': data.store_country_code,
                'state': data.store_state,
                'zip_code': data.store_zip_code,
            });
        }
    }, []);

    /*useEffect(() => {
        fetchCountries()
    }, [])*/

    useEffect(() => {
        if (countryId) {
            fetchStates()
            fetchCities()
        }
    }, [countryId])

    //Fetch Countries
    /*const fetchCountries = async () => {
        await FetchCountries().then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let countries = result.data?.Countries;
                    setCountries(countries)
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }*/

    //Fetch States
    const fetchStates = async () => {
        await FetchStateById(countryId).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let states = result.data?.States;
                    setStates(states)
                    setDisableField(false);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    //Fetch Cities
    const fetchCities = async () => {
        await FetchCityById(countryId).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let cities = result.data?.Cities;
                    setCities(cities)

                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getVendorById = async (id) => {
        setDisableField(true);
        await GetUserById(id).then((result) => {
            if (result.status) {
                if (result.data) {
                    setDisableField(false);
                    setCountryId(result.data?.store?.country_code);
                    setStateId(result.data?.store?.state);
                    setCityId(result.data?.store?.city);

                    setData({
                        full_name: result.data.full_name,
                        username: result.data.username,
                        email: result.data.email,
                        phone: result.data.phone,
                        dob: moment.utc(result.data.dob).format('yyyy-MM-DD'),
                        company_name: result.data.company_name,
                        business_type: result.data.business_type,
                        store_name: result.data?.store?.name,
                        store_address: result.data?.store?.address,
                        store_description: result.data?.store?.description,
                        store_city: result.data?.store?.city || cityId,
                        store_country: result.data?.store?.country_code || countryId,
                        store_country_code: result.data?.store?.country_code || countryId,
                        store_state: result.data?.store?.state || stateId,
                        store_zip_code: result.data?.store?.zip_code
                    });
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async data => {
        let id = props.match.params.id;

        let address_data = {
            address: data.store_address.trim(),
            city: data.city.trim(),
            state: data.state.trim(),
            zip_code: data.zip_code.trim(),
            country_code: data.country.trim(),
        }


        let dt = {
            full_name: data.full_name.trim(),
            username: data.username.trim(),
            email: data.email.trim(),
            phone: data.phone.trim(),
            dob: data.dob,
            company_name: data.company_name.trim(),
            business_type: data.business_type.trim(),
            store_name: data.store_name.trim(),
            store_address: data.store_address.trim(),
            store_city: data.city.trim(),
            store_country: data.country.trim(),
            store_country_code: data.country.trim(),
            store_description: data.store_description.trim(),
            store_state: data.state.trim(),
            store_zip_code: data.zip_code.trim(),
            role_id: constants.ROLES.ROLE_VENDOR
        }

        if (data.password && data.password_confirmation) {
            dt.password = data.password;
            dt.password_confirmation = data.password_confirmation;
        }

        await ValidateAddress(address_data).then(async (result) => {
            if (result.status) {
                await UpdateUser(id, dt).then(async (data) => {
                    if (data.status) {
                        await updateUser(data.data).then(() => {
                            toast.success(data.message);
                            history.push('/vendors');
                        })
                    } else {
                        toast.error(data.message);
                    }
                }).catch((error) => {
                    setDisableButton(false)
                    return toast.error(error.response.data.message);
                })
            } else {
                setDisableButton(false)
                return toast.error(result.message[0]);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                setDisableButton(false)
                return toast.error(error.response.status == 403 ? error.response.data.message[0] : error.response.data.message);
            }
        })

    };

    // you can use React.forwardRef to pass the ref too
    const Select = React.forwardRef(({options, onChange, name, label}, ref) => (
        <>
            <Form.Select name={name} ref={ref} onChange={onChange}>
                <option value="">{label}</option>
                {
                    options ?
                        options.map((d) => {
                            return (<option value={d.Code}>{d.Name}</option>);
                        }) : ''
                }
            </Form.Select>
        </>
    ));

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Update Vendor</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        defaultValue={data.full_name}
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        {...register('full_name', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.full_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.full_name.message}</Form.Text>}

                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Username"
                                        id={"formSignUpName"}
                                        defaultValue={data.username}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Username is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "min length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        defaultValue={data.email}
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="email"/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Phone Number"
                                        defaultValue={data.phone}
                                        {...register("phone", {
                                                required: {
                                                    value: "required",
                                                    message: "Phone is required"
                                                },
                                                pattern: {
                                                    value: /^\+[0-9]*$/,
                                                    message: "incorrect pattern +xxxxxxxxxxxx"
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: "max length is 16"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.phone && <Form.Text
                                        className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date Of Birth</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Date Of Birth"
                                        defaultValue={data.dob}
                                        max={format(new Date(), 'yyyy-MM-dd')}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "Date of birth is required"
                                            },
                                        })} type="date"/>
                                    {errors.dob && <Form.Text
                                        className="text-muted validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        defaultValue={data.company_name}
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "Company name is required"
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "min length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        defaultValue={data.business_type}
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "Business type is required"
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "min length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 16"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="store_name">
                                    <Form.Label>Store Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Store Name"
                                        defaultValue={data.store_name}
                                        {...register('store_name', {
                                            required: {
                                                value: "required",
                                                message: "Store name is required"
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "min length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "max length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.store_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.store_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="store_address">
                                    <Form.Label>Store Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Store Address"
                                        defaultValue={data.store_address}
                                        {...register("store_address", {
                                                required: {
                                                    value: "required",
                                                    message: "Address is required"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "min length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.store_address && <Form.Text
                                        className="text-muted validationText hasError">{errors.store_address.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Store Country</Form.Label>

                                    <select class={"form-select"}
                                            {...register("country", {
                                                    required: {
                                                        value: "required",
                                                        message: "Country is required"
                                                    }
                                                }
                                            )}
                                            onChange={e => {
                                                setCountryId(e.target.value);
                                                setDisableField(true);
                                            }}
                                            aria-label="Default Filters"
                                            value={countryId}>
                                        <option disabled={true}>Select Country</option>
                                        <option value="cn">China</option>
                                        {/*{
                                            countries ?
                                                countries.map((country) => {
                                                    //selected={country.Code == countryId}
                                                    return (<option selected={country.Code == countryId ? 'selected' : ''}
                                                                    key={country.Code}
                                                                    value={country.Code}>{country.Name}</option>);
                                                }) : ''
                                        }*/}
                                    </select>

                                    {errors.country && <Form.Text
                                        className="text-muted validationText hasError">{errors.country.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="state">
                                    <Form.Label>Store State</Form.Label>
                                    <select class={"form-select"}

                                                  disabled={disableField}
                                                  {...register("state")}
                                                  onChange={e => {
                                                      setStateId(e.target.value);
                                                  }}
                                                  aria-label="Default Filters"
                                                value={stateId}>
                                        <option value="">Select State</option>
                                        {
                                            states ?
                                                states.map((state) => {
                                                    return (<option selected={state.Name == stateId}
                                                                    key={state.Name}
                                                                    value={state.Name}>{state.Name}</option>);
                                                }) : ''
                                        }
                                    </select>
                                    {errors.state && <Form.Text
                                        className="text-muted validationText hasError">{errors.state.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Store City</Form.Label>

                                    <select class={"form-select"}
                                            autoFocus
                                                  {...register("city", {
                                                          required: {
                                                              value: "required",
                                                              message: "City is required"
                                                          }
                                                      }
                                                  )}
                                                  onChange={e => {
                                                      setCityId(e.target.value);
                                                      setDisableField(true);
                                                  }}
                                                  aria-label="Default Filters" value={cityId}>
                                        <option disabled={true}>Select City</option>
                                        {
                                            cities ?
                                                cities.map((city) => {
                                                    return (<option selected={city == cityId}
                                                                    key={city}
                                                                    value={city}>{city}</option>);
                                                }) : ''
                                        }
                                    </select>
                                    {errors.city && <Form.Text
                                        className="text-muted validationText hasError">{errors.city.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="zip_code">
                                    <Form.Label>Store ZipCode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter ZipCode"
                                        defaultValue={data.store_zip_code}
                                        {...register("zip_code", {
                                                required: {
                                                    value: "required",
                                                    message: "Zip code is required"
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "min length is 3"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "max length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.zip_code && <Form.Text
                                        className="text-muted validationText hasError">{errors.zip_code.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="store_description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={10}
                                        placeholder="Enter Store Description"
                                        defaultValue={data.store_description}
                                        {...register('store_description', {
                                            required: {
                                                value: "required",
                                                message: "Description is required"
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: "max length is 1000"
                                            },
                                        })} type="text"/>
                                    {errors.store_description && <Form.Text
                                        className="text-muted validationText hasError">{errors.store_description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            {...register('password', {
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            onClick={() => setConfirmPass(!confirmPass)}
                                            type={pass ? "password" : "text"}/>
                                        <Button variant="outline-secondary" id="button-addon2"
                                                onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye/> : <FaEyeSlash/>
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text
                                        className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            disabled={confirmPass}
                                            {...register('password_confirmation', {
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" disabled={confirmPass}
                                                onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye/> : <FaEyeSlash/>
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text
                                        className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"}
                                       className={"btn btn-green-theme w-100 mt-3"}
                                       disabled={disableButton}
                                       value={"Update Vendor"}/>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </div>
    )
}

export default EditVendor
