import React, {useState, useEffect} from "react";
import swal from "sweetalert";
import {Link} from "react-router-dom";
import {
    Button,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Row,
} from "react-bootstrap";
import {
    AddIconOutline,
    FilterIcon,
    MenuDots,
    SearchIcon,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import moment from "moment";
import {
    CreateUser,
    DeleteUser,
    BlockUser,
    GetUsers,
    CheckoutUser,
    UpdateUser,
} from "../../services/users.service";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage, GetAuthUser, GetUserRole} from "../../services/auth/auth.service";

let role = GetUserRole();
let user = GetAuthUser();

function Users() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState("");

    useEffect(async () => {
        await getUsers();
    }, [keyword, page, perPage]);

    const getUsers = async () => {
        setLoading(true);
        await GetUsers(keyword, role, page, perPage)
            .then((result) => {
                if (result.success) {
                    if (result.data.data) {
                        const rowData = [];
                        setPage(result.data.meta.current_page);
                        setTotalRows(result.data.meta.total);
                        result.data.data.map((dt, index) => {
                            console.log("visits: ", dt.amount_spent)
                            rowData.push({
                                id: dt?.id,
                                // index: ++index,
                                full_name: dt?.profile?.full_name || "-",
                                is_blocked: dt?.is_blocked,
                                visits: dt?.visits ?? '-',
                                amount_spent: dt.amount_spent != null ? dt.amount_spent.toFixed(2) : "0",
                                email: dt.email || "-",
                                phone: dt?.profile?.phone_number || "-",
                                is_checked_in: dt.is_checked_in || false
                                // created_at: moment(dt.created_at).fromNow(),
                            });
                        });
                        setRows(rowData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                    return toast.error(result.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("err: ", error)
                if (error.response.status == 401) {
                    EmptyLocalStorage();
                    history.push("/");
                } else {
                    return toast.error(error.response.data.message);
                }
            });
    };

    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delte this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeleteUser(id)
                    .then((result) => {
                        if (result.success) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getUsers();
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(error.response.data.message);
                        }
                    });
            }
        });
    };


    const onUserCheckout = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to checkout this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await CheckoutUser(id)
                    .then((result) => {
                        if (result.success) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getUsers();
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(error.response.data.message);
                        }
                    });
            }
        });
    };


    const onUserBlock = (id, is_blocked) => {
        swal({
            title: "Are you sure?",
            text: `Are you sure you want to ${is_blocked ? "unblock" : "block"} this user?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await BlockUser(id)
                    .then((result) => {
                        if (result.success) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getUsers();
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(error.response.data.message);
                        }
                    });
            }
        });
    };

    const onEdit = (data) => {
        history.push("/manage-users/edit-user/" + data.id, {
            data: data,
        });
    };
    let role = GetUserRole();

    const userActions = (data) => {
        return (
            <DropdownButton
                align="end"
                className={"actionDropdown"}
                id="dropdown-item-button"
                title={<MenuDots/>}
            >
                {role == constants.ROLES.RESTAURANT_OWNER ? (
                    <>
                        <Dropdown.Item as="button" onClick={() => onUserBlock(data.id, data.is_blocked)}>
                            {data.is_blocked ? "Unblock" : "Block"}
                        </Dropdown.Item>
                        {data.is_checked_in == true &&
                            (<Dropdown.Item as="button" onClick={() => onUserCheckout(data.id)}>
                                Checkout
                            </Dropdown.Item>)
                        }
                    </>
                ) : (
                    <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>
                        Delete
                    </Dropdown.Item>
                )}
            </DropdownButton>
        );
    };
    const columns = [
        {
            id: "full_name",
            name: "Full Name",
            maxWidth: "160px",
            selector: (rowData) => rowData.full_name,
        },
        {
            id: "email",
            name: "Email",
            selector: (rowData) => rowData.email,
        },
        {
            id: "phone",
            name: "Phone",
            selector: (rowData) => rowData.phone,
        },
        role == 2 && {
            id: 'visits',
            name: 'No. of Visits',
            sortable: true,
            selector: (rowData) => rowData.visits,

        },
        role == 2 && {
            id: 'amountSpent',
            name: 'Amount Spent',
            sortable: true,
            selector: (rowData) => `$${rowData.amount_spent}`,
        },
        {
            id: "action",
            name: "Action",
            cell: (rowData) => userActions(rowData),
        },
    ];

    return (
        <div className={"usersPage"}>
            <h3 className="page-heading">Manage Customers</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1">
                                <SearchIcon/>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                {/* <Link to={"/manage-users/add-user"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline/> Add New
                                    </Button>
                                </Link> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    onRowClicked={rowData => {
                        history.push(`/manage-users/user-details/${rowData.id}`)
                    }}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage);
                    }}
                    progressComponent={
                        <BarLoader
                            color={"#12726C"}
                            loading={loading}
                            css={"marginTop: 10px"}
                            height={"4"}
                            width={"100%"}
                        />
                    }
                />
            </div>
        </div>
    );
}

export default Users;
